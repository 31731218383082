import { useCallback } from 'react';

export const useDropdownTouch = (options, currentValue, onChange, fieldName) => {
  return useCallback((e) => {
    if (!e.touches || !e.touches[0]) return;
    
    const touch = e.touches[0];
    const startY = touch.clientY;
    let lastY = startY;
    let isSwiping = false;
    let touchTimeout;
    
    // Filter out 'Custom' option for scrolling
    const scrollableOptions = options.filter(option => option !== 'Custom');
    let currentIndex = scrollableOptions.indexOf(currentValue);
    let accumulatedDelta = 0;
    
    touchTimeout = setTimeout(() => {
      if (!isSwiping) {
        e.target.click();
      }
    }, 100);
    
    const handleTouchMove = (e) => {
      if (!e.touches || !e.touches[0]) return;
      
      clearTimeout(touchTimeout);
      
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const totalMove = Math.abs(currentY - startY);
      
      if (totalMove > 5) {
        e.preventDefault();
        isSwiping = true;
        
        const diffY = lastY - currentY;
        lastY = currentY;
        
        accumulatedDelta += diffY * 0.6;
        
        const itemsToMove = Math.floor(Math.abs(accumulatedDelta) / 10);
        
        if (itemsToMove >= 1) {
          const direction = Math.sign(accumulatedDelta);
          let newIndex = currentIndex + (direction * itemsToMove);
          
          // Ensure newIndex stays within bounds of scrollable options
          newIndex = Math.max(0, Math.min(newIndex, scrollableOptions.length - 1));
          
          if (newIndex !== currentIndex) {
            const newValue = scrollableOptions[newIndex];
            onChange({
              target: {
                name: fieldName,
                value: newValue
              }
            });
            currentIndex = newIndex;
          }
          
          accumulatedDelta = accumulatedDelta % 10;
        }
      }
    };

    const handleTouchEnd = () => {
      clearTimeout(touchTimeout);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      
      if (!isSwiping) {
        e.target.click();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  }, [options, currentValue, onChange, fieldName]);
}; 