export const PhantomCameras = {
    "Flex4K": {
        brand: "Phantom",
        resolutions: {
            "(4096 x 2304)": {
                dimensions: [4096, 2304],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["938"]}
                }
            },
            "(4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1000"]}
                }
            },
            "(4096 x 2048)": {
                dimensions: [4096, 2048],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1050"]}
                }
            },
            "(4096 x 1152)": {
                dimensions: [4096, 1152],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1850"]}
                }
            },
            "(4096 x 1080)": {
                dimensions: [4096, 1080],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1970"]}
                }
            },
            "(4096 x 720)": {
                dimensions: [4096, 720],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["2930"]}
                }
            },
            "(4096 x 360)": {
                dimensions: [4096, 360],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["5660"]}
                }
            },
            "(4096 x 16)": {
                dimensions:[4096, 16],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["25000"]}
                }
            },
            "(2048 x 2048)": {
                dimensions: [2048, 2048],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1050"]}
                }
            },
            "(2048 x 1152)": {
                dimensions: [2048, 1152],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1850"]}
                }
            },
            "(2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1970"]}
                }
            },
            "(2048 x 720)": {
                dimensions: [2048, 720],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["2932"]}
                }
            },
            "(2048 x 240)": {
                dimensions: [2048, 240],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["8220"]}
                }
            },
            "(2048 x 16)": {
                dimensions: [2048, 16],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["25000"]}
                }
            }, 
        }
    },
    "VEO4K-PL": {
        brand: "Phantom",
        resolutions: {
            "(4096 x 2304)": {
                dimensions: [4096, 2304],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["938"]}
                    }
                },
            "(4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1000"]}
                    }
                },
            "(4096 x 2048)": {
                dimensions: [4096, 2048],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1050"]}
                    }
                },
            "(4096 x 1152)": {
                dimensions: [4096, 1152],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1850"]}
                    }
                },
            "(4096 x 1080)": {
                dimensions: [4096, 1080],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1970"]}
                    }
                },
            "(4096 x 720)": {
                dimensions: [4096, 720],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["2930"]}
                    }
                },
            "(4096 x 360)": {
                dimensions: [4096, 360],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["5660"]}
                    }
                },
            "(4096 x 8)": {
                dimensions: [4096, 8],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["64300"]}
                    }
                },
            "(2048 x 2048)": {
                dimensions: [2048, 2048],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1050"]}
                    }
                },
            "(2048 x 1152)": {
                dimensions: [2048, 1152],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1850"]}
                    }
                },
            "(2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1970"]}
                    }
                },
            "(2048 x 240)": {
                dimensions: [2048, 240],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["8220"]}
                    }
                },
            "(2048 x 8)": {
                dimensions: [2048, 8],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["64300"]}
                }
            }, 
        }
    },


    "V1840 ONYX": {
        brand: "Phantom",
        resolutions: {
            "(2048 x 1952)": {
                dimensions: [2048, 1952],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["3320", "4510"]}
                }
            },
            "(2048 x 1600) ": {
                dimensions: [2048, 1600],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["4030", "5490"]}
                }
            },
            "(2048 x 1440)": {
                dimensions: [2048, 1440],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["4460", "6080"]}
                }
            },
            "(1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["5880", "8570"]}
                }
            },
            "(1792 x 976)": {
                dimensions: [1792, 976],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["6470", "10110"]}
                }
            },
            "(1280 x 720)": {
                dimensions: [1280, 720],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["8610", "13540"]}
                }
            },
            "(1024 x 976)": {
                dimensions: [1024, 976],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["6470", "10110", "12670", "17240"]}
                }
            },
            "(896 x 720)": {
                dimensions: [896, 720],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["8610", "13540", "16740", "25850"]}
                }
            },
            "(640 x 480)": {
                dimensions: [640, 480],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["12480", "19840", "23960", "37100"]}
                }
            },
            "(256 x 320)": {
                dimensions: [256, 320],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["17810", "28760", "33620", "52260"]}
                }
            },
            "(256 x 64)": {
                dimensions: [256, 64],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["56330", "94710", "102500", "150840"]}
                }
            },
            "(896 x 16)": {
                dimensions: [896, 16],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["94710", "143660", "197380", "225000"]}
                }
            },
            "(1792 x 8)": {
                dimensions: [1792, 8],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["106850", "225000"]}
                }
            }, 
        }
    },





"V2640 ONYX": {
    brand: "Phantom",
    resolutions: {
        "(2048 x 1952)": {
            dimensions: [2048, 1952],
            codecs: {
                "Phantom Cine RAW": { framerates: ["4855", "6600"]}
            }
        },
        "(2048 x 1600) ": {
            dimensions: [2048, 1600],
            codecs: {
                "Phantom Cine RAW": { framerates: ["5885", "8020"]}
            }
        },
        "(2048 x 1440)": {
            dimensions: [2048, 1440],
            codecs: {
                "Phantom Cine RAW": { framerates: ["6510", "8880"]}
            }
        },
        "(1920 x 1080)": {
            dimensions: [1920, 1080],
            codecs: {
                "Phantom Cine RAW": { framerates: ["8575", "12510"]}
            }
        },
        "(1792 x 976)": {
            dimensions: [1792, 976],
            codecs: {
                "Phantom Cine RAW": { framerates: ["9440", "14740"]}
            }
        },
        "(1280 x 720)": {
            dimensions: [1280, 720],
            codecs: {
                "Phantom Cine RAW": { framerates: ["12540", "19695"]}
            }
        },
        "(1024 x 976)": {
            dimensions: [1024, 976],
            codecs: {
                "Phantom Cine RAW": { framerates: ["9440", "14740", "18390", "25030"]}
            }
        },
        "(896 x 720)": {
            dimensions: [896, 720],
            codecs: {
                "Phantom Cine RAW": { framerates: ["12540", "19695", "24230", "37360"]}
            }
        },
        "(640 x 480)": {
            dimensions: [640, 480],
            codecs: {
                "Phantom Cine RAW": { framerates: ["18120", "28760", "34490", "53290"]}
            }
        },
        "(256 x 320)": {
            dimensions: [256, 320],
            codecs: {
                "Phantom Cine RAW": { framerates: ["25765", "41500", "48060", "74460"]}
            }
        },
        "(256 x 64)": {
            dimensions: [256, 64],
            codecs: {
                "Phantom Cine RAW": { framerates: ["79200", "129550", "142270", "204270"]}
            }
        },
        "(896 x 16)": {
            dimensions: [896, 16],
            codecs: {
                "Phantom Cine RAW": { framerates: ["129600", "190060", "261190", "303460"]}
            }
        },
        "(1792 x 8)": {
            dimensions: [1792, 8],
            codecs: {
                "Phantom Cine RAW": { framerates: ["144970", "303460"]}
            }
        }, 
    }
},
"VEO4K 990": {
    brand: "Phantom",
    resolutions: {
            "(4096 x 2304)": {
                dimensions: [4096, 2304],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["938"]}
                }
            },
            "(4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1000"]}
                }
            },
            "(4096 x 2048)": {
                dimensions: [4096, 2048],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1050"]}
                }
            },
            "(4096 x 1152)": {
                dimensions: [4096, 1152],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1850"]}
                }
            },
            "(4096 x 1080)": {
                dimensions: [4096, 1080],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1970"]}
                }
            },
            "(4096 x 720)": {
                dimensions: [4096, 720],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["2930"]}
                }
            },
            "(4096 x 360)": {
                dimensions: [4096, 360],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["5660"]}
                }
            },
            "(4096 x 8)": {
                dimensions: [4096, 8],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["64300"]}
                }
            },
            "(2048 x 2048)": {
                dimensions: [2048, 2048],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1050"]}
                }
            },
            "(2048 x 1152)": {
                dimensions: [2048, 1152],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1850"]}
                }
            },
            "(2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["1970"]}
                }
            },
            "(2048 x 240)": {
                dimensions: [2048, 240],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["8220"]}
                }
            },
            "(2048 x 8)": {
                dimensions: [2048, 8],
                codecs: {
                    "Phantom Cine RAW": { framerates: ["64300"]}
                }
            }, 
        }
    },        

    "VEO4K 590": {
        brand: "Phantom",
        resolutions: {
            "(4096 x 2304)": {
                dimensions: [4096, 2304],
                codecs: {
                    "Phantom Cine RAW": { framerates: [530]}
                }
            },

            "(4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Phantom Cine RAW": { framerates: [570]}
                }
            },

            "(4096 x 2048)": {
                dimensions: [4096, 2048],
                codecs: {
                    "Phantom Cine RAW": { framerates: [600]}
                }
            },

            "(4096 x 1152)": {
                dimensions: [4096, 1152],
                codecs: {
                    "Phantom Cine RAW": { framerates: [1070]}
                }
            },

            "(4096 x 1080)": {
                dimensions: [4096, 1080],
                codecs: {
                    "Phantom Cine RAW": { framerates: [1140]}
                }
            },

            "(4096 x 720)": {
                dimensions: [4096, 720],
                codecs: {
                    "Phantom Cine RAW": { framerates: [1700]}
                }
            },

            "(4096 x 360)": {
                dimensions: [4096, 360],
                codecs: {
                    "Phantom Cine RAW": { framerates: [3330]}
                }
            },

            "(4096 x 8)": {
                dimensions: [4096, 8],
                codecs: {
                    "Phantom Cine RAW": { framerates: [54600]}
                }
            },

            "(2048 x 2048)": {
                dimensions: [2048, 2048],
                codecs: {
                    "Phantom Cine RAW": { framerates: [600]}
                }
            },

            "(2048 x 1152)": {
                dimensions: [2048, 1152],
                codecs: {
                    "Phantom Cine RAW": { framerates: [1070]}
                }
            },

            "(2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "Phantom Cine RAW": { framerates: [1140]}
                }
            },

            "(2048 x 240)": {
                dimensions: [2048, 240],
                codecs: {
                    "Phantom Cine RAW": { framerates: [4900]}
                }
            },

            "(2048 x 8)": {
                dimensions: [2048, 8],
                codecs: {
                    "Phantom Cine RAW": { framerates: [54600]}
                }
            }, 
        }
    },

};
