
// framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "75", "90"]},

export const SonyCameras = {
    "VENICE 2 8K": {
        brand: "Sony",
        resolutions: {
            "8.6K 3:2 (8640 x 5760)": {
                dimensions: [8640, 5760], 
                codecs: { 
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97"]}
                }
            }, 
            
            "8.2K 17:9 (8192 x 4320)": {
                dimensions: [8192, 4320], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94"]}
                }
            }, 
            
            "7.6K 16:9 (7680 x 4320)": {
                dimensions: [7680, 4320], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97"]}
                }
            }, 
            
            "5.8K 6:5 (5760 x 4820)": {
                dimensions: [5760, 4820], 
                codecs: { 
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95"]},
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95"]}
                }
            }, 
            
            "5.8K 17:9 (5760 x 3040)": {
                dimensions: [5760, 3040], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "75"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "75", "90"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "75", "90"]}
                }
            }, 
            
            "5.4K 16:9 (5434 x 3056)": {
                dimensions: [5434, 3056], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "75"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "75", "90"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "75", "90"]}
                }
            }, 
            
            "4K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160], 
                codecs: { 
                    "Apple ProRes 4444": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94"]},
                    "Apple ProRes 422 HQ": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "75", "90"]}
                }
            }, 
            
            "3.8K 16:9 (3840 x 2160)": {
                dimensions: [3840, 2160], 
                codecs: { 
                    "Apple ProRes 4444": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94"]},
                    "Apple ProRes 422 HQ": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "75", "90"]}
                }
            }, 
        }
    },
    "VENICE 2 6K": {
        brand: "Sony",
        resolutions: {
            "6K 2.39:1 (6048 x 2534)": {
                dimensions: [6048, 2534], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "59.94"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94","90"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94","90"]}
                }
            }, 
            "6K 17:9 (6054 x 3192)": {
                dimensions: [6054, 3192], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94","72"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94","72"]}
                }
            }, 
            "6K 1.85:1 (6054 x 3272)": {
                dimensions: [6054, 3272], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94","72"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94","72"]}
                }
            }, 
            "6K 3:2 (6048 x 4032)": {
                dimensions: [6048, 4032], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94"]}
                }
            }, 
            "5.7K 16:9 (5674 x 3192)": {
                dimensions: [5674, 3192], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97"]}
                }
            }, 
            "4K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},                    
                    "Apple ProRes 4444": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes 422 HQ": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94", "75", "90"]}
                }
            }, 
            "4K 6:5 (4096 x 3432)": {
                dimensions: [4096, 3432], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97"]}                    
                }
            }, 
            "4K 4:3 (4096 x 3072)": {
                dimensions: [4096, 3072], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "59.94", "75"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "59.94", "75"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "59.94", "75"]}                    
                }
            }, 
            "4K 2.39:1 (4096 x 1716)": {
                dimensions: [4096, 1716], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "120"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "120"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "120"]}                    
                }
            }, 
            "3.8K 16:9 (3840 x 2160)": {
                dimensions: [3840, 2160], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},                    
                    "Apple ProRes 4444": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes 422 HQ": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94", "75", "90"]}
                }
            },    
        }
    },
    "VENICE": {
        brand: "Sony",
        resolutions: {
            "6K 2.39:1 (6048 x 2534)": {
                dimensions: [6048, 2534], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "59.94"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94","90"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94","90"]}
                }
            }, 
            "6K 17:9 (6054 x 3192)": {
                dimensions: [6054, 3192], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94","72"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94","72"]}
                }
            }, 
            "6K 1.85:1 (6054 x 3272)": {
                dimensions: [6054, 3272], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94","72"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94","72"]}
                }
            }, 
            "6K 3:2 (6048 x 4032)": {
                dimensions: [6048, 4032], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94"]}
                }
            }, 
            "5.7K 16:9 (5674 x 3192)": {
                dimensions: [5674, 3192], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97"]}
                }
            }, 
            "4K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "110"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "110"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "110"]},                    
                    "Sony XAVC C480": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony XAVC C300": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]}
                }
            }, 
            "4K 6:5 (4096 x 3432)": {
                dimensions: [4096, 3432], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony XAVC C480": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony XAVC C300": {framerates: ["23.976", "24", "25", "29.97"]}                    
                }
            }, 
            "4K 4:3 (4096 x 3072)": {
                dimensions: [4096, 3072], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "59.94", "75"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "59.94", "75"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "59.94", "75"]},
                    "Sony XAVC C480": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony XAVC C300": {framerates: ["23.976", "24", "25", "29.97", "59.94"]}                    
                }
            }, 
            "4K 2.39:1 (4096 x 1716)": {
                dimensions: [4096, 1716], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "120"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "120"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "47.95", "50", "59.94", "120"]},
                    "Sony XAVC C480": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony XAVC C300": {framerates: ["23.976", "24", "25", "29.97", "50","59.94"]}                                        
                }
            }, 
            "3.8K 16:9 (3840 x 2160)": {
                dimensions: [3840, 2160], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},                    
                    "Sony XAVC C480": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Sony XAVC C300": {framerates: ["23.976", "24", "25", "29.97", "50","59.94"]}    
                }
            },    
            "2K 16:9 (2048 x 1152)": {
                dimensions: [2048, 1152], 
                codecs: { 
                    "Sony X-OCN XT": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Sony X-OCN LT": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Sony X-OCN ST": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},                    
                    "Apple Prores 4444": {framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple Prores 422 HQ": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple Prores 422": {framerates: ["23.976", "24", "25", "29.97", "50","59.94"]},    
                    "Apple Prores Proxy": {framerates: ["23.976", "24", "25", "29.97", "50","59.94"]},
                    "MPEG 50Mbps": {framerates: ["23.976", "24", "25", "29.97"]}    
                }
            },            
        }
    },            
    "Burano": {
        brand: "Sony",
        resolutions: {
            "FF 8.6K 16:9 (8632 x 4856)": {
                dimensions: [8632, 4856], 
                codecs: { 
                    "Sony X-OCN LT": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30"]}
                }
            },                    
            "FF 8.6K 17:9 (8632 x 4552)": {
                dimensions: [8632, 4552], 
                codecs: { 
                    "Sony X-OCN LT": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30"]}
                }
            },    
            "FF 8.6K 17:9 (8192 x 4320)": {
                dimensions: [8192, 4320], 
                codecs: { 
                    "Sony XAVC H-I XQ": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30"]},
                    "Sony XAVC H-I SQ": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30"]},
                    "Sony XAVC H-L": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30"]}
                }
            },    
            "FF 8.6K 16:9 (7680 x 4320)": {
                dimensions: [7680, 4320], 
                codecs: { 
                    "Sony XAVC H-I XQ": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30"]},
                    "Sony XAVC H-I SQ": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30"]},
                    "Sony XAVC H-L": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30"]}   
                }
            },    
            "FF Crop 6K 16:9 (6052 x 3404)": {
                dimensions: [6052, 3404], 
                codecs: { 
                    "Sony X-OCN LT": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "59.94", "60"]}
                }
            },    
            "FF Crop 6K 17:9 (6052 x 3192)": {
                dimensions: [6052, 3192], 
                codecs: { 
                    "Sony X-OCN LT": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "59.94", "60"]}
                }
            },    
            "S35 5.8K 17:9 (5760 x 3036)": {
                dimensions: [5760, 3036], 
                codecs: { 
                    "Sony X-OCN LT": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "59.94", "60"]}
                }
            },    
            "S35 5.8K 16:9 (5760 x 3240)": {
                dimensions: [5760, 3240], 
                codecs: { 
                    "Sony X-OCN LT": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "59.94", "60"]}
                }
            },    
            "FF Crop 6K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160], 
                codecs: { 
                    "Sony XAVC": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "59.94", "60"]}
                }
            },    
            "4K 17:9 S35c (4096 x 2160)": {
                dimensions: [4096, 2160], 
                codecs: { 
                    "Sony X-OCN LT": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "59.94", "60"]}
                }
            },    
            "UHD (3840 x 2160)": {
                dimensions: [3840, 2160], 
                codecs: { 
                    "Sony XAVC": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "59.94", "60"]}
                }
            },    
            "HD (1920 x 1080)": {
                dimensions: [1920, 1080], 
                codecs: { 
                    "Sony XAVC": {framerates: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "27", "28", "29", "29.97", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "59.94", "60"]}
                }
            },            
        }
    },
    "FX9": {
        brand: "Sony",
        resolutions: {
            "4K DCI (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Sony XAVC-I": {framerates: ["23.976","24","25", "29.97", "50", "59.94"]}
                }
            },
            "UHD (3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "Sony XAVC-I": {framerates: ["23.976", "25", "29.97", "50", "59.94"]},
                    "Sony XAVC-L": {framerates: ["23.976", "25", "29.97", "50", "59.94"]}
                }
            },
            "HD (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "Sony XAVC-I": {framerates: ["23.976", "25", "29.97", "50", "59.94"]},
                    "Sony XAVC-L": {framerates: ["23.976", "25", "29.97", "50", "59.94"]},
                    "MPEG 50Mbps": {framerates: ["23.976", "25", "29.97", "50", "59.94"]}
                }
            },            
        }
    },
    "FX6": {
        brand: "Sony",
        resolutions: {
            "4K DCI (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Sony XAVC-I": {framerates: ["23.976", "24","25", "29.97", "50", "59.94"]}
                }
            },
            "UHD (3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "Sony XAVC-I": {framerates: ["23.976", "25", "29.97", "50", "59.94"]},
                    "Sony XAVC-L": {framerates: ["23.976", "25", "29.97", "50", "59.94"]}
                }
            },
            "HD (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "Sony XAVC-I": {framerates: ["23.976", "25", "29.97", "50", "59.94"]},
                    "Sony XAVC-L": {framerates: ["23.976", "25", "29.97", "50", "59.94"]}
                }
            },            
        }
    },
    "FX3": {
        brand: "Sony",
        resolutions: {
            "4K DCI (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Sony XAVC S-I": {framerates: ["23.976", "25", "29.97", "50", "59.94"]}
                }
            },
            "UHD (3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "Sony XAVC S-I": {framerates: ["23.976", "25", "29.97", "50", "59.94"]},
                    "Sony XAVC HS 4K": {framerates: ["23.976", "25", "29.97", "50", "59.94", "100", "119.88"]},
                    "Sony XAVC S 4K": {framerates: ["23.976", "25", "29.97", "50", "59.94", "100", "119.88"]}
                }
            },
            "HD (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "Sony XAVC S-I": {framerates: ["23.976", "25", "29.97", "50", "59.94"]},
                    "Sony XAVC S": {framerates: ["23.976", "25", "29.97", "50", "59.94"]}
                }
            },            
        }
    },
    "FX30": {
        brand: "Sony",
        resolutions: {
            "UHD (3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "Sony XAVC S-I": {framerates: ["23.976", "25", "29.97", "50", "59.94"]},
                    "Sony XAVC HS 4K": {framerates: ["23.976", "50", "59.94", "100", "119.88"]},
                    "Sony XAVC S 4K": {framerates: ["23.976", "25", "29.97", "50", "59.94", "100", "119.88"]}
                }
            },
            "HD (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "Sony XAVC S-I": {framerates: ["23.976", "25", "29.97", "50", "59.94"]},
                    "Sony XAVC S": {framerates: ["23.976", "25", "29.97", "50", "59.94", "100", "119.88"]}
                }
            },            
        }
    },
    "A7S III": {
        brand: "Sony",
        resolutions: {
            "(3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "Sony XAVC HS 4K": { framerates: ["1", "2", "3", "4", "6", "8", "12", "15", "24", "50", "60", "100", "120"]},
                    "Sony XAVC S 4K": { framerates: ["1", "2", "3", "4", "6", "8", "12", "15", "24", "25", "30", "50", "60", "100", "120"]},
                    "Sony XAVC S-I": { framerates: ["1", "2", "3", "4", "6", "8", "12", "15", "24", "30", "50", "60"]}
                }
            },


            "(1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "Sony XAVC S": { framerates: ["1", "2", "3", "4", "6", "8", "12", "15", "24", "25", "30", "50", "60", "100", "120"]},
                    "Sony XAVC S-I": { framerates: ["1", "2", "3", "4", "6", "8", "12", "15", "24", "25", "30", "50", "60"]}
                }
            },
        }
    },
    "Alpha 1": {
        brand: "Sony",
        resolutions: {
            "(7680 x 4320)": {
                dimensions: [7680, 4320],
                codecs: {
                    "Sony XAVC HS 8K": { framerates: ["1", "2", "3", "4", "6", "8", "12", "15", "24", "25", "30"]}
                }
            },


            "(3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "Sony XAVC HS 4K": { framerates: ["1", "2", "3", "4", "6", "8", "12", "15", "24", "50", "60", "100", "120"]},
                    "Sony XAVC S 4K": { framerates: ["1", "2", "3", "4", "6", "8", "12", "15", "24", "25", "30", "50", "60", "100", "120"]},
                    "Sony XAVC S-I": { framerates: ["1", "2", "3", "4", "6", "8", "12", "15", "24", "30", "50", "60"]}
                }
            },


            "(1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "Sony XAVC S": { framerates: ["1", "2", "3", "4", "6", "8", "12", "15", "24", "25", "30", "50", "60", "100", "120"]},
                    "Sony XAVC S-I": { framerates: ["1", "2", "3", "4", "6", "8", "12", "15", "24", "25", "30", "50", "60"]}
                }
            },
        }
    },
    "FS7": {
        brand: "Sony",
        resolutions: {
                "UHD (3840x2160)": {
                    dimensions: [3840, 2160],
                    codecs: {
                        "Sony XAVC-I": { framerates: ["23.976", "25", "29.97", "50", "59.94"]},
                        "Sony XAVC-L": { framerates: ["23.976", "25", "29.97", "50", "59.94"]}
                    }
                },

                "HD (1920x1080)": {
                    dimensions: [1920, 1080],
                    codecs: {
                        "Sony XAVC-I": { framerates: ["23.976", "25", "29.97", "50", "50", "59.94", "59.94", "120", "150", "180"]},
                        "Sony XAVC-L": { framerates: ["23.976", "25", "29.97", "50", "50", "59.94", "59.94", "120"]},
                        "Sony MPEG 50Mbps": { framerates: ["23.976", "25", "29.97", "50", "59.94"] }
                    }
                },
                "720p (1280x720)": {
                    dimensions: [1280, 720],
                    codecs: {
                        "Sony MPEG 50Mbps": { framerates: ["23.976", "25", "29.97", "50", "59.94"] }
                    }
                },
            }
        },






    // ... other cameras ...
};