import React, { useRef, useEffect, useCallback, useState } from 'react';
import { SlDocs, SlNote, SlRefresh, SlLike, SlLink } from "react-icons/sl";
import { filmFormats, filmStocks, framesPerFoot, getBaseFormat, getStockLengths, getStockPrice } from '../data/filmData';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { disableScroll, enableScroll } from '../utils';
import { useCalculator } from '../context/CalculatorContext';
import { useDropdownTouch } from '../hooks/useDropdownTouch';



// Add this class near the top with other imports
class Timecode {
  constructor(hours = 0, minutes = 0, seconds = 0, frames = 0, frameRate = 24) {
    this.hours = Math.floor(hours);
    this.minutes = Math.floor(minutes);
    this.seconds = Math.floor(seconds);
    this.frames = Math.floor(frames);
    this.frameRate = frameRate;
  }

  static parse(timecodeString, frameRate = 24) {
    const parts = timecodeString.split(/[:.]/).map(Number);
    while (parts.length < 4) parts.unshift(0);
    const [hours, minutes, seconds, frames] = parts;
    return new Timecode(hours, minutes, seconds, frames, frameRate);
  }
  
  toString() {
    return `${this.hours.toString().padStart(2, '0')}:${this.minutes.toString().padStart(2, '0')}:${this.seconds.toString().padStart(2, '0')}:${this.frames.toString().padStart(2, '0')}`;
  }

  totalFrames() {
    return this.hours * 3600 * this.frameRate + 
           this.minutes * 60 * this.frameRate + 
           this.seconds * this.frameRate + 
           this.frames;
  }

  static fromFrames(totalFrames, frameRate = 24) {
    const hours = Math.floor(totalFrames / (3600 * frameRate));
    totalFrames %= 3600 * frameRate;
    const minutes = Math.floor(totalFrames / (60 * frameRate));
    totalFrames %= 60 * frameRate;
    const seconds = Math.floor(totalFrames / frameRate);
    const frames = Math.floor(totalFrames % frameRate);

    return new Timecode(hours, minutes, seconds, frames, frameRate);
  }
}

// Add these functions after the existing helper functions
const parseDuration = (input, framerate) => {
  // Replace all periods with ':00' to ensure they become two zeros
  input = input.replace(/\./g, ':00');

  if (!input || input.trim() === '') {
    return new Timecode(0, 0, 0, 0, framerate).toString();
  }

  input = input.trim().toLowerCase();

  // Handle single number input - interpret as seconds by default
  if (/^\d+$/.test(input)) {
    const seconds = parseInt(input, 10);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    
    return new Timecode(hours, minutes, remainingSeconds, 0, framerate).toString();
  }

  // Handle input with colons after replacing periods
  if (/^\d+([:]\d+)*$/.test(input)) {
    const parts = input.split(':').map(Number);
    while (parts.length < 4) parts.unshift(0);
    let [hours, minutes, seconds, frames] = parts;

    // Adjust for overflow
    if (frames >= framerate) {
      seconds += Math.floor(frames / framerate);
      frames %= framerate;
    }
    if (seconds >= 60) {
      minutes += Math.floor(seconds / 60);
      seconds %= 60;
    }
    if (minutes >= 60) {
      hours += Math.floor(minutes / 60);
      minutes %= 60;
    }

    return new Timecode(hours, minutes, seconds, frames, framerate).toString();
  }

  // Define regex patterns for different time units
  const patterns = {
    week: /(\d+)\s*(?:w|wk|wks|week|weeks)/i,
    day: /(\d+)\s*(?:d|dy|dys|day|days)/i,
    hour: /(\d+)\s*(?:h|hr|hrs|hour|hours)/i,
    minute: /(\d+)\s*(?:m|min|mins|minute|minutes)/i,
    second: /(\d+)\s*(?:s|sec|secs|second|seconds)/i,
    frame: /(\d+)\s*(?:f|fr|frm|frms|frame|frames)/i
  };

  let totalFrames = 0;

  Object.entries(patterns).forEach(([unit, pattern]) => {
    const match = input.match(pattern);
    if (match) {
      const value = parseInt(match[1]);
      switch (unit) {
        case 'week': totalFrames += value * 7 * 24 * 3600 * framerate; break;
        case 'day': totalFrames += value * 24 * 3600 * framerate; break;
        case 'hour': totalFrames += value * 3600 * framerate; break;
        case 'minute': totalFrames += value * 60 * framerate; break;
        case 'second': totalFrames += value * framerate; break;
        case 'frame': totalFrames += value; break;
      }
    }
  });

  if (totalFrames === 0) {
    const totalSeconds = parseFloat(input);
    if (!isNaN(totalSeconds)) {
      totalFrames = Math.round(totalSeconds * framerate);
    } else {
      throw new Error("Invalid duration format");
    }
  }

  return Timecode.fromFrames(totalFrames, framerate).toString();
};

// Update the formatDurationFromFrames function to accept isMobile parameter
const formatDurationFromFrames = (totalFrames, fps, isMobile) => {
  const totalSeconds = totalFrames / fps;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  
  let parts = [];
  if (hours > 0) {
    // If hours exist, don't show seconds and use abbreviated units
    parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
  } else {
    // If no hours, show all units
    if (minutes > 0) parts.push(`${minutes}${isMobile ? 'm' : minutes === 1 ? ' minute' : ' minutes'}`);
    if (seconds > 0 || parts.length === 0) {
      parts.push(`${seconds}${isMobile ? 's' : seconds === 1 ? ' second' : ' seconds'}`);
    }
  }
  
  return `${parts.join(' ')} = ${totalFrames.toLocaleString()} frms`;
};

const FilmCalculator = ({ addLog, isMobile }) => {
  // Get context
  const { filmCalcState, setFilmCalcState } = useCalculator();
  

  // Add state variables at the top
  const [isCustomFramerate, setIsCustomFramerate] = useState(false);
  const [customFramerates, setCustomFramerates] = useState([]);

  // Refs
  const filmFormatRef = useRef(null);
  const fpsRef = useRef(null);
  const stockRef = useRef(null);
  const rollsCountRef = useRef(null);
  const rollLengthRef = useRef(null);

  // Constants
  const filmFramerates = ["1", "2", "3", "4", "6", "8", "12", "15", "16", "18", "20", "24", "25", "30", "32", "40", "48", "50", "60", "72", "75", "90", "96", "120", "150"];
  const sensitivity = 0.0125;

  // Helper functions
  const updateStockOptions = () => {
    const baseFormat = getBaseFormat(filmCalcState.filmFormat);
    return filmStocks[baseFormat] || [];
  };

  const updateRollLengthOptions = () => {
    const lengths = getStockLengths(filmCalcState.stock, filmCalcState.filmFormat) || [];
    return lengths.map(option => option.length);
  };

  // Calculation functions
  const calculateDuration = () => {
    try {
      const rollsCount = parseInt(filmCalcState.rollsCount);
      const rollLength = parseInt(filmCalcState.rollLength);
      const fps = parseFloat(filmCalcState.fps);
      const format = filmCalcState.filmFormat;

      const feet = rollLength * rollsCount;
      const framesPerFt = framesPerFoot[format] || 16;
      const totalFrames = feet * framesPerFt;

      return formatDurationFromFrames(totalFrames, fps, isMobile);
    } catch (error) {
      console.error("Error calculating duration:", error);
      return "0 seconds = 0 frames";
    }
  };

  const calculatePrice = () => {
    try {
      const unitPrice = getStockPrice(filmCalcState.stock, filmCalcState.rollLength, filmCalcState.filmFormat);
      if (unitPrice) {
        const totalPrice = parseFloat(unitPrice) * parseInt(filmCalcState.rollsCount);
        setFilmCalcState(prev => ({
          ...prev,
          unitPrice: `$${parseFloat(unitPrice).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          price: `$${totalPrice.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        }));
      } else {
        setFilmCalcState(prev => ({ 
          ...prev, 
          unitPrice: "Price not found",
          price: "Price not found" 
        }));
      }
    } catch (error) {
      console.error("Error calculating price:", error);
      setFilmCalcState(prev => ({ 
        ...prev, 
        unitPrice: "Invalid Input",
        price: "Invalid Input" 
      }));
    }
  };

  // Event handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilmCalcState(prev => ({ ...prev, [name]: value }));
  };

  // Add this near the top with other handlers
  const handleRollsTouch = useCallback((e) => {
    if (!e.touches || !e.touches[0]) return;
    
    const touch = e.touches[0];
    const rect = e.currentTarget.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    const startY = touch.clientY;
    const fieldWidth = rect.width;
    let startValue = parseInt(filmCalcState.rollsCount) || 1;
    let lastY = startY;
    let isSwiping = false;
    let touchTimeout;
    
    // Start a short timeout to detect if this is a tap or scroll
    touchTimeout = setTimeout(() => {
      // If we reach the timeout and haven't started swiping, it's a tap
      if (!isSwiping) {
        e.target.focus();
      }
    }, 100); // Short delay to detect intent
    
    const handleTouchMove = (e) => {
      if (!e.touches || !e.touches[0]) return;
      
      // Clear the timeout as we're now moving
      clearTimeout(touchTimeout);
      
      // Only prevent default and handle as scroll if we've moved enough
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const totalMove = Math.abs(currentY - startY);
      
      if (totalMove > 5) { // Small threshold to start scrolling
        e.preventDefault();
        isSwiping = true;
        
        const diffY = lastY - currentY;
        lastY = currentY;
        
        const sensitivity = 0.375;
        const isLeftHalf = touchX < fieldWidth / 2;
        const changeAmount = isLeftHalf ? 10 : 1;
        
        let change = Math.sign(diffY) * changeAmount;
        let newValue = Math.max(1, startValue + change);
        startValue = newValue;
        
        setFilmCalcState(prev => ({
          ...prev,
          rollsCount: newValue.toString()
        }));
      }
    };

    const handleTouchEnd = () => {
      clearTimeout(touchTimeout);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      
      // If we never started swiping, treat as a tap
      if (!isSwiping) {
        e.target.focus();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  }, [filmCalcState.rollsCount]);

  const handleLog = async () => {
    const logEntry = `${filmCalcState.filmFormat} ${filmCalcState.stock}\n` +
                    `${filmCalcState.rollsCount} x ${filmCalcState.rollLength}' @ ${filmCalcState.fps} fps\n` +
                    `${filmCalcState.duration}\n` +
                    `${filmCalcState.unitPrice} per roll = ${filmCalcState.price} total`;
    
    // Add to log
    addLog(logEntry);
    
    // Show success icon
    setFilmCalcState(prev => ({ ...prev, logButtonText: <SlLike /> }));
    setTimeout(() => setFilmCalcState(prev => ({ ...prev, logButtonText: <SlNote /> })), 500);
  };

  const handleCopy = () => {
    setFilmCalcState(prev => ({ ...prev, copyButtonText: <SlLike /> }));
    setTimeout(() => setFilmCalcState(prev => ({ ...prev, copyButtonText: <SlDocs /> })), 500);
  };

  const handleShare = () => {
    // Create URL parameters with all relevant state
    const params = new URLSearchParams({
      filmFormat: filmCalcState.filmFormat,
      fps: filmCalcState.fps,
      stock: filmCalcState.stock,
      rollsCount: filmCalcState.rollsCount,
      rollLength: filmCalcState.rollLength,
      duration: filmCalcState.duration,
      frames: filmCalcState.frames,
      price: filmCalcState.price
    });
    
    // Add calculator type parameter to ensure correct calculator is shown
    params.append('calculator', 'film');
    
    const shareUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
    const title = 'Film Calculator Results';
    const text = `${filmCalcState.filmFormat} ${filmCalcState.stock}\n${filmCalcState.rollsCount} x ${filmCalcState.rollLength}' @ ${filmCalcState.fps} fps\n${filmCalcState.duration} = ${filmCalcState.price}`;

    if (isMobile && navigator.share) {
      navigator.share({
        title,
        text,
        url: shareUrl
      })
        .then(() => {
          setFilmCalcState(prev => ({ ...prev, shareButtonText: <SlLike /> }));
          setTimeout(() => setFilmCalcState(prev => ({ ...prev, shareButtonText: <SlLink /> })), 500);
        })
        .catch(err => console.error('Error sharing:', err));
    } else {
      // On web, copy the URL to clipboard
      navigator.clipboard.writeText(shareUrl)
        .then(() => {
          setFilmCalcState(prev => ({ ...prev, shareButtonText: <SlLike /> }));
          setTimeout(() => setFilmCalcState(prev => ({ ...prev, shareButtonText: <SlLink /> })), 500);
        })
        .catch(err => console.error('Error copying to clipboard:', err));
    }
  };

  const handleReset = () => {
    const initialDuration = formatDurationFromFrames(16000, 24, isMobile); // 16000 frames at 24fps
    setFilmCalcState({
      filmFormat: "35mm 4-perf",
      fps: "24",
      stock: "5219 VISION3 500T Color Negative",
      rollsCount: "1",
      rollLength: "1000",
      duration: initialDuration,
      unitPrice: "$791.40",
      price: "$791.40",
      logButtonText: <SlNote />,
      shareButtonText: <SlLink />,
      resetButtonText: <SlRefresh />,
      copyButtonText: <SlDocs />,
      previousDuration: initialDuration,
      previousRollsCount: "1",
      copyStatus: "",
      isMobile: false
    });
  };

  // Effects
  useEffect(() => {
    const newDuration = calculateDuration();
    setFilmCalcState(prev => ({
      ...prev,
      duration: newDuration
    }));
    calculatePrice();
  }, [filmCalcState.filmFormat, filmCalcState.fps, filmCalcState.rollsCount, filmCalcState.rollLength]);

  useEffect(() => {
    if (isMobile) {
      disableScroll();
      return () => enableScroll();
    }
  }, [isMobile]);

  // Add wheel handlers
  const handleWheel = (e, options, currentValue, setterFunction) => {
    e.preventDefault();
    const index = options.indexOf(currentValue);
    const scrollAmount = -e.deltaY * sensitivity;
    
    let newIndex = index + Math.sign(scrollAmount);
    newIndex = Math.max(0, Math.min(newIndex, options.length - 1));
    
    if (newIndex !== index) {
      setterFunction(options[newIndex]);
    }
  };

  // Add these handler functions
  const handleFilmFormatWheel = (e) => {
    handleWheel(e, filmFormats, filmCalcState.filmFormat, (newValue) => {
      setFilmCalcState(prev => ({ ...prev, filmFormat: newValue }));
    });
  };

  const handleFPSWheel = (e) => {
    handleWheel(
      e, 
      [...filmFramerates, ...customFramerates], 
      filmCalcState.fps,
      (newValue) => {
        setFilmCalcState(prev => ({ ...prev, fps: newValue }));
        calculateDuration();
        calculatePrice();
      }
    );
  };

  const handleStockWheel = (e) => {
    const stockOptions = updateStockOptions();
    handleWheel(e, stockOptions, filmCalcState.stock, (newValue) => {
      // Calculate price immediately
      const unitPrice = getStockPrice(newValue, filmCalcState.rollLength, filmCalcState.filmFormat);
      if (unitPrice) {
        const totalPrice = parseFloat(unitPrice) * parseInt(filmCalcState.rollsCount);
        setFilmCalcState(prev => ({
          ...prev,
          stock: newValue,
          unitPrice: `$${parseFloat(unitPrice).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          price: `$${totalPrice.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        }));
      } else {
        setFilmCalcState(prev => ({
          ...prev,
          stock: newValue,
          unitPrice: "Price not found",
          price: "Price not found"
        }));
      }
    });
  };

  const handleRollLengthWheel = (e) => {
    const rollLengthOptions = updateRollLengthOptions();
    handleWheel(e, rollLengthOptions, filmCalcState.rollLength, (newValue) => {
      setFilmCalcState(prev => ({ ...prev, rollLength: newValue }));
    });
  };

  // Add this handler function with the other handlers
  const handleRollsCountWheel = (e) => {
    e.preventDefault();
    const currentValue = parseInt(filmCalcState.rollsCount) || 1;
    
    // Get click position relative to input element
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const isLeftHalf = x < rect.width / 2;
    
    // Calculate scroll amount based on which half was clicked
    const scrollAmount = -e.deltaY * sensitivity;
    const delta = Math.sign(scrollAmount);
    const changeAmount = isLeftHalf ? 10 : 1; // +10 on left, +1 on right
    
    // Calculate new value
    let newValue = currentValue + (delta * changeAmount);
    
    // Ensure value stays at 1 or above
    newValue = Math.max(1, newValue);
    
    setFilmCalcState(prev => ({ ...prev, rollsCount: newValue.toString() }));
  };

  // Add these handlers near the top with other handlers
  const handleRollsFocus = () => {
    // Disable scrolling on mobile when focused
    if (isMobile) {
      disableScroll();
    }
    
    // Only clear the input if it wasn't triggered by a scroll
    if (!document.documentElement.style.touchAction) {
      const currentValue = filmCalcState.rollsCount;
      setFilmCalcState(prev => ({ 
        ...prev, 
        rollsCount: '',
        previousRollsCount: currentValue
      }));
    }
  };

  const handleRollsBlur = () => {
    // Re-enable scrolling on mobile when blurred
    if (isMobile) {
      enableScroll();
    }

    const value = filmCalcState.rollsCount.trim();
    if (value === '') {
      // If empty, revert to previous value or 1
      setFilmCalcState(prev => ({ 
        ...prev, 
        rollsCount: prev.previousRollsCount || '1'
      }));
    } else {
      // Ensure the value is at least 1
      const validValue = Math.max(1, parseInt(value) || 1).toString();
      setFilmCalcState(prev => ({ 
        ...prev, 
        rollsCount: validValue,
        previousRollsCount: validValue
      }));
    }
  };

  // Move these handlers up, right after the other handler definitions and before any useEffects

  // Add this handler function with the other handlers
  const handleDurationWheel = (e) => {
    e.preventDefault();
    
    // Get click position relative to input element
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const isLeftHalf = x < rect.width / 2;
    
    const fps = parseFloat(filmCalcState.fps);
    const format = filmCalcState.filmFormat;
    const framesPerFt = framesPerFoot[format] || 16;
    
    // Get current frames count from duration string
    let currentFrames;
    try {
      const matches = filmCalcState.duration.match(/=\s*([\d,]+)\s*frames/);
      currentFrames = matches ? parseInt(matches[1].replace(/,/g, '')) : 0;
      
      if (isNaN(currentFrames) || currentFrames === 0) {
        const feet = parseInt(filmCalcState.rollLength) * parseInt(filmCalcState.rollsCount);
        currentFrames = feet * framesPerFt;
      }
    } catch (error) {
      const feet = parseInt(filmCalcState.rollLength) * parseInt(filmCalcState.rollsCount);
      currentFrames = feet * framesPerFt;
    }
    
    // Calculate frame change based on scroll direction and position
    const scrollAmount = -e.deltaY * sensitivity;
    const changeAmount = isLeftHalf ? fps * 3600 : fps * 60; // One hour on left, one minute on right
    const frameDelta = Math.sign(scrollAmount) * changeAmount;
    
    // Calculate new total frames
    const newTotalFrames = Math.max(1, currentFrames + frameDelta);
    
    // Update duration and rolls count
    const newDuration = formatDurationFromFrames(newTotalFrames, fps, isMobile);
    const requiredFeet = Math.ceil(newTotalFrames / framesPerFt);
    const requiredRolls = Math.ceil(requiredFeet / parseInt(filmCalcState.rollLength));
    
    setFilmCalcState(prev => ({
      ...prev,
      duration: newDuration,
      rollsCount: requiredRolls.toString()
    }));
    
    // Recalculate price with new roll count
    calculatePrice();
  };

  // Add this handler function for touch events
  const handleDurationTouch = useCallback((e) => {
    if (!e.touches || !e.touches[0]) return;
    
    const touch = e.touches[0];
    const rect = e.currentTarget.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    const width = rect.width;
    const startY = touch.clientY;
    let lastY = startY;
    let isSwiping = false;
    let touchTimeout;
    
    // Start a short timeout to detect if this is a tap or scroll
    touchTimeout = setTimeout(() => {
      // If we reach the timeout and haven't started swiping, it's a tap
      if (!isSwiping) {
        e.target.focus();
      }
    }, 100);
    
    // Determine which section was touched (hours, minutes, or seconds)
    const section = touchX < width/3 ? 'hours' : 
                   touchX < 2*width/3 ? 'minutes' : 'seconds';
    
    const fps = parseFloat(filmCalcState.fps);
    const format = filmCalcState.filmFormat;
    const framesPerFt = framesPerFoot[format] || 16;
    
    // Get initial frames count
    let currentFrames;
    try {
      const matches = filmCalcState.duration.match(/=\s*([\d,]+)\s*frames/);
      currentFrames = matches ? parseInt(matches[1].replace(/,/g, '')) : 0;
      
      if (isNaN(currentFrames) || currentFrames === 0) {
        const feet = parseInt(filmCalcState.rollLength) * parseInt(filmCalcState.rollsCount);
        currentFrames = feet * framesPerFt;
      }
    } catch (error) {
      const feet = parseInt(filmCalcState.rollLength) * parseInt(filmCalcState.rollsCount);
      currentFrames = feet * framesPerFt;
    }
    
    const handleTouchMove = (e) => {
      if (!e.touches || !e.touches[0]) return;
      
      clearTimeout(touchTimeout);
      
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const totalMove = Math.abs(currentY - startY);
      
      if (totalMove > 5) {
        e.preventDefault();
        isSwiping = true;
        
        const diffY = lastY - currentY;
        lastY = currentY;
        
        // Calculate frame change based on section
        let changeAmount;
        switch(section) {
          case 'hours':
            changeAmount = fps * 3600; // One hour
            break;
          case 'minutes':
            changeAmount = fps * 60; // One minute
            break;
          case 'seconds':
            changeAmount = fps; // One second
            break;
        }
        
        const frameDelta = Math.sign(diffY) * changeAmount;
        currentFrames = Math.max(1, currentFrames + frameDelta);
        
        // Update duration and rolls count
        const newDuration = formatDurationFromFrames(currentFrames, fps, isMobile);
        const requiredFeet = Math.ceil(currentFrames / framesPerFt);
        const requiredRolls = Math.ceil(requiredFeet / parseInt(filmCalcState.rollLength));
        
        setFilmCalcState(prev => ({
          ...prev,
          duration: newDuration,
          rollsCount: requiredRolls.toString()
        }));
        
        calculatePrice();
      }
    };

    const handleTouchEnd = () => {
      clearTimeout(touchTimeout);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      
      // If we never started swiping, treat as a tap
      if (!isSwiping) {
        e.target.focus();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  }, [filmCalcState.fps, filmCalcState.filmFormat, filmCalcState.rollLength, filmCalcState.duration, calculatePrice]);

  // Then keep the useEffect as is...
  useEffect(() => {
    const elements = [
      { ref: filmFormatRef, handler: handleFilmFormatWheel },
      { ref: fpsRef, handler: handleFPSWheel },
      { ref: stockRef, handler: handleStockWheel },
      { ref: rollLengthRef, handler: handleRollLengthWheel },
      { ref: rollsCountRef, handler: handleRollsCountWheel }
    ];

    elements.forEach(({ ref, handler }) => {
      if (ref.current) {
        ref.current.addEventListener('wheel', handler, { passive: false });
      }
    });

    // Add touch event for rolls count
    if (rollsCountRef.current) {
      rollsCountRef.current.addEventListener('touchstart', handleRollsTouch, { passive: false });
    }

    // Add duration handlers
    const durationInput = document.querySelector('input[placeholder*="hour"]');
    if (durationInput) {
      durationInput.addEventListener('wheel', handleDurationWheel, { passive: false });
      durationInput.addEventListener('touchstart', handleDurationTouch, { passive: false });
    }

    return () => {
      elements.forEach(({ ref, handler }) => {
        if (ref.current) {
          ref.current.removeEventListener('wheel', handler);
        }
      });

      if (rollsCountRef.current) {
        rollsCountRef.current.removeEventListener('touchstart', handleRollsTouch);
      }

      if (durationInput) {
        durationInput.removeEventListener('wheel', handleDurationWheel);
        durationInput.removeEventListener('touchstart', handleDurationTouch);
      }
    };
  }, [filmCalcState, handleRollsTouch, handleDurationTouch]);

  // Add this useEffect near the other effects
  useEffect(() => {
    // When format changes, update stock and roll length
    const stockOptions = updateStockOptions();
    if (stockOptions.length > 0 && !stockOptions.includes(filmCalcState.stock)) {
      // If current stock isn't available for this format, set to first available stock
      setFilmCalcState(prev => ({ ...prev, stock: stockOptions[0] }));
    } else {
      // If current stock is valid, just update roll lengths
      const rollLengthOptions = updateRollLengthOptions();
      if (rollLengthOptions.length > 0 && !rollLengthOptions.includes(filmCalcState.rollLength)) {
        // If current roll length isn't available for this stock, set to longest available
        const longestRoll = Math.max(...rollLengthOptions.map(l => parseInt(l))).toString();
        setFilmCalcState(prev => ({ ...prev, rollLength: longestRoll }));
      }
    }
  }, [filmCalcState.filmFormat]); // Trigger when format changes

  useEffect(() => {
    // When stock changes, update roll length
    const rollLengthOptions = updateRollLengthOptions();
    if (rollLengthOptions.length > 0 && !rollLengthOptions.includes(filmCalcState.rollLength)) {
      // Set to longest available roll length
      const longestRoll = Math.max(...rollLengthOptions.map(l => parseInt(l))).toString();
      setFilmCalcState(prev => ({ ...prev, rollLength: longestRoll }));
    }
  }, [filmCalcState.stock]); // Trigger when stock changes

  // Add this useEffect near the top with other effects
  useEffect(() => {
    // Parse URL parameters when component loads
    const params = new URLSearchParams(window.location.search);
    if (params.has('filmFormat')) {
      const fps = params.get('fps') || "24";
      const frames = parseInt(params.get('frames') || "16000");
      const duration = formatDurationFromFrames(frames, parseFloat(fps), isMobile);
      
      setFilmCalcState({
        filmFormat: params.get('filmFormat') || "35mm 4-perf",
        fps: fps,
        stock: params.get('stock') || "5219 VISION3 500T Color Negative",
        rollsCount: params.get('rollsCount') || "1",
        rollLength: params.get('rollLength') || "1000",
        duration: duration,
        unitPrice: "$791.40",
        price: params.get('price') || "$791.40",
        logButtonText: <SlNote />,
        shareButtonText: <SlLink />,
        resetButtonText: <SlRefresh />,
        copyButtonText: <SlDocs />,
        previousDuration: duration,
        previousRollsCount: "1",
        copyStatus: "",
        isMobile: false
      });
    } else {
      // Calculate initial duration if no URL parameters
      const initialDuration = calculateDuration();
      setFilmCalcState(prev => ({
        ...prev,
        duration: initialDuration,
        previousDuration: initialDuration
      }));
    }
  }, []); // Empty dependency array so it only runs once on mount

  // Update theme colors based on isDarkMode
  const bgColor = 'bg-white';
  const textColor = 'text-gray-900';
  const inputBgColor = 'bg-gray-50';
  const inputBorderColor = 'border-gray-300';
  const labelColor = 'text-gray-700';
  const buttonColor = 'bg-blue-500 hover:bg-blue-600';
  
  const commonInputClass = `w-full px-2 py-1 border rounded h-[36px] ${inputBgColor} ${inputBorderColor} ${textColor}`;

   // Function to get input style based on lock state
   const getInputStyle = (field) => `w-full px-2 py-1 border rounded h-[36px] ${
    isDurationLocked || isFileSizeLocked
      ? 'bg-gray-200 text-gray-700 border-gray-400 cursor-not-allowed pointer-events-none'
      : 'bg-gray-50 border-gray-300 text-gray-900'
  }`;

  // Define pinButtonStyle
  const pinButtonStyle = `p-2 rounded h-[36px] w-[36px] flex items-center justify-center self-end mb+[2px] bg-gray-200 hover:bg-gray-300`;

  // Add this new handler near the other touch handlers
  const handleDropdownTouch = useCallback((e, options, currentValue, fieldName) => {
    if (!e.touches || !e.touches[0]) return;
    
    const touch = e.touches[0];
    const startY = touch.clientY;
    let lastY = startY;
    let isSwiping = false;
    let touchTimeout;
    let currentIndex = options.indexOf(currentValue);
    let accumulatedDelta = 0;
    
    touchTimeout = setTimeout(() => {
      if (!isSwiping) {
        e.target.click();
      }
    }, 100);
    
    const handleTouchMove = (e) => {
      if (!e.touches || !e.touches[0]) return;
      
      clearTimeout(touchTimeout);
      
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const totalMove = Math.abs(currentY - startY);
      
      if (totalMove > 5) {
        e.preventDefault();
        isSwiping = true;
        
        const diffY = lastY - currentY;
        lastY = currentY;
        
        // Accumulate the delta and adjust sensitivity
        accumulatedDelta += diffY * 0.33; // Increased sensitivity
        
        // Calculate how many items to move based on accumulated delta
        const itemsToMove = Math.floor(Math.abs(accumulatedDelta) / 10);
        
        if (itemsToMove >= 1) {
          const direction = Math.sign(accumulatedDelta);
          let newIndex = currentIndex + (direction * itemsToMove);
          
          // Ensure newIndex stays within bounds
          newIndex = Math.max(0, Math.min(newIndex, options.length - 1));
          
          if (newIndex !== currentIndex) {
            const newValue = options[newIndex];
            setFilmCalcState(prev => ({
              ...prev,
              [fieldName]: newValue
            }));
            currentIndex = newIndex;
            
            // Trigger calculations immediately for responsive feedback
            if (fieldName === 'filmFormat' || fieldName === 'fps' || 
                fieldName === 'stock' || fieldName === 'rollLength') {
              calculateDuration();
              calculatePrice();
            }
          }
          
          // Reset accumulated delta after applying the move
          accumulatedDelta = accumulatedDelta % 10;
        }
      }
    };

    const handleTouchEnd = () => {
      clearTimeout(touchTimeout);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      
      if (!isSwiping) {
        e.target.click();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  }, [calculateDuration, calculatePrice]);

  const handleFilmFormatTouch = useDropdownTouch(
    filmFormats,
    filmCalcState.filmFormat,
    handleInputChange,
    'filmFormat'
  );
  // Add these new handler functions
  const handleFramerateChange = (e) => {
    const { value } = e.target;
    if (value === 'Custom') {
      setIsCustomFramerate(true);
      setFilmCalcState(prevData => ({
        ...prevData,
        fps: ''
      }));
    } else {
      setIsCustomFramerate(false);
      setFilmCalcState(prevData => ({
        ...prevData,
        fps: value
      }));
      calculateDuration();
      calculatePrice();
    }
  };
  
  const handleFPSTouch = useDropdownTouch(
    [...filmFramerates, ...customFramerates],
    filmCalcState.fps,
    handleFramerateChange,
    'fps'
  );

  const handleStockTouch = useDropdownTouch(
    updateStockOptions(),
    filmCalcState.stock,
    (e) => {
      const newValue = e.target.value;
      setFilmCalcState(prev => ({ 
        ...prev, 
        stock: newValue 
      }));
      
      // Calculate price immediately
      const unitPrice = getStockPrice(newValue, filmCalcState.rollLength, filmCalcState.filmFormat);
      if (unitPrice) {
        const totalPrice = parseFloat(unitPrice) * parseInt(filmCalcState.rollsCount);
        setFilmCalcState(prev => ({
          ...prev,
          unitPrice: `$${parseFloat(unitPrice).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          price: `$${totalPrice.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        }));
      }
    },
    'stock'
  );

  const handleRollLengthTouch = useDropdownTouch(
    updateRollLengthOptions(),
    filmCalcState.rollLength,
    handleInputChange,
    'rollLength'
  );



  const handleCustomFramerateChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    if (value.split('.').length <= 2) {
      setFilmCalcState(prevData => ({
        ...prevData,
        fps: value
      }));
    }
  };

  const handleCustomFramerateBlur = () => {
    const parsedFramerate = parseFloat(filmCalcState.fps);
    if (!isNaN(parsedFramerate) && parsedFramerate > 0) {
      const newFramerate = parsedFramerate.toString();
      if (!filmFramerates.includes(newFramerate) && !customFramerates.includes(newFramerate)) {
        setCustomFramerates(prev => [...prev, newFramerate].sort((a, b) => parseFloat(a) - parseFloat(b)));
      }
      setFilmCalcState(prevData => ({
        ...prevData,
        fps: newFramerate
      }));
      setIsCustomFramerate(false);
      calculateDuration();
      calculatePrice();
    } else if (filmCalcState.fps.trim() === '') {
      setFilmCalcState(prevData => ({
        ...prevData,
        fps: '24' // Default value
      }));
      setIsCustomFramerate(false);
    }
  };

  const handleCustomFramerateKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCustomFramerateBlur();
      e.target.blur();
    }
  };

  // Update the framerate section in the JSX
  <div>
    <label className="block text-gray-700">Framerate</label>
    {isCustomFramerate ? (
      <input
        type="text"
        value={filmCalcState.fps}
        onChange={handleCustomFramerateChange}
        onBlur={handleCustomFramerateBlur}
        onKeyPress={handleCustomFramerateKeyPress}
        placeholder="ex: 48"
        className={commonInputClass}
      />
    ) : (
      <select
        ref={fpsRef}
        name="fps"
        value={filmCalcState.fps}
        onChange={handleFramerateChange}
        onWheel={handleFPSWheel}
        onTouchStart={handleFPSTouch}
        className={commonInputClass}
      >
        {[...filmFramerates, ...customFramerates, 'Custom'].map(fps => (
          <option key={fps} value={fps}>{fps}</option>
        ))}
      </select>
    )}
  </div>

  // Add these new handler functions
  const handleDurationFocus = () => {
    setFilmCalcState(prev => ({
      ...prev,
      previousDuration: prev.duration,
      duration: ''
    }));
  };

  const handleDurationBlur = (e) => {
    if (isMobile) {
      scrollToTop();
    }

    try {
      const inputValue = e.target.value.trim();
      if (inputValue === '') {
        setFilmCalcState(prev => ({
          ...prev,
          duration: prev.previousDuration || calculateDuration()
        }));
        return;
      }

      const fps = parseFloat(filmCalcState.fps);
      const parsedDuration = parseDuration(inputValue, fps);
      const format = filmCalcState.filmFormat;
      const framesPerFt = framesPerFoot[format] || 16;
      const rollLength = parseInt(filmCalcState.rollLength);

      // Calculate total frames needed
      const timecode = Timecode.parse(parsedDuration, fps);
      const totalFrames = timecode.totalFrames();

      // Calculate required feet and rolls
      const requiredFeet = Math.ceil(totalFrames / framesPerFt);
      const requiredRolls = Math.ceil(requiredFeet / rollLength);

      // Update state with new values
      setFilmCalcState(prev => ({
        ...prev,
        rollsCount: requiredRolls.toString(),
        duration: formatDurationFromFrames(totalFrames, fps, isMobile)
      }));

      // Recalculate price with new roll count
      calculatePrice();
    } catch (error) {
      console.error("Error parsing duration:", error);
      setFilmCalcState(prev => ({
        ...prev,
        duration: prev.previousDuration || calculateDuration()
      }));
    }
  };

  const handleDurationKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  // Update the duration input in the JSX to include the wheel and touch handlers
  <input
    type="text"
    value={filmCalcState.duration}
    onChange={(e) => setFilmCalcState(prev => ({ ...prev, duration: e.target.value }))}
    onFocus={handleDurationFocus}
    onBlur={handleDurationBlur}
    onKeyPress={handleDurationKeyPress}
    onWheel={handleDurationWheel}
    onTouchStart={handleDurationTouch}
    placeholder="ex: 1 hour 30 minutes or 16 hrs"
    className={`w-full px-2 py-1 border rounded h-[36px] ${inputBgColor} ${inputBorderColor} ${textColor} text-center`}
    style={{ touchAction: 'none' }}
  />

  // Add these handler functions for price input
  const handlePriceFocus = () => {
    // Disable scrolling on mobile when focused
    if (isMobile) {
      disableScroll();
    }

    setFilmCalcState(prev => ({
      ...prev,
      previousPrice: prev.price,
      price: ''
    }));
  };

  const handlePriceBlur = (e) => {
    // Re-enable scrolling on mobile when blurred
    if (isMobile) {
      enableScroll();
      scrollToTop();
    }

    try {
      const inputValue = e.target.value.trim();
      if (inputValue === '') {
        setFilmCalcState(prev => ({
          ...prev,
          price: prev.previousPrice
        }));
        return;
      }

      const numericValue = parseFloat(inputValue.replace(/[^0-9.]/g, ''));
      if (!isNaN(numericValue)) {
        const unitPriceNumeric = parseFloat(filmCalcState.unitPrice.replace(/[$,]/g, ''));
        if (unitPriceNumeric > 0) {
          // Calculate how many rolls we can afford
          const affordableRolls = Math.floor(numericValue / unitPriceNumeric);
          const actualPrice = (affordableRolls * unitPriceNumeric).toFixed(2);
          
          setFilmCalcState(prev => ({
            ...prev,
            rollsCount: Math.max(0, affordableRolls).toString(), // Allow 0 rolls
            price: `$${parseFloat(actualPrice).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
          }));
        }
      } else {
        setFilmCalcState(prev => ({
          ...prev,
          price: prev.previousPrice
        }));
      }
    } catch (error) {
      console.error("Error handling price blur:", error);
      setFilmCalcState(prev => ({
        ...prev,
        price: prev.previousPrice
      }));
    }
  };

  const handlePriceKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  // Update the price input in the JSX
  <input
    type="text"
    value={filmCalcState.price}
    onChange={(e) => setFilmCalcState(prev => ({ ...prev, price: e.target.value }))}
    onFocus={handlePriceFocus}
    onBlur={handlePriceBlur}
    onKeyPress={handlePriceKeyPress}
    className={`w-full px-2 py-1 border rounded h-[36px] ${inputBgColor} ${inputBorderColor} ${textColor} text-center`}
    placeholder="eg: $5000"
  />

  // Add this helper function near the top
  const scrollToTop = () => {
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className="film-calculator p-2 bg-white text-gray-900">
      {/* Format and FPS row */}
      <div className="grid grid-cols-2 gap-4 mb-2">
        <div>
          <label className="block text-gray-700">Film Format</label>
          <select
            ref={filmFormatRef}
            name="filmFormat"
            value={filmCalcState.filmFormat}
            onChange={handleInputChange}
            onWheel={handleFilmFormatWheel}
            onTouchStart={handleFilmFormatTouch}
            className={commonInputClass}
          >
            {filmFormats.map(format => (
              <option key={format} value={format}>{format}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-gray-700">Framerate</label>
          {isCustomFramerate ? (
            <input
              type="text"
              value={filmCalcState.fps}
              onChange={handleCustomFramerateChange}
              onBlur={handleCustomFramerateBlur}
              onKeyPress={handleCustomFramerateKeyPress}
              placeholder="ex: 48"
              className={commonInputClass}
            />
          ) : (
            <select
              ref={fpsRef}
              name="fps"
              value={filmCalcState.fps}
              onChange={handleFramerateChange}
              onWheel={handleFPSWheel}
              onTouchStart={handleFPSTouch}
              className={commonInputClass}
            >
              {[...filmFramerates, ...customFramerates, 'Custom'].map(fps => (
                <option key={fps} value={fps}>{fps}</option>
              ))}
            </select>
          )}
        </div>
      </div>

      {/* Stock selection */}
      <div className="mb-2">
        <label className="block text-gray-700">Stock</label>
        <select
          ref={stockRef}
          name="stock"
          value={filmCalcState.stock}
          onChange={(e) => {
            const newValue = e.target.value;
            setFilmCalcState(prev => ({ 
              ...prev, 
              stock: newValue 
            }));
            
            // Calculate price immediately
            const unitPrice = getStockPrice(newValue, filmCalcState.rollLength, filmCalcState.filmFormat);
            if (unitPrice) {
              const totalPrice = parseFloat(unitPrice) * parseInt(filmCalcState.rollsCount);
              setFilmCalcState(prev => ({
                ...prev,
                unitPrice: `$${parseFloat(unitPrice).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                price: `$${totalPrice.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
              }));
            }
          }}
          onWheel={handleStockWheel}
          onTouchStart={handleStockTouch}
          className={commonInputClass}
        >
          {updateStockOptions().map(stock => (
            <option key={stock} value={stock}>{stock}</option>
          ))}
        </select>
      </div>

      {/* Rolls, Length and Price Per Roll row */}
      <div className="grid md:grid-cols-3 grid-cols-12 gap-2 mb-2">
        <div className="col-span-4 md:col-span-1">
          <label className="block text-gray-700">Rolls</label>
          <input
            ref={rollsCountRef}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            enterKeyHint="done"
            name="rollsCount"
            value={filmCalcState.rollsCount}
            onChange={handleInputChange}
            onFocus={handleRollsFocus}
            onBlur={handleRollsBlur}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.target.blur();
              }
            }}
            min="1"
            className={`${commonInputClass} text-center`}
            style={{ touchAction: 'none' }}
            onTouchStart={handleRollsTouch}
            placeholder="Enter rolls"
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <label className="block text-gray-700">Length (ft)</label>
          <select
            ref={rollLengthRef}
            name="rollLength"
            value={filmCalcState.rollLength}
            onChange={handleInputChange}
            onWheel={handleRollLengthWheel}
            onTouchStart={handleRollLengthTouch}
            className={`${commonInputClass} text-center`}
          >
            {updateRollLengthOptions().map(length => (
              <option key={length} value={length}>{length}</option>
            ))}
          </select>
        </div>
        <div className="col-span-5 md:col-span-1">
          <label className="block text-gray-700">Price Per Roll</label>
          <input
            type="text"
            value={filmCalcState.unitPrice}
            readOnly
            className={`w-full px-2 py-1 border rounded h-[36px] ${inputBgColor} ${inputBorderColor} ${textColor} cursor-not-allowed pointer-events-none text-center`}
          />
        </div>
      </div>

      {/* Duration and Total Price row */}
      <div className="grid grid-cols-2 gap-4 mb-2">
        <div>
          <label className="block text-gray-700">Duration</label>
          <input
            type="text"
            value={filmCalcState.duration}
            onChange={(e) => setFilmCalcState(prev => ({ ...prev, duration: e.target.value }))}
            onFocus={handleDurationFocus}
            onBlur={handleDurationBlur}
            onKeyPress={handleDurationKeyPress}
            onWheel={handleDurationWheel}
            onTouchStart={handleDurationTouch}
            placeholder="ex: 1 hour 30 minutes or 16 hrs"
            className={`w-full px-2 py-1 border rounded h-[36px] ${inputBgColor} ${inputBorderColor} ${textColor} text-center`}
            style={{ touchAction: 'none' }}
          />
        </div>
        <div>
          <label className="block text-gray-700">Total Price</label>
          <input
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            enterKeyHint="done"
            value={filmCalcState.price}
            onChange={(e) => setFilmCalcState(prev => ({ ...prev, price: e.target.value }))}
            onFocus={handlePriceFocus}
            onBlur={handlePriceBlur}
            onKeyPress={handlePriceKeyPress}
            className={`w-full px-2 py-1 border rounded h-[36px] ${inputBgColor} ${inputBorderColor} ${textColor} text-center`}
            placeholder="eg: $5000"
          />
        </div>
      </div>

      {/* Button Row */}
      <div className="mt-4 flex justify-between w-full">
        <button
          onClick={handleLog}
          className={`px-4 py-4 rounded flex-grow mr-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {filmCalcState.logButtonText || <SlNote />}
        </button>
        <CopyToClipboard 
          text={`${filmCalcState.filmFormat} ${filmCalcState.stock}\n` +
                `${filmCalcState.rollsCount} x ${filmCalcState.rollLength}' @ ${filmCalcState.fps} fps\n` +
                `${filmCalcState.duration}\n` +
                `${filmCalcState.unitPrice} per roll = ${filmCalcState.price} total`}
          onCopy={handleCopy}
        >
          <button 
            className={`px-4 py-4 rounded flex-grow mx-2 ${buttonColor} text-white flex items-center justify-center`}
          >
            {filmCalcState.copyButtonText || <SlDocs />}
          </button>
        </CopyToClipboard>
        <button
          onClick={handleShare}
          className={`px-4 py-4 rounded flex-grow mx-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {filmCalcState.shareButtonText || <SlLink />}
        </button>
        <button
          onClick={handleReset}
          className={`px-4 py-4 rounded flex-grow ml-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {filmCalcState.resetButtonText || <SlRefresh />}
        </button>
      </div>
    </div>
  );
};

export default FilmCalculator;