import React, { useCallback } from 'react';
import { SlDocs, SlLike } from "react-icons/sl";


const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        return true;
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        const success = document.execCommand('copy');
        document.body.removeChild(textArea);
        return success;
      }
    } catch (err) {
      console.error('Copy failed:', err);
      return false;
    }
  };
  
  const mobileShare = async (title, text, url) => {
    try {
      if (navigator.share) {
        await navigator.share({ title, text, url });
        return true;
      }
    } catch (err) {
      console.error('Share failed:', err);
    }
    return false;
  };
  
  export const handleShare = async (isMobile, title, text, currentUrl, onSuccess) => {
    let success = false;
    
    if (isMobile) {
      success = await mobileShare(title, text, currentUrl);
    }
    
    if (!success) {
      success = await copyToClipboard(currentUrl);
    }
  
    if (success && onSuccess) {
      onSuccess();
    }
  };
  


// Add these utility functions to handle scroll locking
export const disableScroll = () => {
  document.body.style.overflow = 'hidden';
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
};

export const enableScroll = () => {
  document.body.style.overflow = '';
  document.body.style.position = '';
  document.body.style.width = '';
};
