export const DJICameras = {
    "Air 3": {
        brand: "DJI",
        resolutions: {
            "4K Wide-Angle (3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "MP4 (MPEG-4 AVC) 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100"] },
                    "HEVC/H.265 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100"] }
                }
            },
            "HD Wide-Angle (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "MP4 (MPEG-4 AVC) 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100", "200"] },
                    "HEVC/H.265 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100", "200"] }
                }
            },
            "2.7K Vertical Shooting Wide-Angle (1512 x 2688)": {
                dimensions: [1512, 2688],
                codecs: {
                    "MP4 (MPEG-4 AVC) 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] },
                    "HEVC/H.265 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] }
                }
            },
            "HD Vertical Shooting Wide-Angle (1080 x 1920)": {
                dimensions: [1080, 1920],
                codecs: {
                    "MP4 (MPEG-4 AVC) 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] },
                    "HEVC/H.265 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] }
                }
            },
            "4K Medium Tele (3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "MP4 (MPEG-4 AVC) 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100"] },
                    "HEVC/H.265 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100"] }
                }
            },
            "HD Medium Tele (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "MP4 (MPEG-4 AVC) 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100", "200"] },
                    "HEVC/H.265 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100", "200"] }
                }
            },
            "2.7K Vertical Shooting Medium Tele (1512 x 2688)": {
                dimensions: [1512, 2688],
                codecs: {
                    "MP4 (MPEG-4 AVC) 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] },
                    "HEVC/H.265 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] }
                }
            },
            "HD Vertical Shooting Medium Tele (1080 x 1920)": {
                dimensions: [1080, 1920],
                codecs: {
                    "MP4 (MPEG-4 AVC) 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] },
                    "HEVC/H.265 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] }
                }
            },            
        }
    },           
    "Air 3S": {
            brand: "DJI",
            resolutions: {
                "4K Wide-Angle (3840 x 2160)": {
                    dimensions: [3840, 2160],
                    codecs: {
                        "MP4 (MPEG-4 AVC) 130 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "120"] },
                        "HEVC/H.265 130 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "120"] }
                    }
                },
                "HD Wide-Angle (1920 x 1080)": {
                    dimensions: [1920, 1080],
                    codecs: {
                        "MP4 (MPEG-4 AVC) 130 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100", "240"] },
                        "HEVC/H.265 130 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100", "240"] }
                    }
                },
                "2.7K Vertical Shooting Wide-Angle (1512 x 2688)": {
                    dimensions: [1512, 2688],
                    codecs: {
                        "MP4 (MPEG-4 AVC) 130 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] },
                        "HEVC/H.265 130 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] }
                    }
                },
                "4K Medium Tele (3840 x 2160)": {
                    dimensions: [3840, 2160],
                    codecs: {
                        "MP4 (MPEG-4 AVC) 130 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "120"] },
                        "HEVC/H.265 130 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "120"] }
                    }
                },
                "HD Medium Tele (1920 x 1080)": {
                    dimensions: [1920, 1080],
                    codecs: {
                        "MP4 (MPEG-4 AVC) 130 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100", "240"] },
                        "HEVC/H.265 130 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100", "240"] }
                    }
                },
                "2.7K Vertical Shooting Medium Tele (1512 x 2688)": {
                    dimensions: [1512, 2688],
                    codecs: {
                        "MP4 (MPEG-4 AVC) 130 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] },
                        "HEVC/H.265 130 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] }
                }
            },            
        }
    },

    "Avata 2": {
        brand: "DJI",
        resolutions: {
            "4K 4:3 (3840 x 2880)": {
                dimensions: [3840, 2880],
                codecs: {
                    "MP4 (H.264) 130 Mbps": { framerates: ["30", "50", "60"] },
                    "MP4 (H.265) 130 Mbps": { framerates: ["30", "50", "60"] }
                }
            },
            "4K 16:9 (3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "MP4 (H.264) 130 Mbps": { framerates: ["30", "50", "60", "100"] },
                    "MP4 (H.265) 130 Mbps": { framerates: ["30", "50", "60", "100"] }
                }
            },
            "2.7K 4:3 (2688 x 2016)": {
                dimensions: [2688, 2016],
                codecs: {
                    "MP4 (H.264) 130 Mbps": { framerates: ["30", "50", "60"] },
                    "MP4 (H.265) 130 Mbps": { framerates: ["30", "50", "60"] }
                }
            },
            "2.7K 16:9 (2688 x 1512)": {
                dimensions: [2688, 1512],
                codecs: {
                    "MP4 (H.264) 130 Mbps": { framerates: ["30", "50", "60", "120"] },
                    "MP4 (H.265) 130 Mbps": { framerates: ["30", "50", "60", "120"] }
                }
            },
            "1080p 4:3 (1440 x 1080)": {
                dimensions: [1440, 1080],
                codecs: {
                    "MP4 (H.264) 130 Mbps": { framerates: ["30", "50", "120"] },
                    "MP4 (H.265) 130 Mbps": { framerates: ["30", "50", "120"] }
                }
            },
            "1080p 16:9 (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "MP4 (H.264) 130 Mbps": { framerates: ["30", "50", "120"] },
                    "MP4 (H.265) 130 Mbps": { framerates: ["30", "50", "120"] }
                }
            },            
        }
    },
        "Inspire 3": {
            brand: "DJI",
            resolutions: {
                "8K FF 17:9 (8192 x 4320)": {
                    dimensions: [8192, 4320],
                    codecs: {
                        "CinemaDNG": { framerates: ["23.976", "24", "25"] },
                        "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"] },
                        "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30"] }
                    }
                },
                "8K FF 2.39:1 (8192 x 3424)": {
                    dimensions: [8192, 3424],
                    codecs: {
                        "CinemaDNG": { framerates: ["23.976", "24", "25"] },
                        "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "60", "72", "75"] },
                        "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30"] }
                    }
                },
                "8K FF 16:9 (7680 x 4320)": {
                    dimensions: [7680, 4320],
                    codecs: {
                        "CinemaDNG": { framerates: ["23.976", "24", "25"] },
                        "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"] },
                        "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30"] }
                    }
                },
                "5.5K S35 17:9 (5568 x 2952)": {
                    dimensions: [5568, 2952],
                    codecs: {
                        "CinemaDNG": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50"] },
                        "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"] }
                    }
                },
                "5.2K S35 16:9 (5248 x 2952)": {
                    dimensions: [5248, 2952],
                    codecs: {
                        "CinemaDNG": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50"] },
                        "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"] }
                    }
                },
                "4K FF 17:9 (4096 x 2160)": {
                    dimensions: [4096, 2160],
                    codecs: {
                        "CinemaDNG": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "72", "75", "96", "100"] },
                        "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "72", "75", "96", "100", "119.88", "120"] },
                        "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "72", "75", "96", "100", "119.88", "120"] },
                        "H.264 18 Mbps": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "72", "75", "96", "100", "119.88", "120"] }
                    }
                },
                "4K S35 17:9 (4096 x 2160)": {
                    dimensions: [4096, 2160],
                    codecs: {
                        "CinemaDNG": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "72", "75", "96", "100"] },
                        "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"] },
                        "H.264 18 Mbps": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"] }
                    }
                },
                "UHD FF (3840 x 2160)": {
                    dimensions: [3840, 2160],
                    codecs: {
                        "CinemaDNG": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "72", "75", "96", "100"] },
                        "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "72", "75", "96", "100", "119.88", "120"] },
                        "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "72", "75", "96", "100", "119.88", "120"] },
                        "H.264 18 Mbps": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "72", "75", "96", "100", "119.88", "120"] }
                    }
                },
                "UHD S35 (3840 x 2160)": {
                    dimensions: [3840, 2160],
                    codecs: {
                        "CinemaDNG": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "72", "75", "96", "100"] },
                        "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"] },
                        "H.264 18 Mbps": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"] }
                    }
                },            
            }
        },
        "Mavic 3 Pro": {
            brand: "DJI",
            resolutions: {
                "5.1K Hasselblad (5120 x 2700)": {
                    dimensions: [5120, 2700],
                    codecs: {
                        "Apple ProRes 422 HQ": { framerates: ["24", "25", "30", "48", "50"] },
                        "Apple ProRes 422": { framerates: ["24", "25", "30", "48", "50"] },
                        "Apple ProRes LT": { framerates: ["24", "25", "30", "48", "50"] },
                        "MP4 (MPEG-4 AVC) 200 Mbps": { framerates: ["24", "25", "30", "48", "50"] },
                        "HEVC/H.265 200 Mbps": { framerates: ["24", "25", "30", "48", "50"] }
                    }
                },
                "4K DCI Hasselblad (4096 x 2160)": {
                    dimensions: [4096, 2160],
                    codecs: {
                        "Apple ProRes 422 HQ": { framerates: ["24", "25", "30", "48", "50", "60", "120"] },
                        "Apple ProRes 422": { framerates: ["24", "25", "30", "48", "50", "60", "120"] },
                        "Apple ProRes LT": { framerates: ["24", "25", "30", "48", "50", "60", "120"] },
                        "MP4 (MPEG-4 AVC) 200 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "120"] },
                        "HEVC/H.265 200 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "120"] }
                    }
                },
                "UHD Hasselblad (3840 x 2160)": {
                    dimensions: [3840, 2160],
                    codecs: {
                        "Apple ProRes 422 HQ": { framerates: ["24", "25", "30", "48", "50", "60", "120"] },
                        "Apple ProRes 422": { framerates: ["24", "25", "30", "48", "50", "60", "120"] },
                        "Apple ProRes LT": { framerates: ["24", "25", "30", "48", "50", "60", "120"] },
                        "MP4 (MPEG-4 AVC) 200 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "120"] },
                        "HEVC/H.265 200 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "120"] }
                    }
                },
                "HD Hasselblad (1920 x 1080)": {
                    dimensions: [1920, 1080],
                    codecs: {
                        "MP4 (MPEG-4 AVC) 160 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "120", "200"] },
                        "HEVC/H.265 160 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "120", "200"] }
                    }
                },
                "UHD Medium Tele (3840 x 2160)": {
                    dimensions: [3840, 2160],
                    codecs: {
                        "Apple ProRes 422 HQ": { framerates: ["24", "25", "30", "48", "50", "60"] },
                        "Apple ProRes 422": { framerates: ["24", "25", "30", "48", "50", "60"] },
                        "Apple ProRes LT": { framerates: ["24", "25", "30", "48", "50", "60"] },
                        "MP4 (MPEG-4 AVC) 160 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] },
                        "HEVC/H.265 160 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] }
                    }
                },
                "HD Medium Tele (1920 x 1080)": {
                    dimensions: [1920, 1080],
                    codecs: {
                        "MP4 (MPEG-4 AVC) 160 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] },
                        "HEVC/H.265 160 Mbps": { framerates: ["24", "25", "30", "48", "50", "60"] }
                    }
                },
                "UHD Tele (3840 x 2160)": {
                    dimensions: [3840, 2160],
                    codecs: {
                        "Apple ProRes 422 HQ": { framerates: ["24", "25", "30", "50", "60"] },
                        "Apple ProRes 422": { framerates: ["24", "25", "30", "50", "60"] },
                        "Apple ProRes LT": { framerates: ["24", "25", "30", "50", "60"] },
                        "MP4 (MPEG-4 AVC) 160 Mbps": { framerates: ["24", "25", "30", "50", "60"] },
                        "HEVC/H.265 160 Mbps": { framerates: ["24", "25", "30", "50", "60"] }
                    }
                },
                "HD Tele (1920 x 1080)": {
                    dimensions: [1920, 1080],
                    codecs: {
                        "MP4 (MPEG-4 AVC) 160 Mbps": { framerates: ["24", "25", "30", "50", "60"] },
                        "HEVC/H.265 160 Mbps": { framerates: ["24", "25", "30", "50", "60"] }
                    }
                },            
            }
        },
        "Mini 4 Pro": {
            brand: "DJI",
            resolutions: {
                "4K (3840 x 2160)": {
                    dimensions: [3840, 2160],
                    codecs: {
                        "MP4 (MPEG-4 AVC) 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100"] },
                        "HEVC/H.265 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100"] }
                    }
                },
                "HD (1920 x 1080)": {
                    dimensions: [1920, 1080],
                    codecs: {
                        "MP4 (MPEG-4 AVC) 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100", "200"] },
                        "HEVC/H.265 150 Mbps": { framerates: ["24", "25", "30", "48", "50", "60", "100", "200"] }
                    }
                },            
            }
        },

        // ... other cameras ...
};

