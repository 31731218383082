export const brands = [
  "Apple", 
  "ARRI", 
  "Blackmagic Design", 
  "Canon", 
  "DJI", 
  "Freefly", 
  // "FujiFilm", 
  // "Google", 
  "GoPro", 
  // "Kinefinity", 
  // "Leica", 
  // "Nikon", 
  // "Panasonic", 
  // "Panavision", 
  "Phantom", 
  "RED", 
  // "Samsung", 
  // "Sigma",
  "Sony", 
  "Z CAM"
];


