// TBD Post's DataCalc WebApp
// datacalc@tbdpost.com

import React, { useState, useRef, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import DurationToFileSize from './components/DurationToFileSize';
import CameraToFileSize from './components/CameraToFileSize';
import AspectRatioCalculator from './components/AspectRatioCalculator';
import DailiesCalculator from './components/DailiesCalculator';
import Log from './components/Log';
import PrivacyPolicy from './components/PrivacyPolicy';
import Header from './components/Header';
import Footer from './components/Footer';
import SideMenu from './components/SideMenu';
import './App.css';
import { CalculatorProvider } from './context/CalculatorContext';
import ComingSoon from './components/ComingSoon';
import FilmCalculator from './components/FilmCalculator';
import BitrateCalculator from './components/BitrateCalculator';

const pageInfo = {
  '/duration': {
    title: 'Video Duration to File Size Calculator',
    description: 'Calculate file size based on duration, resolution, codec, and framerate.'
  },
  '/camera': {
    title: 'Camera to File Size Calculator',
    description: 'Calculate file size based on camera settings and recording duration.'
  },
  '/film': {
    title: 'Motion Picture Film Calculator',
    description: 'Calculate the amount of film you need to achieve your desired duration and cost for motion picture film.'
  },
  '/aspect-ratio': {
    title: 'Aspect Ratio Calculator',
    description: 'Calculate dimensions while maintaining aspect ratio.'
  },
  '/dailies': {
    title: 'Dailies Calculator',
    description: 'Calculate resolutions and file sizes for OCN and dailies.'
  },
  '/bitrate': {
    title: 'Bitrate Calculator',
    description: 'Calculate bitrate, file size, and duration relationships.'
  },
  '/logs': {
    title: 'Logs',
    description: 'View and share your DataCalc logs.'
  },
  '/comingsoon': {
    title: 'Coming Soon!',
  },
};

// Add this helper function to detect if we're in staging
const isStaging = () => {
  return window.location.pathname.startsWith('/beta');
};

// Add this helper function at the top of your file
const getBasename = () => {
  // Check if we're in the beta environment
  if (window.location.pathname.startsWith('/beta')) {
    return '/beta';
  }
  return '';
};

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);
  const [currentCalculator, setCurrentCalculator] = useState('duration');
  const [selectedCalculator, setSelectedCalculator] = useState('camera');
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [logs, setLogs] = useState(() => {
    const savedLogs = localStorage.getItem('calculatorLogs');
    return savedLogs ? JSON.parse(savedLogs) : [];
  });
  const logRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [previousPath, setPreviousPath] = useState('/duration');

  // Get current page info
  const currentPageInfo = pageInfo[location.pathname] || pageInfo['/duration'];


  useEffect(() => {
    const checkMobile = () => {
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      setIsMobile(isMobileDevice);
      setIsWideScreen(window.innerWidth >= 1024);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const addLog = (entry) => {
    setLogs(prevLogs => [entry, ...prevLogs]);
  };

  const deleteLog = (index) => {
    setLogs(prevLogs => prevLogs.filter((_, i) => i !== index));
  };

  const handleShare = (log) => {
    if (navigator.share) {
      navigator.share({
        title: 'DataCalc Log Entry',
        text: log
      }).catch(console.error);
    }
  };

  // First, add this helper function near the top of the App component
  const getCalculatorName = (path) => {
    switch (path) {
      case '/duration':
        return 'Duration Calculator';
      case '/camera':
        return 'Camera Calculator';
      case '/film':
        return 'Film Calculator';
      case '/aspect-ratio':
        return 'Aspect Ratio Calculator';
      case '/dailies':
        return 'Dailies Calculator';
      case '/bitrate':
        return 'Bitrate Calculator';
      default:
        return 'Calculator';
    }
  };

  useEffect(() => {
    localStorage.setItem('calculatorLogs', JSON.stringify(logs));
  }, [logs]);

  useEffect(() => {
    if (location.pathname !== '/logs') {
      setPreviousPath(location.pathname);
    }
  }, [location.pathname]);

  return (
    <CalculatorProvider>
      <div className={`app min-h-screen flex flex-col ${isMobile ? 'pt-1' : 'pt-2'} overflow-hidden`}>
        <div className="fixed top-0 left-0 right-0 z-10">
          <Header isMobile={isMobile} />
        </div>

        <div className={`flex-grow w-full ${isMobile ? 'p-0' : 'p-6'} mt-20 mb-20 bg-gray-100 overflow-hidden`}>
          <div className={`max-w-[1920px] mx-auto flex ${isWideScreen ? 'space-x-4' : 'flex-col'}`}>
            {/* Side Menu - only show on desktop */}
            {isWideScreen && (
              <div className="flex-shrink-0">
                <div className="sticky top">
                  <SideMenu isMobile={false} />
                </div>
              </div>
            )}

            {/* Main Content Area */}
            <div className={`flex ${isWideScreen ? 'flex-row space-x-4' : 'flex-col space-y-1'} w-full`}>
              <div className={`flex flex-col flex-grow basis-3/5 ${isMobile ? '' : 'space-y-2'}`}>
                {!isMobile && (
                  <div className="p-4 text-center border-b border-gray-200 bg-white rounded-lg shadow-md">
                    <h1 className="text-2xl font-bold mb-4">{currentPageInfo.title}</h1>
                    <p className="text-sm text-gray-600">{currentPageInfo.description}</p>
                  </div>
                )}
                
                <div className={`${
                  location.pathname === '/logs' 
                    ? "p-2" 
                    : isMobile 
                      ? "p-1.5 shadow-md rounded-xl bg-white" 
                      : "p-3 shadow-md rounded-xl bg-white"
                } `}>
                  <Routes>
                    <Route path="/duration" element={<DurationToFileSize addLog={addLog} />} />
                    <Route path="/video-duration-to-file-size-calculator" element={<DurationToFileSize addLog={addLog} />} />
                    <Route path="/camera" element={<CameraToFileSize addLog={addLog} />} />
                    <Route path="/camera-to-file-size-calculator" element={<CameraToFileSize addLog={addLog} />} />
                    <Route path="/aspect-ratio" element={<AspectRatioCalculator addLog={addLog} />} />
                    <Route path="/ratio" element={<AspectRatioCalculator addLog={addLog} />} />
                    <Route path="/aspect-ratio-calculator" element={<AspectRatioCalculator addLog={addLog} />} />
                    <Route path="/film" element={<FilmCalculator addLog={addLog} />} />
                    <Route path="/film-calculator" element={<FilmCalculator addLog={addLog} />} />
                    <Route path="/motion-picture-film" element={<FilmCalculator addLog={addLog} />} />
                    <Route path="/motion-picture-film-calculator" element={<FilmCalculator addLog={addLog} />} />
                    <Route path="/dailies" element={<DailiesCalculator addLog={addLog} />} />
                    <Route path="/dailies-calculator" element={<DailiesCalculator addLog={addLog} />} />
                    <Route path="/dailies-to-file-size-calculator" element={<DailiesCalculator addLog={addLog} />} />
                    <Route path="/bitrate" element={<BitrateCalculator addLog={addLog} />} />
                    <Route path="/bitrate-calculator" element={<BitrateCalculator addLog={addLog} />} />
                    <Route path="/comingsoon" element={<ComingSoon />} />
                    <Route path="/logs" element={
                      <div className="flex flex-col">
                        {isMobile && (
                          <div className="sticky top-2 z-10 p-2 mb-0">
                            <button 
                              onClick={() => navigate(-1)} 
                              className="w-full mx-auto py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center space-x-2 shadow-md"
                            >
                              {/* <span>← {getCalculatorName(location.state?.from || '/duration')}</span> */}
                              <span>← {getCalculatorName(previousPath)}</span>
                            </button>
                          </div>
                        )}
                        <Log 
                          log={logs} 
                          onDelete={deleteLog} 
                          onShare={handleShare} 
                          isMobile={isMobile} 
                          isFullPage={true}
                        />
                      </div>
                    } />
                    {/* <Route path="/bitrate" element={<BitrateCalculator addLog={addLog} />} /> */}
                    <Route path="/" element={<Navigate to="/duration" replace />} />
                  </Routes>
                </div>
              </div>

              {/* Right side log area - hidden on logs page but maintains spacing */}
              <div className={`${
                isMobile ? 'h-[calc(100vh-180px)]' : 'mb-20'
              } flex flex-col ${
                window.innerWidth <= 1024 ? 'w-full' : 'w-[40%]'
              } ${location.pathname === '/logs' ? 'invisible' : ''}`}>
                <div 
                  className={`${isMobile ? 'p-1 m-1' : 'p-2 mb-2'} text-center bg-white rounded-lg shadow-md flex-shrink-0`}
                  onClick={() => isMobile && navigate('/logs', { state: { from: location.pathname } })}
                  style={{ cursor: isMobile ? 'pointer' : 'default' }}
                >
                  <h2 className={`${isMobile ? 'text-md' : 'text-lg'} font-semibold text-gray-700`}>
                    Log {isMobile && '(tap to view all)'}
                  </h2>
                </div>
                <div className={`flex-1 notes ${isMobile ? 'overflow-hidden' : 'overflow-visible'}`}>
                  <div className={`${isMobile ? 'h-full' : ''}`}>
                    <Log 
                      log={logs} 
                      onDelete={deleteLog} 
                      onShare={handleShare} 
                      isMobile={isMobile} 
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed bottom-0 left-0 right-0">
          <Footer setShowPrivacyPolicy={setShowPrivacyPolicy} isMobile={isMobile} />
        </div>

        {showPrivacyPolicy && (
          <PrivacyPolicy onClose={() => setShowPrivacyPolicy(false)} />
        )}
      </div>
    </CalculatorProvider>
  );
}

export default App;
