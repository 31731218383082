export const resolutions = {
    "SD (720 x 486)": { dimensions: [720, 486] },
    "720p (1280 x 720)": { dimensions: [1280, 720] },
    "1080p (1920 x 1080)": { dimensions: [1920, 1080] },
    "2K Flat (1998 x 1080)": { dimensions: [1998, 1080] },
    "2K Scope (2048 x 858)": { dimensions: [2048, 858] },
    "2K DCI (2048 x 1080)": { dimensions: [2048, 1080] },
    "2K (2048 x 1152)": { dimensions: [2048, 1152] },
    "UHD (3840 x 2160)": { dimensions: [3840, 2160] },
    "4K Flat (3996 x 2160)": { dimensions: [3996, 2160] },
    "4K Scope (4096 x 1716)": { dimensions: [4096, 1716] },
    "4K DCI (4096 x 2160)": { dimensions: [4096, 2160] },
    "5K (5120 x 2880)": { dimensions: [5120, 2880] },
    "6K (6016 x 3384)": { dimensions: [6016, 3384] },
    "8K (8192 x 4320)": { dimensions: [8192, 4320] },
    "12K (12288 x 6480)": { dimensions: [12288, 6480] },
    "16K (16384 x 8294)": { dimensions: [15360, 8294] },
};

export const cameraResolutions = {
     "720p (1280 x 720)": { dimensions: [1280, 720] },
     "1080p (1920 x 1080)": { dimensions: [1920, 1080] },
     "HD (1920 x 1080)": { dimensions: [1920, 1080] },
     "2K (2048 x 864)": { dimensions: [2048, 864] },
     "2K DCI (2048 x 1080)": { dimensions: [2048, 1080] },
     "2K 16:9 (2048 x 1152)": { dimensions: [2048, 1152] },
     "2.2K 16:9 (2208 x 1242)": { dimensions: [2208, 1242] },
     "2.5K (2560 x 1440)": { dimensions: [2560, 1440] },
     "2.5K (2560 x 1600)": { dimensions: [2560, 1600] },
     "HD Ana. (1920 x 2160)": { dimensions: [1920, 2160] },
     "2.6K 6:5 (2624 x 2192)": { dimensions: [2624, 2192] },
     "2.6K 16:9 (2688 x 1512)": { dimensions: [2688, 1512] },
     "2.7K (2704 x 1520)": { dimensions: [2704, 1520] },
     "2.8K 17:9 (2880 x 1512)": { dimensions: [2880, 1512] },
     "2.8K 16:9 (2880 x 1620)": { dimensions: [2880, 1620] },
     "2.8K 4:3 (2880 x 2160)": { dimensions: [2880, 2160] },
     "2.8K LF 1:1 (2880 x 2880)": { dimensions: [2880, 2880] },
     "3.2K 16:9 (3200 x 1800)": { dimensions: [3200, 1800] },
     "3.2K S35 16:9 (3200 x 1800)": { dimensions: [3200, 1800] },
     "3.2K 3:2 (3200 x 2400)": { dimensions: [3200, 2400] },
     "3.4K LF 3:2 (3424 x 2202)": { dimensions: [3424, 2202] },
     "3.4K Open Gate (3424 x 2202)": { dimensions: [3424, 2202] },
     "3.4K 6:5 (3424 x 2856)": { dimensions: [3424, 2856] },
     "3.8K 16:9 (3840 x 2160)": { dimensions: [3840, 2160] },
     "UHD (3840 x 2160)": { dimensions: [3840, 2160] },
     "4K Scope 2.39:1 (4096 x 1716)": { dimensions: [4096, 1716] },
     "4K (4096 x 1728)": { dimensions: [4096, 1728] },
     "4K 2:1 (4096 x 2048)": { dimensions: [4096, 2048] },
     "4K 17:9 (4096 x 2160)": { dimensions: [4096, 2160] },
     "4K 16:9 (4096 x 2304)": { dimensions: [4096, 2304] },
     "4K 4:3 (4096 x 3072)": { dimensions: [4096, 3072] },
     "4K 6:5 (4096 x 3432)": { dimensions: [4096, 3432] },
     "4.3K 16:9 (4320 x 2430)": { dimensions: [4320, 2430] },
     "4.3K LF 16:9 (4320 x 2430)": { dimensions: [4320, 2430] },
     "4.5K LF 2.39:1 (4448 x 1856)": { dimensions: [4448, 1856] },
     "4.5K LF 3:2 Open Gate (4448 x 3096)": { dimensions: [4448, 3096] },
     "4.5K (4608 x 2160)": { dimensions: [4608, 2160] },
     "4.6K 16:9 (4608 x 2592)": { dimensions: [4608, 2592] },
     "4.6K 3:2 Open Gate (4608 x 3164)": { dimensions: [4608, 3164] },
     "5K (5120 x 2700)": { dimensions: [5120, 2700] },
     "5K (5120 x 2880)": { dimensions: [5120, 2880] },
     "5.3K (5312 x 2988)": { dimensions: [5312, 2988] },
     "5.4K 16:9 (5434 x 3056)": { dimensions: [5434, 3056] },
     "5.5K (5472 x 2886)": { dimensions: [5472, 2886] },
     "5.7K 16:9 (5674 x 3192)": { dimensions: [5674, 3192] },
     "5.7K (5720 x 3016)": { dimensions: [5720, 3016] },
     "5.7K (5728 x 3024)": { dimensions: [5728, 3024] },
     "5.7K 16:9 (5728 x 3024)": { dimensions: [5728, 3024] },
     "5.8K 17:9 (5792 x 3056)": { dimensions: [5792, 3056] },
     "5.8K 6:5 (5792 x 4854)": { dimensions: [5792, 4854] },
     "5.9K (5952 x 3140)": { dimensions: [5952, 3140] },
     "6K (5952 x 3968)": { dimensions: [5952, 3968] },
     "6K DCI (6016 x 3200)": { dimensions: [6016, 3200] },
     "6K UHD (6016 x 3384)": { dimensions: [6016, 3384] },
     "6K 2.39:1 (6048 x 2534)": { dimensions: [6048, 2534] },
     "6K (6048 x 3160)": { dimensions: [6048, 3160] },
     "6K 3:2 (6048 x 4032)": { dimensions: [6048, 4032] },
     "6K 17:9 (6054 x 3192)": { dimensions: [6054, 3192] },
     "6K 1.85:1 (6054 x 3272)": { dimensions: [6054, 3272] },
     "6K 2.40:1 (6144 x 2560)": { dimensions: [6144, 2560] },
     "6K (6144 x 2592)": { dimensions: [6144, 2592] },
     "6K (6144 x 3160)": { dimensions: [6144, 3160] },
     "6K (6144 x 3240)": { dimensions: [6144, 3240] },
     "6K DCI (6144 x 3240)": { dimensions: [6144, 3240] },
     "6K 16:9 (6144 x 3456)": { dimensions: [6144, 3456] },
     "6.2K (6240 x 4160)": { dimensions: [6240, 4160] },
     "7.6K 16:9 (7680 x 4320)": { dimensions: [7680, 4320] },
     "8K (7680 x 4320)": { dimensions: [7680, 4320] },
     "8K (8192 x 3456)": { dimensions: [8192, 3456] },
     "8K DCI (8192 x 4320)": { dimensions: [8192, 4320] },
     "8.6K (8640 x 4320)": { dimensions: [8640, 4320] },
     "8.2K 17:9 (8640 x 4556)": { dimensions: [8640, 4556] },
     "8.6K 16:9 FF (8632 x 4856)": { dimensions: [8632, 4856] },
     "8.6K 3:2 (8640 x 5760)": { dimensions: [8640, 5760] },
     "12K (12288 x 6480)": { dimensions: [12288, 6480 ]}
};