import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SlDocs, SlNote, SlRefresh, SlLike, SlLink } from "react-icons/sl";
import { FaLock, FaUnlockAlt, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { codecs } from '../data/codecData';
import { resolutions } from '../data/resolutionData';
import { disableScroll, enableScroll } from '../utils';
import { useCalculator } from '../context/CalculatorContext';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import { useDropdownTouch } from '../hooks/useDropdownTouch';



// Timecode class for handling timecode operations
class Timecode {
  constructor(hours = 0, minutes = 0, seconds = 0, frames = 0, frameRate = 24) {
    this.hours = Math.floor(hours);
    this.minutes = Math.floor(minutes);
    this.seconds = Math.floor(seconds);
    this.frames = Math.floor(frames);
    this.frameRate = frameRate;
  }

  // Parse a timecode string into a Timecode object
  static parse(timecodeString, frameRate = 24) {
    const parts = timecodeString.split(/[:.]/).map(Number);
    while (parts.length < 4) parts.unshift(0);
    const [hours, minutes, seconds, frames] = parts;
    return new Timecode(hours, minutes, seconds, frames, frameRate);
  }
  
  // Convert Timecode object to string
  toString() {
    return `${this.hours.toString().padStart(2, '0')}:${this.minutes.toString().padStart(2, '0')}:${this.seconds.toString().padStart(2, '0')}:${this.frames.toString().padStart(2, '0')}`;
  }

  // Calculate total frames
  totalFrames() {
    return this.hours * 3600 * this.frameRate + 
           this.minutes * 60 * this.frameRate + 
           this.seconds * this.frameRate + 
           this.frames;
  }

  // Create a Timecode object from total frames
  static fromFrames(totalFrames, frameRate = 24) {
    const hours = Math.floor(totalFrames / (3600 * frameRate));
    totalFrames %= 3600 * frameRate;
    const minutes = Math.floor(totalFrames / (60 * frameRate));
    totalFrames %= 60 * frameRate;
    const seconds = Math.floor(totalFrames / frameRate);
    const frames = Math.floor(totalFrames % frameRate);

    return new Timecode(hours, minutes, seconds, frames, frameRate);
  }
}

// Define default framerates and resolutions
const defaultFramerates = ["1", "12", "23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"];
const defaultResolutions = Object.keys(resolutions);

const DurationToFileSize = ({ addLog }) => {
  // Get context
  const { durationCalcState, setDurationCalcState } = useCalculator();

  // Initialize state from context or defaults
  const [calcData, setCalcData] = useState(durationCalcState.calcData || {
    duration: "01:00:00:00",
    resolution: "UHD (3840 x 2160)",
    codec: "Apple ProRes 422 HQ",
    framerate: "23.976",
    fileSize: "310.328"
  });
  
  const [durationInput, setDurationInput] = useState(durationCalcState.durationInput || "01:00:00:00");
  const [fileSizeInput, setFileSizeInput] = useState(durationCalcState.fileSizeInput || "310.328 GB");
  const [fileSizeUnit, setFileSizeUnit] = useState(durationCalcState.fileSizeUnit || "GB");
  const [isDurationLocked, setIsDurationLocked] = useState(durationCalcState.isDurationLocked || false);
  const [isFileSizeLocked, setIsFileSizeLocked] = useState(durationCalcState.isFileSizeLocked || false);
  const [calculationMode, setCalculationMode] = useState(durationCalcState.calculationMode || null);

  // Add effect to save state to context when it changes
  useEffect(() => {
    setDurationCalcState({
      calcData,
      durationInput,
      fileSizeInput,
      fileSizeUnit,
      isDurationLocked,
      isFileSizeLocked,
      calculationMode
    });
  }, [
    calcData,
    durationInput,
    fileSizeInput,
    fileSizeUnit,
    isDurationLocked,
    isFileSizeLocked,
    calculationMode,
    setDurationCalcState
  ]);

  // Add effect to handle URL parameters
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('duration')) {
      const newCalcData = {
        duration: params.get('duration') || "01:00:00:00",
        resolution: params.get('resolution') || "UHD (3840 x 2160)",
        codec: params.get('codec') || "Apple ProRes 422 HQ",
        framerate: params.get('framerate') || "23.976",
        fileSize: params.get('fileSize') || "310.328"
      };
      setCalcData(newCalcData);
      setDurationInput(params.get('duration') || "01:00:00:00");
      setFileSizeInput(params.get('fileSize') ? `${params.get('fileSize')} GB` : "310.328 GB");
    }
  }, []);

  // Update handleReset to also clear context
  const handleReset = () => {
    const defaultValues = {
      duration: "01:00:00:00",
      resolution: "UHD (3840 x 2160)",
      codec: "Apple ProRes 422 HQ",
      framerate: "23.976",
      fileSize: "310.328"
    };

    setCalcData(defaultValues);
    setDurationInput(defaultValues.duration);
    setFileSizeInput(`${defaultValues.fileSize} GB`);
    setFileSizeUnit("GB");
    setIsDurationLocked(false);
    setIsFileSizeLocked(false);
    setCalculationMode(null);

    // Reset context state
    setDurationCalcState({
      calcData: defaultValues,
      durationInput: defaultValues.duration,
      fileSizeInput: `${defaultValues.fileSize} GB`,
      fileSizeUnit: "GB",
      isDurationLocked: false,
      isFileSizeLocked: false,
      calculationMode: null
    });

    setResetButtonText(<SlLike />);
    setTimeout(() => setResetButtonText(<SlRefresh />), 500);
  };

  // Function to calculate duration based on file size and other parameters
  const calculateDuration = (data = calcData, fileSizeInput) => {
    if (!data) {
      console.error('Invalid data for duration calculation');
      return "00:00:00:01"; // Ensure at least 1 frame
    }

    // Constants for time calculations
    const SECONDS_PER_MINUTE = 60;
    const MINUTES_PER_HOUR = 60;
    const HOURS_PER_DAY = 24;
    
    // Find the selected codec
    const selectedCodec = codecs.find(codec => codec.name === data.codec);
    if (!selectedCodec) {
      console.error('Invalid codec');
      return "00:00:00:01"; // Ensure at least 1 frame
    }
    const datarate = selectedCodec.datarate;

    // Calculate resolution value
    let resolutionValue;
    if (data.resolution.startsWith('Custom')) {
      const match = data.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
      if (match) {
        const [, width, height] = match;
        resolutionValue = parseInt(width) * parseInt(height);
      } else {
        console.error('Invalid custom resolution format');
        return "00:00:00:01"; // Ensure at least 1 frame
      }
    } else {
      const resolutionData = resolutions[data.resolution];
      if (!resolutionData || !resolutionData.dimensions) {
        console.error('Invalid resolution');
        return "00:00:00:01"; // Ensure at least 1 frame
      }
      const [width, height] = resolutionData.dimensions;
      resolutionValue = width * height;
    }

    const fps = parseFloat(data.framerate);
    const parsedSize = parseFileSize(fileSizeInput || `${data.fileSize} ${fileSizeUnit}`);
    if (!parsedSize) {
      console.error('Invalid file size');
      return "00:00:00:01"; // Ensure at least 1 frame
    }

    // Unit multipliers for file size calculations
    const unitMultiplier = {
      'B': 1,
      'KB': 1024,
      'MB': 1024 * 1024,
      'GB': 1024 * 1024 * 1024,
      'TB': 1024 * 1024 * 1024 * 1024,
      'PB': 1024 * 1024 * 1024 * 1024 * 1024
    };

    const size_in_bytes = parseFloat(parsedSize.size) * unitMultiplier[parsedSize.unit];

    const bytes_per_second = datarate * resolutionValue * fps;

    let total_seconds = size_in_bytes / (bytes_per_second * 1024 * 1024 / 8);

    // Calculate hours, minutes, seconds, and frames
    let frames = Math.floor((total_seconds % 1) * fps);
    total_seconds = Math.floor(total_seconds);
    const seconds = total_seconds % SECONDS_PER_MINUTE;
    total_seconds = Math.floor(total_seconds / SECONDS_PER_MINUTE);
    const minutes = total_seconds % MINUTES_PER_HOUR;
    total_seconds = Math.floor(total_seconds / MINUTES_PER_HOUR);
    const hours = total_seconds; // Allow hours to exceed 24

    // Ensure at least 1 frame
    if (hours === 0 && minutes === 0 && seconds === 0 && frames === 0) {
      frames = 1;
    }

    // Format the result, allowing for hours to exceed 24
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
  };

  // State variables
  const [copyStatus, setCopyStatus] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [customResolutions, setCustomResolutions] = useState([]);
  const [customFramerates, setCustomFramerates] = useState([]);
  const [logs, setLogs] = useState([]);
  const [copiedLogIndex, setCopiedLogIndex] = useState(null);
  const [copyDurationText, setCopyDurationText] = useState(<SlDocs />);
  const [copyFileSizeText, setCopyFileSizeText] = useState(<SlDocs />);
  const [logButtonText, setLogButtonText] = useState(<SlNote />);
  const [pinnedField, setPinnedField] = useState(null); // 'duration', 'fileSize', or null
  const [shareButtonText, setShareButtonText] = useState(<SlLink />);
  const [resetButtonText, setResetButtonText] = useState(<SlRefresh />);
  const [previousResolution, setPreviousResolution] = useState("UHD (3840 x 2160)");
  const [isCustomResolution, setIsCustomResolution] = useState(false);
  const [isCustomFramerate, setIsCustomFramerate] = useState(false);

  // Refs for DOM elements
  const resolutionRef = useRef(null);
  const codecRef = useRef(null);
  const durationRef = useRef(null);
  const framerateRef = useRef(null);
  const fileSizeRef = useRef(null);
  const customResolutionRef = useRef(null);
  const customFramerateRef = useRef(null);
  const logRef = useRef(null);

  const commonFramerates = [
    "1", "12", "23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"];

  // Convert resolutions object to array for select options
  const resolutionOptions = Object.keys(resolutions);

  // Get resolution string for display
  let resolutionString;
  if (calcData.resolution === 'Custom') {
    resolutionString = calcData.customResolution;
  } else {
    const match = calcData.resolution.match(/\((\d+\s*x\s*\d+)\)/);
    resolutionString = match ? match[1].replace(/\s+/g, '') : calcData.resolution;
  }

  // Ref for previous calcData
  const prevCalcData = useRef(calcData);


  // Effect to check if the device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);


  // Update the handleDurationPinClick function
  const handleDurationPinClick = () => {
    if (isDurationLocked) {
      // Unpin duration
      setIsDurationLocked(false);
      setCalculationMode(null);
    } else {
      // Pin duration and unpin file size
      setIsDurationLocked(true);
      setIsFileSizeLocked(false);
      setCalculationMode('duration');
      setCalcData(prevData => ({
        ...prevData,
        duration: durationInput,
        fileSize: parseFloat(fileSizeInput.split(' ')[0]) // Ensure fileSize is correctly set
      }));
    }
  };

  // Handler for file size pin click
  const handleFileSizePinClick = () => {
    if (isFileSizeLocked) {
      // Unpin file size
      setIsFileSizeLocked(false);
      setCalculationMode(null);
    } else {
      // Pin file size and unpin duration
      setIsFileSizeLocked(true);
      setIsDurationLocked(false);
      setCalculationMode('fileSize');
      setCalcData(prevData => ({
        ...prevData,
        fileSize: parseFloat(fileSizeInput.split(' ')[0]),
        duration: durationInput
      }));
    }
  };

  // Handler for input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'duration') {
      setDurationInput(value);
    } else if (name === 'fileSize') {
      setFileSizeInput(value);
    } else if (['resolution', 'codec', 'framerate'].includes(name)) {
      setCalcData(prevData => ({
        ...prevData,
        [name]: value
      }));
      
      // Only recalculate if file size is NOT pinned
      if (calculationMode !== 'fileSize') {
        // If file size is not pinned, recalculate file size based on current duration
        const { size, unit } = calculateFileSize();
        setFileSizeInput(`${size} ${unit}`);
        setCalcData(prevData => ({
          ...prevData,
          fileSize: size,
          [name]: value
        }));
        setFileSizeUnit(unit);
      } else {
        // If file size is pinned, recalculate duration instead
        const duration = calculateDuration({ ...calcData, [name]: value }, fileSizeInput);
        setDurationInput(duration);
        setCalcData(prevData => ({
          ...prevData,
          duration: duration,
          [name]: value
        }));
      }
    }
  };

  // Function to format duration
  const formatDuration = (value) => {
    const parts = value.split(':').filter(part => part !== '');
    while (parts.length < 4) {
      parts.unshift('00');
    }
    return parts.join(':');
  };

  // Handler for duration focus
  const handleDurationFocus = () => {
    if (!isDurationLocked) {
      setDurationInput('');
    }
  };

  // Function to parse duration input
  const parseDuration = (input, framerate) => {
    // Replace all periods with ':00' to ensure they become two zeros
    input = input.replace(/\./g, ':00');

    if (!input || input.trim() === '') {
      return new Timecode(0, 0, 0, 0, framerate).toString();
    }

    input = input.trim().toLowerCase();

    // Handle single number input - interpret as seconds by default
    if (/^\d+$/.test(input)) {
      const seconds = parseInt(input, 10);
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      
      return new Timecode(hours, minutes, remainingSeconds, 0, framerate).toString();
    }

    // Handle input with colons after replacing periods
    if (/^\d+([:]\d+)*$/.test(input)) {
      const parts = input.split(':').map(Number);
      while (parts.length < 4) parts.unshift(0);
      let [hours, minutes, seconds, frames] = parts;

      // Adjust for overflow
      if (frames >= framerate) {
        seconds += Math.floor(frames / framerate);
        frames %= framerate;
      }
      if (seconds >= 60) {
        minutes += Math.floor(seconds / 60);
        seconds %= 60;
      }
      if (minutes >= 60) {
        hours += Math.floor(minutes / 60);
        minutes %= 60;
      }

      // Ensure at least 1 frame
      const totalFrames = Math.max(1, (hours * 3600 + minutes * 60 + seconds) * framerate + frames);
      
      return Timecode.fromFrames(totalFrames, framerate).toString();
    }

    // Define regex patterns for different time units
    const patterns = {
      week: /(\d+)\s*(?:w|wk|wks|week|weeks)/i,
      day: /(\d+)\s*(?:d|dy|dys|day|days)/i,
      hour: /(\d+)\s*(?:h|hr|hrs|hour|hours)/i,
      minute: /(\d+)\s*(?:m|min|mins|minute|minutes)/i,
      second: /(\d+)\s*(?:s|sec|secs|second|seconds)/i,
      frame: /(\d+)\s*(?:f|fr|frm|frms|frame|frames)/i
    };

    let totalFrames = 0;

    Object.entries(patterns).forEach(([unit, pattern]) => {
      const match = input.match(pattern);
      if (match) {
        const value = parseInt(match[1]);
        switch (unit) {
          case 'week': totalFrames += value * 7 * 24 * 3600 * framerate; break;
          case 'day': totalFrames += value * 24 * 3600 * framerate; break;
          case 'hour': totalFrames += value * 3600 * framerate; break;
          case 'minute': totalFrames += value * 60 * framerate; break;
          case 'second': totalFrames += value * framerate; break;
          case 'frame': totalFrames += value; break;
        }
      }
    });

    if (totalFrames === 0) {
      // If no valid time units were found, try parsing as a single number (assume seconds)
      const totalSeconds = parseFloat(input);
      if (!isNaN(totalSeconds)) {
        totalFrames = Math.round(totalSeconds * framerate);
      } else {
        throw new Error("Invalid duration format");
      }
    }

    return Timecode.fromFrames(totalFrames, framerate).toString();
  };

  const handleShare = () => {
    const shareUrl = generateShareUrl();

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(shareUrl)
        .then(handleShareSuccess)
        .catch(handleShareError);
    } else if (navigator.share) {
      navigator.share({
        title: 'Duration to File Size Calculation',
        text: 'Check out this calculation',
        url: shareUrl,
      })
        .then(handleShareSuccess)
        .catch(handleShareError);
    } else {
      // Fallback for browsers that don't support clipboard API or Web Share API
      const textArea = document.createElement("textarea");
      textArea.value = shareUrl;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        handleShareSuccess();
      } catch (err) {
        handleShareError(err);
      }
      document.body.removeChild(textArea);
    }
  };

  const handleShareSuccess = () => {
    setShareButtonText(<SlLike />);
    setTimeout(() => setShareButtonText(<SlLink />), 500);
  };

  const handleShareError = (error) => {
    console.error('Failed to copy or share: ', error);
  };

  const handleShareLog = () => {
    const shareUrl = generateShareUrl();
    handleShare(isMobile, 'Duration to File Size', `${resolutionString} ${calcData.codec} @ ${calcData.framerate}fps ${calcData.duration} = ${fileSizeInput}`, shareUrl, () => {
      setShareButtonText(<SlLike />);
      setTimeout(() => setShareButtonText(<SlLink />), 500);
    });
  };

  const handleShareLogError = (error) => {
    console.error('Failed to copy or share: ', error);
  };

  const generateShareUrl = () => {
    const params = new URLSearchParams({
      duration: calcData.duration,
      resolution: calcData.resolution,
      codec: calcData.codec,
      framerate: calcData.framerate,
      fileSize: fileSizeInput,
      calculationMode: calculationMode || '',
      pinnedField: pinnedField || ''
    });
    return `${window.location.origin}${window.location.pathname}?${params.toString()}`;
  };


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('duration')) {
      setCalcData({
        duration: params.get('duration'),
        resolution: params.get('resolution'),
        codec: params.get('codec'),
        framerate: params.get('framerate'),
        fileSize: params.get('fileSize'),
      });
      setDurationInput(params.get('duration'));
      setFileSizeInput(`${params.get('fileSize')} GB`);
    }
  }, []);

  // Handler for duration blur event
  const handleDurationBlur = () => {
    if (durationInput.trim() === '') {
      setDurationInput('');
      if (!isDurationLocked) {
        setCalcData(prevData => ({ ...prevData, duration: '' }));
      }
    } else {
      try {
        const parsedDuration = parseDuration(durationInput, parseFloat(calcData.framerate));
        setDurationInput(parsedDuration);
        if (!isDurationLocked) {
          setCalcData(prevData => ({
            ...prevData,
            duration: parsedDuration
          }));
          // Recalculate file size if needed
          if (!isFileSizeLocked) {
            const { size, unit } = calculateFileSize();
            setFileSizeInput(`${size} ${unit}`);
            setFileSizeUnit(unit);
          }
        }
      } catch (error) {
        console.error("Invalid duration format");
        setDurationInput('');
        if (!isDurationLocked) {
          setCalcData(prevData => ({ ...prevData, duration: '' }));
        }
      }
    }
  };

  // Handler for duration key press
  const handleDurationKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!isDurationLocked) {
        try {
          const parsedDuration = parseDuration(durationInput, parseFloat(calcData.framerate));
          setDurationInput(parsedDuration);
          setCalcData(prev => ({
            ...prev,
            duration: parsedDuration
          }));
          // Recalculate file size based on the parsed duration
          if (!isFileSizeLocked) {
            const { size, unit } = calculateFileSize({ ...calcData, duration: parsedDuration });
            setFileSizeInput(`${size} ${unit}`);
            setCalcData(prev => ({
              ...prev,
              fileSize: size
            }));
            setFileSizeUnit(unit);
          }
          // Blur the input field
          e.target.blur();
        } catch (error) {
          console.error("Invalid duration format");
          // Optionally, notify the user or revert to the previous valid duration
        }
      }
    }
  };

  // Update the handleWheel function to handle all dropdown types
  const handleWheel = (e, options, currentValue, setterFunction, fieldType) => {
    e.preventDefault();
    const index = options.indexOf(currentValue);
    const sensitivity = 0.05;
    const scrollAmount = -e.deltaY * sensitivity;
    
    let newIndex = index + Math.sign(scrollAmount);
    newIndex = Math.max(0, Math.min(newIndex, options.length - 1));
    
    if (newIndex !== index) {
      const newValue = options[newIndex];
      
      // Recalculate based on pin state
      if (calculationMode === 'fileSize') {
        // If file size is pinned, only update duration
        const duration = calculateDuration({ 
          ...calcData, 
          [fieldType]: newValue 
        }, fileSizeInput);
        
        setDurationInput(duration);
        setCalcData(prev => ({
          ...prev,
          [fieldType]: newValue,
          duration: duration
        }));
      } else {
        // If duration is pinned or nothing is pinned, update file size
        const { size, unit } = calculateFileSize({ 
          ...calcData, 
          [fieldType]: newValue 
        });
        
        setFileSizeInput(`${size} ${unit}`);
        setCalcData(prev => ({
          ...prev,
          [fieldType]: newValue,
          fileSize: size
        }));
        setFileSizeUnit(unit);
      }
    }
  };

  // Update the wheel handlers to pass the field type
  const handleResolutionWheel = (e) => {
    handleWheel(
      e, 
      [...resolutionOptions, ...customResolutions], 
      calcData.resolution,
      () => {}, // We don't need the setter function anymore
      'resolution'
    );
  };

  const handleFramerateWheel = (e) => {
    handleWheel(
      e, 
      [...defaultFramerates, ...customFramerates], 
      calcData.framerate,
      () => {}, // We don't need the setter function anymore
      'framerate'
    );
  };

  const handleCodecWheel = (e) => {
    handleWheel(
      e, 
      codecs.map(codec => codec.name), 
      calcData.codec,
      () => {}, // We don't need the setter function anymore
      'codec'
    );
  };

  const handleDurationWheel = (e) => {
    e.preventDefault();
    if (isDurationLocked) return; // Immediately return if duration is locked

    const sensitivity = 0.025;
    const scrollAmount = -e.deltaY * sensitivity; // Note the negative sign here
    
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    
    let [hours, minutes, seconds, frames] = calcData.duration.split(':').map(Number);
    const framerate = parseFloat(calcData.framerate);
    
    if (x < width * 0.25) {
      // Scrolling hours
      hours += Math.sign(scrollAmount);
      hours = Math.max(0, hours);
    } else if (x < width * 0.5) {
      // Scrolling minutes
      minutes += Math.sign(scrollAmount);
      if (minutes < 0) {
        if (hours > 0) {
          hours--;
          minutes = 59;
        } else {
          minutes = 0;
        }
      } else if (minutes >= 60) {
        hours++;
        minutes = 0;
      }
    } else if (x < width * 0.75) {
      // Scrolling seconds
      seconds += Math.sign(scrollAmount);
      if (seconds < 0) {
        if (minutes > 0) {
          minutes--;
          seconds += 60;
        } else if (hours > 0) {
          hours--;
          minutes = 59;
          seconds += 60;
        } else {
          seconds = 0;
        }
      } else if (seconds >= 60) {
        minutes++;
        seconds -= 60;
        if (minutes >= 60) {
          hours++;
          minutes -= 60;
        }
      }
    } else {
      // Scrolling frames
      frames += Math.sign(scrollAmount);
      if (frames < 0) {
        if (seconds > 0) {
          seconds--;
          frames += Math.floor(framerate);
        } else if (minutes > 0) {
          minutes--;
          seconds = 59;
          frames += Math.floor(framerate);
        } else if (hours > 0) {
          hours--;
          minutes = 59;
          seconds = 59;
          frames += Math.floor(framerate);
        } else {
          frames = 0;
        }
      } else if (frames >= Math.floor(framerate)) {
        seconds++;
        frames -= Math.floor(framerate);
        if (seconds >= 60) {
          minutes++;
          seconds -= 60;
          if (minutes >= 60) {
            hours++;
            minutes -= 60;
          }
        }
      }
    }

    const newDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
    setDurationInput(newDuration);
    setCalcData(prevData => ({ ...prevData, duration: newDuration }));

    // Recalculate file size
    const { size, unit } = calculateFileSize();
    setFileSizeInput(`${size} ${unit}`);
    setCalcData(prevData => ({
      ...prevData,
      fileSize: size
    }));
    setFileSizeUnit(unit);
  };

  const calculateFileSize = (data = calcData) => {
    try {
      console.log("Starting file size calculation with data:", data);

      const [hours, minutes, seconds, frames] = data.duration.split(':').map(Number);
      const totalFrames = (hours * 3600 + minutes * 60 + seconds) * parseFloat(data.framerate) + frames;
      console.log("Total frames:", totalFrames);

      let resolutionValue;
      if (data.resolution.startsWith('Custom')) {
        const match = data.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
        if (match) {
          const [, width, height] = match;
          resolutionValue = parseInt(width) * parseInt(height);
          console.log("Custom resolution parsed:", width, "x", height);
        } else {
          throw new Error(`Invalid custom resolution format: ${data.resolution}`);
        }
      } else {
        const resolutionData = resolutions[data.resolution];
        console.log("Resolution data:", resolutionData);
        if (!resolutionData || !resolutionData.dimensions) {
          throw new Error(`Invalid resolution: ${data.resolution}`);
        }
        const [width, height] = resolutionData.dimensions;
        resolutionValue = width * height;
      }
      console.log("Resolution value:", resolutionValue);

      const selectedCodec = codecs.find(codec => codec.name === data.codec);
      console.log("Selected codec:", selectedCodec);
      if (!selectedCodec) {
        throw new Error(`Invalid codec: ${data.codec}`);
      }

      const megapixels = resolutionValue;
      const datarate = selectedCodec.datarate;
      const fileSizeGB = (totalFrames * megapixels * datarate) / 8 / 1024;
      console.log("Calculated file size in GB:", fileSizeGB);

      const { size, unit } = formatFileSize(fileSizeGB);
      console.log("Formatted file size:", size, unit);

      return { size, unit };
    } catch (error) {
      console.error("Error calculating file size:", error.message);
      return { size: '0', unit: 'B' };
    }
  };

  // Helper function to format file size
  const formatFileSize = (sizeInGB) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let size = sizeInGB * 1024 * 1024 * 1024; // Convert GB to bytes
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return {
      size: size.toFixed(3),
      unit: units[unitIndex]
    };
  };

  // 8. Modify useEffect to handle recalculations based on calculationMode
  useEffect(() => {
    // Skip calculations if the resolution is set to 'Custom' and the custom resolution is not yet confirmed
    if (calcData.resolution === 'Custom' && !calcData.customResolution) {
      return;
    }

    recalculateBasedOnPinState();
  }, [
    calcData.resolution,
    calcData.codec,
    calcData.framerate,
    calcData.duration,
    calcData.fileSize,
    isDurationLocked,
    isFileSizeLocked,
    calculationMode
  ]);

  useEffect(() => {
    const durationElement = durationRef.current;

    const wheelHandler = (e) => {
      if (!isDurationLocked) {
        handleDurationWheel(e);
      }
    };

    if (durationElement) {
      durationElement.addEventListener('wheel', wheelHandler, { passive: false });
    }

    return () => {
      if (durationElement) {
        durationElement.removeEventListener('wheel', wheelHandler);
      }
    };
  }, [calcData.duration, calcData.framerate, isDurationLocked]);

  const handleFileSizeClick = () => {
    if (isMobile) return;

    const fileSizeText = `${calcData.fileSize} ${fileSizeUnit}`;
    navigator.clipboard.writeText(fileSizeText).then(() => {
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleLogClick = () => {
    const logEntry = `${resolutionString} ${calcData.codec} @ ${calcData.framerate}fps \n${calcData.duration} = ${calcData.fileSize} ${fileSizeUnit}\n`;
    addLog(logEntry);  // Use the addLog function passed from App.js

    if (isMobile) {
      const copyToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          setLogButtonText(<SlLike />);
        } catch (err) {
          console.error('Failed to copy: ', err);
          setLogButtonText(<SlLike />);
        }
        document.body.removeChild(textArea);
        setTimeout(() => setLogButtonText(<SlNote />), 500);
      };

      copyToClipboard(logEntry);
    } else {
      navigator.clipboard.writeText(logEntry)
        .then(() => {
          setLogButtonText(<SlLike />);
          setTimeout(() => setLogButtonText(<SlNote />), 500);
        })
        .catch(err => {
          console.error('Failed to copy log entry: ', err);
          setLogButtonText(<SlLike />);
          setTimeout(() => setLogButtonText(<SlNote />), 500);
        });
    }
  };

  const copyLogToClipboard = (log, index) => {
    if (isMobile) return; 

    const logWithLineBreak = `${log}\n`; // Add line break

    navigator.clipboard.writeText(logWithLineBreak).then(() => {
      setCopiedLogIndex(index);
      setTimeout(() => setCopiedLogIndex(null), 3000);
    }).catch(err => {
      console.error('Failed to copy log: ', err);
      setCopiedLogIndex(null);
    });
  };
  
  useEffect(() => {
    if (isMobile) {
      disableScroll();
      return () => enableScroll();
    }
  }, [isMobile]);

  /**
   * Parses and validates the custom resolution input.
   * Allows any non-digit character as a separator.
   * @param {string} value - The custom resolution input string.
   * @returns {string|null} - Returns the formatted resolution string or null if invalid.
   */
  const parseCustomResolution = (value) => {
    // Split the input by ' x ' or any non-digit characters
    const numbers = value.split(/\s*x\s*|\D+/).map(Number).filter(num => !isNaN(num));
  
    if (numbers.length >= 2) {
      const [width, height] = numbers;
      if (Number.isInteger(width) && Number.isInteger(height) && width > 0 && height > 0) {
        return `${width} x ${height}`;
      }
    }
    
    return null;
  };

  const handleResolutionChange = (e) => {
    const { value } = e.target;
    if (value === 'Custom') {
      setIsCustomResolution(true);
      setCalcData(prevData => ({
        ...prevData,
        resolution: ''
      }));
    } else {
      setIsCustomResolution(false);
      setCalcData(prevData => ({
        ...prevData,
        resolution: value
      }));
      recalculateBasedOnPinState(value);
    }
  };

  const handleFramerateChange = (e) => {
    const { value } = e.target;
    if (value === 'Custom') {
      setIsCustomFramerate(true);
      setCalcData(prevData => ({
        ...prevData,
        framerate: ''
      }));
    } else {
      setIsCustomFramerate(false);
      setCalcData(prevData => ({
        ...prevData,
        framerate: value
      }));
      recalculateBasedOnPinState();
    }
  };

  const handleCustomFramerateChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    if (value.split('.').length <= 2) {
      setCalcData(prevData => ({
        ...prevData,
        framerate: value
      }));
    }
  };

  const handleCustomFramerateBlur = () => {
    const parsedFramerate = parseFloat(calcData.framerate);
    if (!isNaN(parsedFramerate) && parsedFramerate > 0) {
      const newFramerate = parsedFramerate.toString();
      if (!defaultFramerates.includes(newFramerate) && !customFramerates.includes(newFramerate)) {
        setCustomFramerates(prev => [...prev, newFramerate].sort((a, b) => parseFloat(a) - parseFloat(b)));
      }
      setCalcData(prevData => ({
        ...prevData,
        framerate: newFramerate
      }));
      setIsCustomFramerate(false);
      recalculateBasedOnPinState();
    } else if (calcData.framerate.trim() === '') {
      setCalcData(prevData => ({
        ...prevData,
        framerate: '23.976' // or any default value
      }));
      setIsCustomFramerate(false);
    }
  };

  const formatCustomResolution = (value) => {
    // Remove any non-digit characters and split into parts
    const parts = value.split(/[^\d]+/).filter(part => part !== '');
    
    if (parts.length >= 2) {
      // If we have at least two number parts, join the first two with ' x '
      return `${parts[0]} x ${parts[1]}`;
    } else if (parts.length === 1) {
      // If we only have one number part, add the ' x ' at the end
      return `${parts[0]} x `;
    } else {
      // If we don't have any number parts, return an empty string
      return '';
    }
  };

  const handleCustomResolutionChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9\s,:';+=*x./\-]/g, '');
    setCalcData(prevData => ({
      ...prevData,
      resolution: value
    }));
  };

  const handleCustomResolutionBlur = () => {
    const formattedValue = formatCustomResolution(calcData.resolution);
    const [width, height] = formattedValue.split(' x ').map(Number);
    if (!isNaN(width) && !isNaN(height) && width > 0 && height > 0) {
      const newCustomResolution = `Custom (${width} x ${height})`;
      
      // Check if a resolution with the same dimensions already exists
      const existingResolution = [...defaultResolutions, ...customResolutions].find(res => {
        const match = res.match(/\((\d+)\s*x\s*(\d+)\)/);
        if (match) {
          const [, existingWidth, existingHeight] = match;
          return parseInt(existingWidth) === width && parseInt(existingHeight) === height;
        }
        return false;
      });

      if (existingResolution) {
        // Use the existing resolution instead of creating a new custom one
        setCalcData(prevData => ({
          ...prevData,
          resolution: existingResolution
        }));
      } else {
        // Add the new custom resolution
        setCustomResolutions(prev => [...prev, newCustomResolution].sort((a, b) => {
          const [aWidth, aHeight] = a.match(/\d+/g).map(Number);
          const [bWidth, bHeight] = b.match(/\d+/g).map(Number);
          return bWidth * bHeight - aWidth * aHeight;
        }));
        setCalcData(prevData => ({
          ...prevData,
          resolution: newCustomResolution
        }));
      }

      setIsCustomResolution(false);
      recalculateBasedOnPinState(existingResolution || newCustomResolution);
    } else if (calcData.resolution.trim() === '') {
      setCalcData(prevData => ({
        ...prevData,
        resolution: previousResolution
      }));
      setIsCustomResolution(false);
    }
  };



  // 1. Update handleCopyDuration to remove navigator.clipboard.writeText
  const handleCopyDuration = (text, result) => {
    if (result) {
      setCopyDurationText(<SlLike />);
    } else {
      setCopyDurationText('Error');
    }
    setTimeout(() => setCopyDurationText(<SlDocs />), 500);
  };


  const handleTouchStart = useCallback((e, field) => {
    if ((field === 'fileSize' && isFileSizeLocked) || (field === 'duration' && isDurationLocked)) return;
    
    e.preventDefault();
    const touch = e.touches[0];
    const startY = touch.clientY;
    const rect = e.target.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    const fieldWidth = rect.width;
    let lastY = startY;
    let startValue;

    if (field === 'fileSize') {
      const parsedSize = parseFileSize(fileSizeInput);
      startValue = parsedSize ? parseFloat(parsedSize.size) : 0;
    } else if (field === 'duration') {
      const timecode = Timecode.parse(durationInput, parseFloat(calcData.framerate));
      startValue = timecode.totalFrames();
    }

    const handleTouchMove = (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const diffY = lastY - currentY;
      const isLeftHalf = touchX < fieldWidth / 2;
      const baseChange = isLeftHalf ? 20 : 2;
      const changeAmount = Math.abs(diffY) > 5 ? baseChange * Math.abs(diffY / 5) : baseChange;
      const delta = Math.sign(diffY);

      if (field === 'fileSize') {
        let change = diffY * sensitivity;
        if (isLeftHalf) change *= 10;
        let newSize = Math.max(0, startValue - change);
        newSize = Math.round(newSize * 100) / 100;

        const newFileSizeInput = `${newSize} ${fileSizeUnit}`;
        setFileSizeInput(newFileSizeInput);
        setCalcData(prevData => ({
          ...prevData,
          fileSize: newSize.toString()
        }));

        // Recalculate duration
        const duration = calculateDuration({ ...calcData, fileSize: newSize.toString() }, newFileSizeInput);
        setDurationInput(duration);
        setCalcData(prevData => ({
          ...prevData,
          duration: duration
        }));
      } else if (field === 'duration') {
        const segment = Math.floor(touchX / (fieldWidth / 4)); // Split into 4 segments
        const framerate = parseFloat(calcData.framerate);
        
        // Calculate change based on segment
        let change = Math.round(diffY * sensitivity);
        let { hours, minutes, seconds, frames } = startValue;
        
        switch(segment) {
          case 0: // Hours
            hours = Math.max(0, hours - change);
            break;
          case 1: // Minutes
            minutes -= change;
            while (minutes < 0) {
              if (hours > 0) {
                hours--;
                minutes += 60;
              } else {
                minutes = 0;
              }
            }
            while (minutes >= 60) {
              hours++;
              minutes -= 60;
            }
            break;
          case 2: // Seconds
            seconds -= change;
            while (seconds < 0) {
              if (minutes > 0) {
                minutes--;
                seconds += 60;
              } else if (hours > 0) {
                hours--;
                minutes = 59;
                seconds += 60;
              } else {
                seconds = 0;
              }
            }
            while (seconds >= 60) {
              minutes++;
              seconds -= 60;
              if (minutes >= 60) {
                hours++;
                minutes -= 60;
              }
            }
            break;
          case 3: // Frames
            frames -= change;
            while (frames < 0) {
              if (seconds > 0) {
                seconds--;
                frames += Math.floor(framerate);
              } else if (minutes > 0) {
                minutes--;
                seconds = 59;
                frames += Math.floor(framerate);
              } else if (hours > 0) {
                hours--;
                minutes = 59;
                seconds = 59;
                frames += Math.floor(framerate);
              } else {
                frames = 0;
              }
            }
            while (frames >= framerate) {
              seconds++;
              frames -= Math.floor(framerate);
              if (seconds >= 60) {
                minutes++;
                seconds -= 60;
                if (minutes >= 60) {
                  hours++;
                  minutes -= 60;
                }
              }
            }
            break;
        }

        const newDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;

        setDurationInput(newDuration);
        setCalcData(prevData => ({
          ...prevData,
          duration: newDuration
        }));

        // Recalculate file size
        const { size, unit } = calculateFileSize({ ...calcData, duration: newDuration });
        setFileSizeInput(`${size} ${unit}`);
        setCalcData(prevData => ({
          ...prevData,
          fileSize: size
        }));
        setFileSizeUnit(unit);
      }
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  }, [calcData, fileSizeInput, durationInput, isFileSizeLocked, isDurationLocked, calculateFileSize, fileSizeUnit]);

  // Updated parseFileSize function to handle more input permutations
  const parseFileSize = (input) => {
    // Remove any whitespace and convert to lowercase
    const cleanInput = input.replace(/\s+/g, '').toLowerCase();
  
    // Define unit patterns
    const unitPatterns = {
      'pb': ['p', 'ps', 'pb', 'pbs', 'pe', 'pet', 'pets', 'peta', 'petas', 'petabyte', 'petabytes'],
      'tb': ['t', 'ts', 'tb', 'tbs', 'te', 'ter', 'ters', 'tera', 'teras', 'terabyte', 'terabytes'],
      'gb': ['g', 'gs', 'gb', 'gbs', 'gi', 'gig', 'gigs', 'giga', 'gigas', 'gigabyte', 'gigabytes'],
      'mb': ['m', 'ms', 'mb', 'mbs', 'me', 'meg', 'megs', 'mega', 'megas', 'megabyte', 'megabytes'],
      'kb': ['k', 'ks', 'kb', 'kbs', 'ki', 'kil', 'kils', 'kilo', 'kilos', 'kilobyte', 'kilobytes'],
      'b': ['b', 'bs', 'bi', 'by', 'byte', 'bytes']
    };
  
    // Create a regex pattern that matches any of the unit patterns
    const unitRegexPattern = Object.values(unitPatterns).flat().join('|');
    
    // Match the input against the pattern
    const match = cleanInput.match(new RegExp(`^(\\d+(?:\\.\\d+)?)(${unitRegexPattern})$`));
  
    if (match) {
      const size = parseFloat(match[1]);
      const unitLower = match[2];
  
      // Determine the standardized unit
      for (const [standardUnit, patterns] of Object.entries(unitPatterns)) {
        if (patterns.includes(unitLower)) {
          return { size, unit: standardUnit.toUpperCase() };
        }
      }
    }
  
    return null;
  };

  // Update handleFileSizeFocus to clear the input and show placeholder text
  const handleFileSizeFocus = () => {
    if (!isFileSizeLocked) {
      setFileSizeInput('');
    }
  };

  // Updated handleFileSizeBlur function to parse input and calculate duration
  const handleFileSizeBlur = () => {
    if (fileSizeInput.trim() === '') {
      setFileSizeInput('');
      if (!isFileSizeLocked) {
        setCalcData(prevData => ({ ...prevData, fileSize: '' }));
      }
    } else {
      const parsedSize = parseFileSize(fileSizeInput);
      if (parsedSize !== null) {
        const formattedSize = `${parsedSize.size} ${parsedSize.unit}`;
        setFileSizeInput(formattedSize);
        if (!isFileSizeLocked) {
          setCalcData(prevData => ({
            ...prevData,
            fileSize: parsedSize.size
          }));
          setFileSizeUnit(parsedSize.unit);
          
          // Calculate duration based on the parsed file size
          if (!isDurationLocked) {
            const duration = calculateDuration({ 
              ...calcData, 
              fileSize: parsedSize.size, 
              fileSizeUnit: parsedSize.unit 
            }, formattedSize);
            setDurationInput(duration);
            setCalcData(prevData => ({
              ...prevData,
              duration: duration
            }));
          }
        }
      }
    }
  };

  // Updated handleFileSizeKeyPress to trigger blur on Enter key
  const handleFileSizeKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      if (!isFileSizeLocked) {
        const parsedSize = parseFileSize(fileSizeInput);
        if (parsedSize !== null) {
          const formattedSize = `${parsedSize.size} ${parsedSize.unit}`;
          setFileSizeInput(formattedSize);
          setCalcData(prevData => ({
            ...prevData,
            fileSize: parsedSize.size
          }));
          setFileSizeUnit(parsedSize.unit);
          
          // Calculate duration based on the parsed file size
          if (!isDurationLocked) {
            const duration = calculateDuration({ 
              ...calcData, 
              fileSize: parsedSize.size, 
              fileSizeUnit: parsedSize.unit 
            }, formattedSize);
            setDurationInput(duration);
            setCalcData(prevData => ({
              ...prevData,
              duration: duration
            }));
          }
        }
        e.target.blur(); // Remove focus from the input
      }
    }
  };

  // Helper function to recalculate based on pin state
  const recalculateBasedOnPinState = (updatedResolution = calcData.resolution) => {
    const updatedCalcData = { ...calcData, resolution: updatedResolution };
    
    if (calculationMode === 'duration' && isDurationLocked) {
      // If duration is pinned, recalculate file size based on duration
      const { size, unit } = calculateFileSize(updatedCalcData);
      setFileSizeInput(`${size} ${unit}`);
      setCalcData(prevData => ({
        ...prevData,
        fileSize: size
      }));
      setFileSizeUnit(unit);
    } else if (calculationMode === 'fileSize' && isFileSizeLocked) {
      // If file size is pinned, recalculate duration based on file size
      const duration = calculateDuration(updatedCalcData, fileSizeInput);
      setDurationInput(duration);
      setCalcData(prevData => ({
        ...prevData,
        duration: duration
      }));
    } else {
      // If nothing is pinned, default to recalculating file size based on duration
      const { size, unit } = calculateFileSize(updatedCalcData);
      setFileSizeInput(`${size} ${unit}`);
      setCalcData(prevData => ({
        ...prevData,
        fileSize: size
      }));
      setFileSizeUnit(unit);
    }
  };

  // Updated handleFileSizeWheel to implement proportional scrolling across all units
  const handleFileSizeWheel = (e) => {
    e.preventDefault();
    if (isFileSizeLocked || isDurationLocked) return;

    const delta = Math.sign(-e.deltaY); // Positive for scroll up, negative for scroll down
    const changeFactor = 1.1; // 10% change per scroll

    let parsed = parseFileSize(fileSizeInput);
    if (!parsed) return;
    let { size, unit } = parsed;
    let newSize = size;

    // Apply proportional change based on scroll direction
    if (delta > 0) {
      newSize *= changeFactor;
    } else {
      newSize /= changeFactor;
    }

    // Define the order of units
    const unitsOrder = ['KB', 'MB', 'GB', 'TB', 'PB'];
    let currentIndex = unitsOrder.indexOf(unit);

    // Adjust unit if size exceeds current unit's boundaries
    while (newSize >= 1000 && currentIndex < unitsOrder.length - 1) {
      newSize /= 1000;
      currentIndex++;
      unit = unitsOrder[currentIndex];
    }

    while (newSize < 1 && currentIndex > 0) {
      newSize *= 1000;
      currentIndex--;
      unit = unitsOrder[currentIndex];
    }

    // Format the new size based on unit for consistency
    newSize = unit === 'KB' ? Math.round(newSize) : parseFloat(newSize.toFixed(2));

    const newFileSizeInput = `${newSize} ${unit}`;
    setFileSizeInput(newFileSizeInput);

    // Update calcData immediately
    const updatedCalcData = {
      ...calcData,
      fileSize: newSize.toString(),
      fileSizeUnit: unit
    };
    setCalcData(updatedCalcData);

    // Recalculate duration based on the new file size
    const duration = calculateDuration(updatedCalcData, newFileSizeInput);
    setDurationInput(duration);
    setCalcData(prevData => ({
      ...prevData,
      duration: duration
    }));
  };

  // Ensure handleFileSizeWheel is correctly referenced in useEffect
  useEffect(() => {
    const fileSizeElement = fileSizeRef.current;

    const wheelHandler = (e) => {
      if (!isFileSizeLocked) {
        handleFileSizeWheel(e);
      }
    };

    if (fileSizeElement) {
      fileSizeElement.addEventListener('wheel', wheelHandler, { passive: false });
      fileSizeElement.addEventListener('touchmove', wheelHandler, { passive: false });
    }

    return () => {
      if (fileSizeElement) {
        fileSizeElement.removeEventListener('wheel', wheelHandler);
        fileSizeElement.removeEventListener('touchmove', wheelHandler);
      }
    };
  }, [isFileSizeLocked, handleFileSizeWheel]);

  
  const handleCustomResolutionKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCustomResolutionBlur();
    }
  };
  
  const handleCustomFramerateKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCustomFramerateBlur();
    }
  };

  // Update theme colors based on isDarkMode
  const bgColor = 'bg-white';
  const textColor = 'text-gray-900';
  const inputBgColor = 'bg-gray-50';
  const inputBorderColor = 'border-gray-300';
  const labelColor = 'text-gray-700';
  const buttonColor = 'bg-blue-500 hover:bg-blue-600';
  

  // Update commonInputClass to include direct mobile centering styles
  const commonInputClass = `w-full px-2 py-1 border rounded h-[36px] ${inputBgColor} ${inputBorderColor} ${textColor} text-center 
    [&>option]:text-center 
    [&>option]:!text-center
    [-webkit-appearance:none] 
    [appearance:none] 
    !text-center
    !text-align-last-center`;
  const textCenterImportant = {
    textAlign: 'center !important',
    textAlignLast: 'center !important',
    '-moz-text-align-last': 'center !important',
    '-webkit-text-align-last': 'center !important'
  };
  

  const pinButtonStyle = `p-2 rounded h-[36px] w-[42px] flex items-center justify-center self-end bg-gray-200 hover:bg-gray-300 border-2 ${
    calculationMode ? 'border-blue-500' : 'border-transparent'
  }`;

   // Function to get input style based on lock state
   const getInputStyle = (field) => {
     const baseStyle = "w-full px-2 py-1 border rounded h-[36px] text-center";
     
     if (isDurationLocked || isFileSizeLocked) {
       // Locked state styles
       const lockedStyle = "bg-gray-200 text-gray-700 cursor-not-allowed pointer-events-none";
       
       // Add blue border if this field is the one that's controlling
       if ((field === 'duration' && calculationMode === 'duration') ||
           (field === 'fileSize' && calculationMode === 'fileSize')) {
         return `${baseStyle} ${lockedStyle} border-2 border-blue-500`;
       }
       
       return `${baseStyle} ${lockedStyle} border border-gray-400`;
     }
     
     // Default unlocked state
     return `${baseStyle} bg-gray-50 border border-gray-300 text-gray-900`;
   };

  // Add this new handler near the other touch handlers
  const handleDropdownTouch = useCallback((e, options, currentValue, fieldName) => {
    if (!e.touches || !e.touches[0]) return;
    
    const touch = e.touches[0];
    const startY = touch.clientY;
    let lastY = startY;
    let isSwiping = false;
    let touchTimeout;
    
    // Filter out 'Custom' option for scrolling
    const scrollableOptions = options.filter(option => option !== 'Custom');
    let currentIndex = scrollableOptions.indexOf(currentValue);
    let accumulatedDelta = 0;
    
    touchTimeout = setTimeout(() => {
      if (!isSwiping) {
        e.target.click();
      }
    }, 100);
    
    const handleTouchMove = (e) => {
      if (!e.touches || !e.touches[0]) return;
      
      clearTimeout(touchTimeout);
      
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const totalMove = Math.abs(currentY - startY);
      
      if (totalMove > 5) {
        e.preventDefault();
        isSwiping = true;
        
        const diffY = lastY - currentY;
        lastY = currentY;
        
        accumulatedDelta += diffY * 0.6;
        
        const itemsToMove = Math.floor(Math.abs(accumulatedDelta) / 10);
        
        if (itemsToMove >= 1) {
          const direction = Math.sign(accumulatedDelta);
          let newIndex = currentIndex + (direction * itemsToMove);
          
          // Ensure newIndex stays within bounds of scrollable options
          newIndex = Math.max(0, Math.min(newIndex, scrollableOptions.length - 1));
          
          if (newIndex !== currentIndex) {
            const newValue = scrollableOptions[newIndex];
            setCalcData(prev => ({
              ...prev,
              [fieldName]: newValue
            }));
            currentIndex = newIndex;
            recalculateBasedOnPinState();
          }
          
          accumulatedDelta = accumulatedDelta % 10;
        }
      }
    };

    const handleTouchEnd = () => {
      clearTimeout(touchTimeout);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      
      if (!isSwiping) {
        e.target.click();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  }, [recalculateBasedOnPinState]);

  const handleResolutionTouch = useDropdownTouch(
    resolutionOptions,
    calcData.resolution,
    handleResolutionChange,
    'resolution'
  );

  const handleCodecTouch = useDropdownTouch(
    codecs.map(codec => codec.name),
    calcData.codec,
    handleInputChange,
    'codec'
  );

  const handleFramerateTouch = useDropdownTouch(
    defaultFramerates,
    calcData.framerate,
    handleFramerateChange,
    'framerate'
  );

  return (
    <div className={`duration-to-file-size p-2 ${isMobile ? '' : ''} ${bgColor} ${textColor}`}>
      <div className="flex flex-col space-y-2">
        <div className="grid grid-cols-[1fr,auto,1fr] gap-2 items-end">
          <div>
            <label className={`block ${labelColor}`}>
              {isMobile ? "Duration" : "Duration (HH:MM:SS:FF)"}
            </label>
            <input
              ref={durationRef}
              type="text"
              name="duration"
              value={durationInput}
              onChange={handleInputChange}
              onFocus={handleDurationFocus}
              onBlur={handleDurationBlur}
              onKeyPress={handleDurationKeyPress}
              onWheel={handleDurationWheel}
              onTouchStart={(e) => handleTouchStart(e, 'duration')}
              placeholder={isDurationLocked ? '' : "ex: 01:00:00:00 or 16 hrs"}
              className={getInputStyle('duration')}
              readOnly={isDurationLocked}
              aria-readonly={isDurationLocked}
            />
          </div>

          <div className="flex flex-col justify-end">
            <label className="block invisible">Pin</label>
            <button
              type="button"
              onClick={() => {
                if (calculationMode === null) {
                  // First click: Lock duration
                  setIsDurationLocked(true);
                  setIsFileSizeLocked(false);
                  setCalculationMode('duration');
                } else if (calculationMode === 'duration') {
                  // Second click: Lock file size
                  setIsDurationLocked(false);
                  setIsFileSizeLocked(true);
                  setCalculationMode('fileSize');
                } else {
                  // Third click: Unlock everything
                  setIsDurationLocked(false);
                  setIsFileSizeLocked(false);
                  setCalculationMode(null);
                }
              }}
              className={`${pinButtonStyle} ${calculationMode ? 'bg-gray-300' : ''} relative`}
            >
              {calculationMode ? (
                <FaLock 
                  className={`text-blue-500 scale-[0.85] transition-transform`}
                />
              ) : (
                <FaUnlockAlt 
                  className={`text-gray-500 scale-100 transition-transform`}
                />
              )}
              {calculationMode === 'duration' && (
                <FaAngleLeft className="absolute -left-1 top-1/2 -translate-y-1/2 text-blue-500" />
              )}
              {calculationMode === 'fileSize' && (
                <FaAngleRight className="absolute -right-1 top-1/2 -translate-y-1/2 text-blue-500" />
              )}
            </button>
          </div>

          <div>
            <label className={`block ${labelColor}`}>Estimated File Size</label>
            <input
              ref={fileSizeRef}
              type="text"
              name="fileSize"
              value={fileSizeInput}
              onChange={handleInputChange}
              onFocus={handleFileSizeFocus}
              onBlur={handleFileSizeBlur}
              onKeyPress={handleFileSizeKeyPress}
              onWheel={handleFileSizeWheel}
              onTouchStart={(e) => handleTouchStart(e, 'fileSize')}
              placeholder={isFileSizeLocked ? '' : "ex: 512 GB"}
              className={getInputStyle('fileSize')}
              readOnly={isFileSizeLocked}
              aria-readonly={isFileSizeLocked}
            />
          </div>
        </div>
      </div>
      <div className="mb-2"></div>
      <div className="flex space-x-2">
        <div className="flex-grow">
          <label className={`block ${labelColor}`}>Resolution</label>
          {isCustomResolution ? (
            <input
              type="text"
              value={calcData.resolution}
              onChange={handleCustomResolutionChange}
              onBlur={handleCustomResolutionBlur}
              onKeyPress={handleCustomResolutionKeyPress}
              placeholder="ex: 1920 x 1080"
              className={commonInputClass}
            />
          ) : (
            <select
              ref={resolutionRef}
              value={calcData.resolution}
              onChange={handleResolutionChange}
              onWheel={handleResolutionWheel}
              onTouchStart={handleResolutionTouch}
              className={commonInputClass}
            >
              {[...defaultResolutions, ...customResolutions, 'Custom'].map(res => (
                <option key={res} value={res}>{res}</option>
              ))}
            </select>
          )}
        </div>
      </div>
      <div className="mb-2"></div>
      <div className="flex space-x-2">
        <div className="w-2/3">
          <label className={`block ${labelColor}`}>Codec</label>
          <select
            ref={codecRef}
            name="codec"
            value={calcData.codec}
            onChange={handleInputChange}
            onWheel={handleCodecWheel}
            onTouchStart={handleCodecTouch}
            className={commonInputClass}
          >
            {codecs.map(codec => (
              <option key={codec.name} value={codec.name}>{codec.name}</option>
            ))}
          </select>
        </div>
        <div className="w-1/3">
          <label className={`block ${labelColor}`}>Framerate</label>
          {isCustomFramerate ? (
            <input
              type="text"
              // inputMode="numeric"
              // pattern="[0-9]*"
              // enterKeyHint="done"
              value={calcData.framerate}
              onChange={handleCustomFramerateChange}
              onBlur={handleCustomFramerateBlur}
              onKeyPress={handleCustomFramerateKeyPress}
              placeholder="ex: 48"
              className={commonInputClass}
            />
          ) : (
            <select
              ref={framerateRef}
              value={calcData.framerate}
              onChange={handleFramerateChange}
              onWheel={handleFramerateWheel}
              onTouchStart={handleFramerateTouch}
              className={commonInputClass}
            >
              {[...defaultFramerates, ...customFramerates, 'Custom'].map(fps => (
                <option key={fps} value={fps}>{fps}</option>
              ))}
            </select>
          )}
        </div>
      </div>
      <div className="mb-2"></div>
      <div className="mt-4 flex justify-between w-full">
        <button
          onClick={handleLogClick}
          className={`px-4 py-4 rounded flex-grow mr-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {logButtonText}
        </button>
        <CopyToClipboard text={`${resolutionString} ${calcData.codec} @ ${calcData.framerate}fps \n${calcData.duration} = ${calcData.fileSize} ${fileSizeUnit}`} onCopy={handleCopyDuration}>
          <button 
            className={`px-4 py-4 rounded flex-grow mx-2 ${buttonColor} text-white flex items-center justify-center`}
          >
            {copyDurationText}
          </button>
        </CopyToClipboard>
        <button
          onClick={handleShare}
          className={`px-4 py-4 rounded flex-grow mx-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {shareButtonText}
        </button>
        <button
          onClick={handleReset}
          className={`px-4 py-4 rounded flex-grow ml-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {resetButtonText}
        </button>
      </div>
    </div>
  );
};

export default DurationToFileSize;