import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';

const getBasename = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'beta') {
    console.log('Beta environment detected');
    if (!window.location.pathname.startsWith('/beta') && window.location.pathname !== '/') {
      window.location.pathname = `/beta${window.location.pathname}`;
    }
    return '/beta';
  }
  console.log('Production environment detected');
  return '';
};

const basename = getBasename();
if (process.env.REACT_APP_ENVIRONMENT === 'beta' && 
    window.location.pathname === '/' && 
    !window.location.pathname.startsWith('/beta')) {
  window.location.pathname = '/beta/';
} else {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <BrowserRouter basename={basename}>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}
