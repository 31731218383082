export const codecs = [
  { name: "Apple ProRes Proxy", datarate: 0.0000007299729335 },
  { name: "Apple ProRes LT", datarate: 0.000001648325979 },
  { name: "Apple ProRes 422", datarate: 0.000002368879907 },
  { name: "Apple ProRes 422 HQ", datarate: 0.000003550965109 },
  { name: "Apple ProRes 4444", datarate: 0.000005326447663 },
  { name: "Apple ProRes 4444 XQ", datarate: 0.000007992026247 },
  { name: "Apple ProRes RAW", datarate: 0.000003375771605 },
  { name: "Apple ProRes RAW HQ", datarate: 0.00000479841821 },
  { name: "ARRIRAW  HDE", datarate: 0.000006382412381 }, // ARRIRAW HDE - ALEXA 35 @ 50%
  { name: "ARRIRAW HDE", datarate: 0.000007658894857 },
  { name: "ARRIRAW", datarate: 0.00001276482476 },
  { name: "AVCHD", datarate: 0.0000005631917926 },
  { name: "AVC Intra 422", datarate: 0.000001671873493 },
  { name: "AVC Intra 444", datarate: 0.000003348456489 },
  { name: "Avid DNxHD 36", datarate: 0.0000007233796296 },
  { name: "Avid DNxHD 80", datarate: 0.000001607510288 },
  { name: "Avid DNxHD 115", datarate: 0.000002330889918 },
  { name: "Avid DNxHD 175", datarate: 0.000003536522634 },
  { name: "Avid DNxHD 175x", datarate: 0.000003536522634 },
  { name: "Avid DNxHD 350x", datarate: 0.000007073045267 },
  { name: "Avid DNxHR LB", datarate: 0.00000008618845826 }, 
  { name: "Avid DNxHR SQ", datarate: 0.000000276919393 }, 
  { name: "Avid DNxHR HQ", datarate: 0.0000004186727558 }, 
  { name: "Avid DNxHR HQX", datarate: 0.0000004186727558 }, 
  { name: "Avid DNxHR 444", datarate: 0.0000008377980765 }, 
  { name: "Blackmagic RAW 18:1", datarate: 0.00000006844477398 },
  { name: "Blackmagic RAW 12:1", datarate: 0.0000001271565755 },
  { name: "Blackmagic RAW 8:1", datarate: 0.0000001899499461 },
  { name: "Blackmagic RAW 6:1", datarate: 0.0000002581088697 },
  { name: "Blackmagic RAW 5:1", datarate: 0.0000003045478475 },
  { name: "Blackmagic RAW 4:1", datarate: 0.0000003833675859 },
  { name: "Blackmagic RAW 3:1", datarate: 0.0000005070564678 },
  { name: "Blackmagic RAW Q5", datarate: 0.0000002291958028 },
  { name: "Blackmagic RAW Q3", datarate: 0.0000003908887322 },
  { name: "Blackmagic RAW Q1", datarate: 0.0000005463023245 },
  { name: "Blackmagic RAW Q0", datarate: 0.0000006828779056 },
  { name: "Canon Cinema RAW Light HQ", datarate: 0.00000379005148 },
  { name: "Canon Cinema RAW Light ST", datarate: 0.000001863813552 },
  { name: "Canon Cinema RAW Light LT", datarate: 0.000001212816474 },
  { name: "Canon Cinema RAW Light", datarate: 0.000001546263181 },
  { name: "Canon XF-AVC", datarate: 0.000003814697266 },
  { name: "Cineform Low", datarate: 0.00000128600823 },
  { name: "Cineform Medium", datarate: 0.000001547228652 },
  { name: "Cineform High", datarate: 0.000001788355195 },
  { name: "Cineform Film Scan", datarate: 0.000002089763374 },
  { name: "Cineform Film Scan 2", datarate: 0.000003415959362 },
  { name: "CinemaDNG", datarate: 0.00003433641975 },
  { name: "Cineon", datarate: 0.000003815639166 },
  { name: "DCP 2K", datarate: 0.000004827358222 },
  { name: "DCP 4K", datarate: 0.000001206839556 },
  { name: "DPX RGB 10-bit", datarate: 0.000003519948704 },
  { name: "DPX RGB 16-bit", datarate: 0.000005531347963 },
  { name: "EXR RGB 16-bit PIZ", datarate: 0.00003390842014 },
  { name: "EXR RGB 16-bit", datarate: 0.00006103515625 },
  { name: "EXR RGB 32-bit", datarate: 0.0001220703125 },
  { name: "EXR RGBA 16-bit PIZ", datarate: 0.0000254313151 },
  { name: "EXR RGBA 16-bit", datarate: 0.00004577636719 },
  { name: "EXR RGBA 32-bit", datarate: 0.00009155273438 },
  { name: "H.264 5 Mbps", datarate: 0.000000025117348 },
  { name: "H.264 10 Mbps", datarate: 0.000000050234696 },
  { name: "H.264 18 Mbps", datarate: 0.0000000904224537 },
  { name: "H.264 25 Mbps", datarate: 0.0000001255867413 },
  { name: "H.264 50 Mbps", datarate: 0.0000002511734825 },
  { name: "H.264 80 Mbps", datarate: 0.000000401877572 },
  { name: "HEVC/H.265 5 Mbps", datarate: 0.000000025117348 },
  { name: "HEVC/H.265 10 Mbps", datarate: 0.000000050234696 },
  { name: "HEVC/H.265 25 Mbps", datarate: 0.0000001255867413 },
  { name: "HEVC/H.265 50 Mbps", datarate: 0.0000002511734825 },
  { name: "HEVC/H.265 80 Mbps", datarate: 0.000000401877572 },
  { name: "HEVC/H.265 120 Mbps", datarate: 0.000000602816358 },
  { name: "HEVC/H.265 130 Mbps", datarate: 0.0000006530510545 },
  { name: "HEVC/H.265 150 Mbps", datarate: 0.0000007535204475 },
  { name: "HEVC/H.265 160 Mbps", datarate: 0.000000803755144 },
  { name: "IMF HD", datarate: 0.00000803755144 },
  { name: "IMF 4K", datarate: 0.00000401877572 },
  { name: "iPhone H.264 Low", datarate: 0.00000007233796296 },
  { name: "iPhone H.264 Medium", datarate: 0.0000001085069444 },
  { name: "iPhone H.264 High", datarate: 0.0000001446759259 },
  { name: "iPhone H.264 Max", datarate: 0.0000002170138889 },
  { name: "iPhone HEVC (H.265) Low", datarate: 0.00000007233796296 },
  { name: "iPhone HEVC (H.265) Medium", datarate: 0.0000001085069444 },
  { name: "iPhone HEVC (H.265) High", datarate: 0.0000001446759259 },
  { name: "iPhone HEVC (H.265) Max", datarate: 0.0000002170138889 },
  { name: "MPEG 50Mbps", datarate: 0.000004822530864 },
  { name: "MPEG-2 HD 422", datarate: 0.000001327523511 },
  { name: "MP4 (MPEG-4 AVC) 40 Mbps", datarate: 0.000000200938786 },
  { name: "MP4 (MPEG-4 AVC) 100 Mbps", datarate: 0.000000502346965 },
  { name: "MP4 (MPEG-4 AVC) 130 Mbps", datarate: 0.0000006530510545 },
  { name: "MP4 (MPEG-4 AVC) 150 Mbps", datarate: 0.0000007535204475 },
  { name: "MP4 (MPEG-4 AVC) 160 Mbps", datarate: 0.000000803755144 },
  { name: "MP4 (MPEG-4 AVC) 200 Mbps", datarate: 0.00000100469393 },
  { name: "MP4 (H.264) 130 Mbps", datarate: 0.0000006530510545 },
  { name: "MP4 (H.265) 130 Mbps", datarate: 0.0000006530510545 },
  { name: "Nikon N-RAW", datarate: 0.00000151057281 },
  { name: "Nikon N-RAW HQ", datarate: 0.000002510376396 },
  { name: "Panasonic V-RAW", datarate: 0.00001199599525 },
  { name: "Phantom Cine RAW", datarate: 0.000001446759259 },
  { name: "REDCODE ELQ", datarate: 0.00000091990455 },
  { name: "REDCODE LQ", datarate: 0.000001724821031 },
  { name: "REDCODE MQ", datarate: 0.000002759713649 },
  { name: "REDCODE HQ", datarate: 0.000004599522748 },
  { name: "REDCODE 22:1", datarate: 0.00000006970705273 },
  { name: "REDCODE 21:1", datarate: 0.00000007302183871 },
  { name: "REDCODE 20:1", datarate: 0.00000007665844857 },
  { name: "REDCODE 19:1", datarate: 0.00000008071342948 },
  { name: "REDCODE 18:1", datarate: 0.00000008518678143 },
  { name: "REDCODE 17:1", datarate: 0.00000009020723398 },
  { name: "REDCODE 16:1", datarate: 0.00000009583915191 },
  { name: "REDCODE 15:1", datarate: 0.0000001022112648 },
  { name: "REDCODE 14:1", datarate: 0.0000001095166669 },
  { name: "REDCODE 13:1", datarate: 0.0000001179484526 },
  { name: "REDCODE 12:1", datarate: 0.0000001277962633 },
  { name: "REDCODE 11:1", datarate: 0.0000001393819231 },
  { name: "REDCODE 10:1", datarate: 0.0000001533490795 },
  { name: "REDCODE 9:1", datarate: 0.0000001703735629 },
  { name: "REDCODE 8:1", datarate: 0.0000001916783038 },
  { name: "REDCODE 7:1", datarate: 0.0000002190655161 },
  { name: "REDCODE 6:1", datarate: 0.0000002555603443 },
  { name: "REDCODE 5:1", datarate: 0.0000003066659767 },
  { name: "REDCODE 4:1", datarate: 0.0000003833566076 },
  { name: "REDCODE 3:1", datarate: 0.0000005111206886 },
  { name: "REDCODE 2:1", datarate: 0.0000007666810329 },
  { name: "Sony RAW", datarate: 0.00001808449074 },
  { name: "Sony RAW SQ", datarate: 0.000004441061138 },
  { name: "Sony X-OCN LT", datarate: 0.000001831996588 },
  { name: "Sony X-OCN ST", datarate: 0.000003108271846 },
  { name: "Sony X-OCN XT", datarate: 0.000004544670199 },
  { name: "Sony XAVC 4K Intra Class 300", datarate: 0.000001412850839 },
  { name: "Sony XAVC 4K Intra Class 480", datarate: 0.000002260561343 },
  { name: "Sony XAVC C300", datarate: 0.000001131223357 },
  { name: "Sony XAVC C480", datarate: 0.000001809957372 },
  { name: "Sony XAVC H-I HQ", datarate: 0.000001243308738 },
  { name: "Sony XAVC H-I SQ", datarate: 0.0000008288724923 },
  { name: "Sony XAVC H-L", datarate: 0.0000006530510545 },
  { name: "Sony XAVC HD Profile Class 100", datarate: 0.00000200938786 },
  { name: "Sony XAVC HD Profile Class 200", datarate: 0.00000401877572 },
  { name: "Sony XAVC HE-4K", datarate: 0.000000803755144 },
  { name: "Sony XAVC HE-8K", datarate: 0.000000502346965 },
  { name: "Sony XAVC HE-8K Extension", datarate: 0.0000006530510545 },
  { name: "Sony XAVC HS 4K", datarate: 0.000000502346965},
  { name: "Sony XAVC HS HEVC", datarate: 0.000000502346965 },
  { name: "Sony XAVC-I", datarate: 0.000001205632716 },
  { name: "Sony XAVC-L", datarate: 0.000000502346965 },
  { name: "Sony XAVC S", datarate: 0.000000502346965 },
  { name: "Sony XAVC S 4K", datarate: 0.000000502346965 },
  { name: "Sony XAVC S-I", datarate: 0.000001205632716 },
  { name: "Sony XDCAM HD", datarate: 0.00000100469393 },
  { name: "TIFF RGB 10 bit", datarate: 0.000003576278687 },
  { name: "TIFF RGBA 10 bit", datarate: 0.000004768371582 },
  { name: "TIFF RGB 16 bit", datarate: 0.000005722045898 },
  { name: "TIFF RGBA 16 bit", datarate: 0.000007629394531 },
  { name: "Z CAM 4K H.264/H.265 Low", datarate: 0.0000002411265432 },
  { name: "Z CAM 4K H.264/H.265 Medium", datarate: 0.0000005224408436 },
  { name: "Z CAM 4K H.264/H.265 High", datarate: 0.000000803755144 },
  { name: "Z CAM 5-6K H.264/H.265 Low", datarate: 0.00000016373463786 },
  { name: "Z CAM 5-6K H.264/H.265 Medium", datarate: 0.00000026197542042 },
  { name: "Z CAM 5-6K H.264/H.265 High", datarate: 0.00000040933659445 },
  { name: "Z CAM 8K H.264/H.265 Low", datarate: 0.000000200938786 },
  { name: "Z CAM 8K H.264/H.265 Medium", datarate: 0.000000401877572 },
  { name: "Z CAM 8K H.264/H.265 High", datarate: 0.000000502346965 },
  { name: "ZRAW", datarate: 0.000003904224683 },
];

export const dailiesCodecs = [
  { name: "Apple ProRes Proxy", datarate: 0.0000007299729335 },
  { name: "Apple ProRes LT", datarate: 0.000001648325979 },
  { name: "Apple ProRes 422", datarate: 0.000002368879907 },
  { name: "Apple ProRes 422 HQ", datarate: 0.000003550965109 },
  { name: "Avid DNxHD 36", datarate: 0.0000007233796296 },
  { name: "Avid DNxHD 80", datarate: 0.000001607510288 },
  { name: "Avid DNxHD 115", datarate: 0.000002330889918 },
  { name: "Avid DNxHR LB", datarate: 0.0000006934147798 },
  { name: "Avid DNxHR SQ", datarate: 0.000002215387823 },
  { name: "Avid DNxHR HQ", datarate: 0.000003344801223 },
  { name: "Avid DNxHR HQX", datarate: 0.000003344801223 },
  { name: "H.264 5 Mbps", datarate: 0.000000025117348 },
  { name: "H.264 10 Mbps", datarate: 0.000000050234696 },
  { name: "H.264 18 Mbps", datarate: 0.0000000904224537 },
  { name: "H.264 25 Mbps", datarate: 0.0000001255867413 },
  { name: "H.264 50 Mbps", datarate: 0.0000002511734825 },
  { name: "HEVC/H.265 5 Mbps", datarate: 0.000000025117348 },
  { name: "HEVC/H.265 10 Mbps", datarate: 0.000000050234696 },
  { name: "HEVC/H.265 25 Mbps", datarate: 0.0000001255867413 },
  { name: "HEVC/H.265 50 Mbps", datarate: 0.0000002511734825 }
];