// framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
// framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},


export const RedCameras = {
    "V-RAPTOR XL": {
        brand: "RED",
        resolutions: {
            "8K 17:9 (8192 x 4320)": {
                dimensions: [8192, 4320],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]}
                }
            }, 
            "8K 2.4:1 (8192 x 3456": {
                dimensions: [8192, 3456],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "150"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "150"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "150"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "150"]}
                }
            },
            "7K 17:9 (7168 x 3780)": {
                dimensions: [7168, 3780],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "140"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "140"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "140"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "140"]}
                }
            },
            "7K 2.4:1 (7168 x 3024": {
                dimensions: [7168, 3024],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "175"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "175"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "175"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "175"]}
                }
            },
            "6K 17:9 (6144 x 3240)": {
                dimensions: [6144, 3240],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"]}
                }
            },
            "6K 2.4:1 (6144 x 2592": {
                dimensions: [6144, 2592],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "200"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "200"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "200"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "200"]}
                }
            },
            "5K 17:9 (5120 x 2700)": {
                dimensions: [5120, 2700],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "192"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "192"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "192"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "192"]}
                }
            },       
            "5K 2.4:1 (5120 x 2160": {
                dimensions: [5120, 2160],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]}
                }
            },
            "4K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]}
                }
            },       
            "4K 2.4:1 (4096 x 1728": {
                dimensions: [4096, 1728],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "300"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "300"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "300"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "300"]}
                }
            },
            "3K 17:9 (3072 x 1620)": {
                dimensions: [3072, 1620],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "320"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "320"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "320"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "320"]}
                }
            },
            "3K 2.4:1 (3072 x 1296": {
                dimensions: [3072, 1296],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "400"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "400"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "400"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "400"]}
                }
            },
            "2K 17:9 (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "480"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "480"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "480"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "480"]}
                }
            },
            "2K 2.4:1 (2048 x 864": {
                dimensions: [2048, 864],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "600"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "600"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "600"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "600"]}
                }
            },            
        }
    },            

    "KOMODO-X 6K": {
        brand: "RED",
        resolutions: {
            "6K 17:9 (6144 x 3240)": {
                dimensions: [6144, 3240],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]}
                }
            }, 
            "5K 17:9 (5120 x 2700)": {
                dimensions: [5120, 2700],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "96"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "96"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "96"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "96"]}
                }
            },  
            "4K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]}
                }
            },  
            "2K 17:9 (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]}
                }
            },            
        }
    },
    "KOMODO 6K": {
        brand: "RED",
        resolutions: {
            "6K 17:9 (6144 x 3240)": {
                dimensions: [6144, 3240],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "40"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "40"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "40"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "40"]}
                }
            }, 
            "5K 17:9 (5120 x 2700)": {
                dimensions: [5120, 2700],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "48"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "48"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "48"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "48"]}
                }
            },  
            "4K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },  
            "2K 17:9 (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]}
                }
            },            
        }
    },
    // "RANGER MONSTRO 8K VV": {
    //     brand: "RED",
    //     resolutions: {
    //         "8K FF (8192 x 4320)": {
    //             dimensions: [8192, 4320],
    //             codecs: {
    //                 "REDCODE 5:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 6:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 7:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 8:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 9:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 10:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 11:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 12:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 13:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 14:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 15:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 16:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 17:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 18:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 19:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 20:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 21:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 22:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]}
    //             }
    //         }, 
    //         "7K FF (7168 x 3170)": {
    //             dimensions: [7168, 3170],
    //             codecs: {
    //                 "REDCODE 5:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 6:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 7:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 8:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 9:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 10:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 11:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 12:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 13:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 14:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 15:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 16:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 17:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 18:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 19:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 20:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 21:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 22:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]}
    //             }
    //         }, 
    //         "6K FF (6144 x 3240)": {
    //             dimensions: [6144, 3240],
    //             codecs: {
    //                 "REDCODE 5:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 6:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 7:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 8:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 9:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 10:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 11:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 12:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 13:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 14:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 15:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 16:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 17:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 18:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 19:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 20:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 21:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 22:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]}
    //             }
    //         }, 
    //         "5K FF (5120 x 2700)": {
    //             dimensions: [5120, 2700],
    //             codecs: {
    //                 "REDCODE 2:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 4:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 3:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 5:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 6:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 7:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 8:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 9:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 10:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 11:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 12:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 13:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 14:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 15:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 16:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 17:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 18:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 19:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 20:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 21:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 22:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]}
    //             }
    //         }, 
    //         "4K FF (4096 x 2160)": {
    //             dimensions: [4096, 2160],
    //             codecs: {
    //                 "REDCODE 5:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 6:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 7:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 8:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 9:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 10:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 11:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 12:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 13:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 14:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 15:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 16:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 17:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 18:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 19:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 20:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 21:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 22:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]}
    //             }
    //         }, 
    //         "3K FF (3072 x 1620)": {
    //             dimensions: [3072, 1620],
    //             codecs: {
    //                 "REDCODE 5:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 6:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 7:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 8:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 9:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 10:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 11:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 12:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 13:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 14:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 15:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 16:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 17:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 18:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 19:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 20:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 21:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 22:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]}
    //             }
    //         }, 
    //         "2K FF (2048 x 1080)": {
    //             dimensions: [2048, 1080],
    //             codecs: {
    //                 "REDCODE 5:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 6:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 7:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 8:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 9:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 10:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 11:1": {framerates: ["23.976", "24"]},
    //                 "REDCODE 12:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 13:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 14:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 15:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 16:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 17:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 18:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 19:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 20:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 21:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]},
    //                 "REDCODE 22:1": {framerates: ["23.976", "24", "25", "29.97", "47.95", "48", "50", "59.94", "60"]}
    //             }
    //         }, 
         
    //     }
    // },            
    
     
    // ... other cameras ...
};







