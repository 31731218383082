export const CanonCameras = {
    "EOS C500 Mark II": {
        brand: "Canon",
        resolutions: {
            "6K (5952 x 3140)": {
                dimensions: [5952, 3140],
                codecs: {
                    "Canon Cinema RAW Light HQ": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30"]},
                    "Canon Cinema RAW Light ST": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]},
                    "Canon Cinema RAW Light LT": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]}
                }
            },
            "4K 4:3 (4192 x 3140)": {
                dimensions: [4192, 3140],
                codecs: {
                    "Canon Cinema RAW Light HQ": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30"]},
                    "Canon Cinema RAW Light ST": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]},
                    "Canon Cinema RAW Light LT": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]}
                }
            },
            "3.7K 6:5 (3768 x 3140)": {
                dimensions: [3768, 3140],
                codecs: {
                    "Canon Cinema RAW Light HQ": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30"]},
                    "Canon Cinema RAW Light ST": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]},
                    "Canon Cinema RAW Light LT": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]}
                }
            },
            "4K 17:9 FF (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Canon Cinema RAW Light HQ": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]},
                    "Canon Cinema RAW Light ST": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]},
                    "Canon Cinema RAW Light LT": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]},
                    "Canon XF-AVC": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]}
                }
            },
            "UHD S35 Crop (3840 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Canon XF-AVC": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]}
                }
            },
            "2K FF/S35 (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "Canon Cinema RAW Light HQ": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]},
                    "Canon Cinema RAW Light ST": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]},
                    "Canon Cinema RAW Light LT": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]},
                    "Canon XF-AVC": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60", "72", "75", "90", "96", "100", "120"]}
                }
            },
            "2K S16 (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "Canon Cinema RAW Light HQ": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]},
                    "Canon Cinema RAW Light ST": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]},
                    "Canon Cinema RAW Light LT": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60"]},
                    "Canon XF-AVC": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60", "72", "75", "90", "96", "100", "120"]}
                }
            },
            "HD (1920 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "Canon XF-AVC": {framerates: ["12", "15", "16", "17", "18", "19", "20", "21", "22", "23", "23.976", "24", "25", "26", "28", "29.97", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "59.94", "60", "72", "75", "90", "96", "100", "120"]}
                }
            },            

        }
    },
    "EOS C300 Mark III": {
        brand: "Canon",
        resolutions: {
            "4K (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Canon Cinema RAW Light": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Canon XF-AVC": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]}
            }
        },
            "UHD (3840 x 2160)": { 
                dimensions: [3840, 2160],
                codecs: {
                    "Canon XF-AVC": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]}
            }
        },
            "2K (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "Canon Cinema RAW Light": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Canon XF-AVC": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]}
            }
        },
            "HD (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "Canon XF-AVC": {framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]}
            }
        },
            "720p (1280 x 720)": {
                dimensions: [1280, 720],
                codecs: {
                    "Canon XF-AVC": {framerates: ["50", "59.94"]}
                }
            },            
        }
    },
    // ... other cameras ...
};
