import React, { createContext, useContext, useState } from 'react';

const CalculatorContext = createContext();

export const CalculatorProvider = ({ children }) => {
  // Camera to File Size state
  const [cameraCalcState, setCameraCalcState] = useState({
    duration: '01:00:00:00',
    brand: 'ARRI',
    camera: 'ALEXA 35',
    resolution: '4.6K 3:2 Open Gate (4608 x 3164)',
    framerate: '23.976',
    codec: 'ARRIRAW',
    fileSize: '1.915',
    fileSizeUnit: 'TB',
    durationInput: '01:00:00:00',
    fileSizeInput: '1.915 TB',
    isDurationLocked: false,
    isFileSizeLocked: false,
    calculationMode: null,
    availableCameras: [],
    availableResolutions: [],
    availableFramerates: [],
    availableCodecs: [],
    copyStatus: '',
    isMobile: false,
    copiedLogIndex: null,
    copyDurationText: null,
    copyFileSizeText: null,
    logButtonText: null,
    shareButtonText: null,
    resetButtonText: null,
    previousResolution: '4.6K 3:2 Open Gate (4608 x 3164)'
  });

  // Duration to File Size state
  const [durationCalcState, setDurationCalcState] = useState({
    duration: "01:00:00:00",
    resolution: "UHD (3840 x 2160)",
    codec: "Apple ProRes 422 HQ",
    framerate: "23.976",
    fileSize: "310.328 GB",
    durationInput: "01:00:00:00",
    fileSizeInput: "310.328 GB",
    isDurationLocked: false,
    isFileSizeLocked: false,
    calculationMode: null
  });

  // Aspect Ratio Calculator state
  const [aspectCalcState, setAspectCalcState] = useState({
    selectedRatio: "16:9",
    width: 1920,
    height: 1080,
    pinnedField: null,
    customRatio: "",
    customRatios: [],
    isMobile: false,
    logButtonText: null,
    copyButtonText: null,
    shareButtonText: null,
    resetButtonText: null,
    calculationMode: null,
    isWidthLocked: false,
    isHeightLocked: false
  });

  // Dailies Calculator state
  const [dailiesCalcState, setDailiesCalcState] = useState({
    duration: '01:00:00:00',
    brand: 'ARRI',
    camera: 'ALEXA 35',
    resolution: '4.6K 3:2 Open Gate (4608 x 3164)',
    framerate: '23.976',
    codec: 'ARRIRAW',
    fileSize: '1.915 TB',
    width: 1920,
    height: 1080,
    selectedRatio: "Match",
    customRatio: "",
    pinnedField: null,
    dailiesCodec: "Apple ProRes Proxy",
    dailiesFileSize: "",
    isDurationLocked: false,
    isFileSizeLocked: false,
    isDailiesFileSizeLocked: false,
    calculationMode: null,
    isWidthLocked: false,
    isHeightLocked: false,
    customRatios: [],
    isMobile: false,
    logButtonText: null,
    copyButtonText: null,
    shareButtonText: null,
    resetButtonText: null,
    availableCameras: [],
    availableResolutions: [],
    availableFramerates: [],
    availableCodecs: []
  });

  // Film Calculator state
  const [filmCalcState, setFilmCalcState] = useState({
    filmFormat: "35mm 4-perf",
    fps: "24",
    stock: "5219 VISION3 500T Color Negative",
    rollsCount: "1",
    rollLength: "1000",
    duration: "11 minutes 6.7 seconds",
    frames: "16,000",
    price: "$791.40",
    logButtonText: null,
    shareButtonText: null,
    resetButtonText: null,
    copyButtonText: null
  });

  return (
    <CalculatorContext.Provider
      value={{
        cameraCalcState,
        setCameraCalcState,
        durationCalcState,
        setDurationCalcState,
        aspectCalcState,
        setAspectCalcState,
        dailiesCalcState,
        setDailiesCalcState,
        filmCalcState,
        setFilmCalcState
      }}
    >
      {children}
    </CalculatorContext.Provider>
  );
};

export const useCalculator = () => {
  const context = useContext(CalculatorContext);
  if (!context) {
    throw new Error('useCalculator must be used within a CalculatorProvider');
  }
  return context;
}; 