import React, { useState, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SlDocs, SlNote, SlRefresh, SlLike, SlLink } from "react-icons/sl";
import { FaLock, FaUnlockAlt, FaAngleLeft, FaAngleRight, FaAngleUp } from 'react-icons/fa';
import { formatFileSize } from '../utils/formatters';

const BitrateCalculator = ({ addLog }) => {
  const [durationInput, setDurationInput] = useState("01:00:00:00");
  const [bitrate, setBitrate] = useState('100');
  const [fileSize, setFileSize] = useState('45');
  const [fileSizeUnit, setFileSizeUnit] = useState('GB');
  const [pinnedField, setPinnedField] = useState(null);
  const [calculationMode, setCalculationMode] = useState(null);
  const [logButtonText, setLogButtonText] = useState(<SlNote />);
  const [copyButtonText, setCopyButtonText] = useState(<SlDocs />);
  const [shareButtonText, setShareButtonText] = useState(<SlLink />);
  const [resetButtonText, setResetButtonText] = useState(<SlRefresh />);
  const [isMobile, setIsMobile] = useState(false);
  const [bitrateUnit, setBitrateUnit] = useState('Mbps');

  const durationRef = useRef(null);
  const bitrateRef = useRef(null);
  const fileSizeRef = useRef(null);

  // Style constants
  const bgColor = 'bg-white';
  const textColor = 'text-gray-900';
  const labelColor = 'text-gray-700';
  const buttonColor = 'bg-blue-500 hover:bg-blue-600';

  const getInputStyle = (field) => {
    const baseStyle = "w-full px-2 py-1 border rounded h-[36px] text-center";
    
    if (pinnedField === field) {
      return `${baseStyle} bg-gray-200 text-gray-700 border-2 border-blue-500 cursor-not-allowed`;
    }
    
    return `${baseStyle} bg-gray-50 border-gray-300 text-gray-900`;
  };

  const pinButtonStyle = `p-2 rounded h-[36px] w-[42px] flex items-center justify-center self-end bg-gray-200 hover:bg-gray-300 border-2 ${
    pinnedField ? 'border-blue-500' : 'border-transparent'
  }`;

  const handleDurationChange = (e) => {
    setDurationInput(e.target.value);
    recalculateValues('duration', e.target.value);
  };

  const handleBitrateChange = (e) => {
    const value = e.target.value;
    const parsed = parseBitrate(value);
    
    if (parsed) {
      setBitrate(parsed.size.toString());
      setBitrateUnit(parsed.unit);
      recalculateValues('bitrate', parsed.size);
    } else {
      setBitrate(value);
    }
  };

  const handleFileSizeChange = (e) => {
    const value = e.target.value;
    const parsed = parseFileSize(value);
    
    if (parsed) {
      setFileSize(parsed.size.toString());
      setFileSizeUnit(parsed.unit);
      recalculateValues('fileSize', parsed.size);
    } else {
      setFileSize(value);
    }
  };

  const recalculateValues = (changedField, value) => {
    if (pinnedField === changedField) return;

    const durationInMinutes = parseDuration(durationInput) / 60; // Convert seconds to minutes
    
    // Convert bitrate to kbps for calculations
    const getBitrateInKbps = (value, unit) => {
      switch (unit.toUpperCase()) {
        case 'TBPS': return value * 1000000000;
        case 'GBPS': return value * 1000000;
        case 'MBPS': return value * 1000;
        case 'KBPS': return value;
        default: return value / 1000;
      }
    };

    // Convert file size to MB for calculations
    const getFileSizeInMB = (value, unit) => {
      switch (unit.toUpperCase()) {
        case 'PB': return value * 1024 * 1024 * 1024;
        case 'TB': return value * 1024 * 1024;
        case 'GB': return value * 1024;
        case 'MB': return value;
        case 'KB': return value / 1024;
        default: return value / (1024 * 1024);
      }
    };

    switch (calculationMode) {
      case 'duration':
        // Duration is pinned
        if (changedField === 'bitrate') {
          // Calculate file size from bitrate
          // File Size (MB) = (Bitrate (kbps) × Duration (minutes) × 60) ÷ (8 × 1000)
          const bitrateKbps = getBitrateInKbps(parseFloat(value), bitrateUnit);
          const newFileSizeMB = (bitrateKbps * durationInMinutes * 60) / (8 * 1000);
          const { size, unit } = formatFileSizeWithUnit(newFileSizeMB);
          setFileSize(size.toString());
          setFileSizeUnit(unit);
        } else if (changedField === 'fileSize') {
          // Calculate bitrate from file size
          // Bitrate (kbps) = (File Size (MB) × 8 × 1000) ÷ (Duration (minutes) × 60)
          const fileSizeMB = getFileSizeInMB(parseFloat(value), fileSizeUnit);
          const newBitrateKbps = (fileSizeMB * 8 * 1000) / (durationInMinutes * 60);
          const { size, unit } = formatBitrateWithUnit(newBitrateKbps);
          setBitrate(size.toString());
          setBitrateUnit(unit);
        }
        break;

      case 'bitrate':
        // Bitrate is pinned, calculate based on changed field
        if (changedField === 'duration') {
          const newFileSize = (parseFloat(bitrate) * parseDuration(value)) / 8;
          setFileSize(newFileSize.toFixed(2));
        } else if (changedField === 'fileSize') {
          const newDuration = (parseFloat(value) * 8) / parseFloat(bitrate);
          setDurationInput(formatDuration(newDuration));
        }
        break;

      case 'fileSize':
        // File size is pinned, calculate based on changed field
        if (changedField === 'duration') {
          const newBitrate = (parseFloat(fileSize) * 8) / parseDuration(value);
          setBitrate(newBitrate.toFixed(2));
        } else if (changedField === 'bitrate') {
          const newDuration = (parseFloat(value) * 8) / parseFloat(fileSize);
          setDurationInput(formatDuration(newDuration));
        }
        break;

      default:
        // Nothing is pinned, calculate based on changed field
        if (changedField === 'duration') {
          const newFileSize = (parseFloat(bitrate) * parseDuration(value)) / 8;
          setFileSize(newFileSize.toFixed(2));
        } else if (changedField === 'bitrate') {
          const newFileSize = (parseFloat(value) * durationInMinutes * 60 * 1000) / (8 * 1000);
          const { size, unit } = formatFileSizeWithUnit(newFileSize);
          setFileSize(size.toString());
          setFileSizeUnit(unit);
        } else if (changedField === 'fileSize') {
          const newBitrate = (parseFloat(value) * 8 * 1000) / (durationInMinutes * 60 * 1000);
          const { size, unit } = formatBitrateWithUnit(newBitrate);
          setBitrate(size.toString());
          setBitrateUnit(unit);
        }
    }
  };

  const parseDuration = (duration) => {
    const [hours, minutes, seconds, frames] = duration.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds + (frames || 0) / 24;
  };

  const handlePinClick = () => {
    setPinnedField(current => {
      if (current === null) {
        setCalculationMode('bitrate');
        return 'bitrate';
      }
      if (current === 'bitrate') {
        setCalculationMode('duration');
        return 'duration';
      }
      if (current === 'duration') {
        setCalculationMode('fileSize');
        return 'fileSize';
      }
      setCalculationMode(null);
      return null;
    });
  };

  const handleReset = () => {
    setDurationInput("01:00:00:00");
    setBitrate('100');
    setFileSize('45');
    setFileSizeUnit('GB');
    setPinnedField(null);
    setResetButtonText(<SlLike />);
    setTimeout(() => setResetButtonText(<SlRefresh />), 500);
  };

  const handleShare = () => {
    const shareUrl = `${window.location.origin}${window.location.pathname}?duration=${durationInput}&bitrate=${bitrate}&fileSize=${fileSize}`;
    
    if (navigator.share) {
      navigator.share({
        title: 'Bitrate Calculation',
        text: `${durationInput} @ ${bitrate}Mbps = ${fileSize}${fileSizeUnit}`,
        url: shareUrl
      }).then(() => {
        setShareButtonText(<SlLike />);
        setTimeout(() => setShareButtonText(<SlLink />), 500);
      });
    } else {
      navigator.clipboard.writeText(shareUrl).then(() => {
        setShareButtonText(<SlLike />);
        setTimeout(() => setShareButtonText(<SlLink />), 500);
      });
    }
  };

  const handleLog = () => {
    const logEntry = `${durationInput} @ ${bitrate}Mbps = ${fileSize}${fileSizeUnit}`;
    addLog(logEntry);
    setLogButtonText(<SlLike />);
    setTimeout(() => setLogButtonText(<SlNote />), 500);
  };

  const handleCopy = () => {
    setCopyButtonText(<SlLike />);
    setTimeout(() => setCopyButtonText(<SlDocs />), 500);
  };

  // Add helper function to format duration
  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const frames = Math.round((seconds % 1) * 24); // Assuming 24fps

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
  };

  // Add wheel handlers for each field
  const handleDurationWheel = (e) => {
    e.preventDefault();
    if (pinnedField === 'duration') return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    
    let [hours, minutes, seconds, frames] = durationInput.split(':').map(Number);
    const framerate = 24; // Using 24fps as default
    
    const sensitivity = 0.025;
    const scrollAmount = -e.deltaY * sensitivity;
    
    if (x < width * 0.25) {
      // Hours
      hours = Math.max(0, hours + Math.sign(scrollAmount));
    } else if (x < width * 0.5) {
      // Minutes
      minutes += Math.sign(scrollAmount);
      if (minutes < 0) {
        if (hours > 0) {
          hours--;
          minutes = 59;
        } else {
          minutes = 0;
        }
      } else if (minutes >= 60) {
        hours++;
        minutes = 0;
      }
    } else if (x < width * 0.75) {
      // Seconds
      seconds += Math.sign(scrollAmount);
      if (seconds < 0) {
        if (minutes > 0) {
          minutes--;
          seconds = 59;
        } else if (hours > 0) {
          hours--;
          minutes = 59;
          seconds = 59;
        } else {
          seconds = 0;
        }
      } else if (seconds >= 60) {
        minutes++;
        seconds = 0;
        if (minutes >= 60) {
          hours++;
          minutes = 0;
        }
      }
    } else {
      // Frames
      frames += Math.sign(scrollAmount);
      if (frames < 0) {
        if (seconds > 0) {
          seconds--;
          frames = framerate - 1;
        } else if (minutes > 0) {
          minutes--;
          seconds = 59;
          frames = framerate - 1;
        } else if (hours > 0) {
          hours--;
          minutes = 59;
          seconds = 59;
          frames = framerate - 1;
        } else {
          frames = 0;
        }
      } else if (frames >= framerate) {
        seconds++;
        frames = 0;
        if (seconds >= 60) {
          minutes++;
          seconds = 0;
          if (minutes >= 60) {
            hours++;
            minutes = 0;
          }
        }
      }
    }

    const newDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
    setDurationInput(newDuration);
    recalculateValues('duration', newDuration);
  };

  const handleBitrateWheel = (e) => {
    e.preventDefault();
    if (pinnedField === 'bitrate') return;

    const delta = Math.sign(-e.deltaY);
    const changeFactor = 1.1;
    
    let parsed = parseBitrate(`${bitrate} ${bitrateUnit}`);
    if (!parsed) return;
    
    let { size, unit } = parsed;
    let newSize = delta > 0 ? size * changeFactor : size / changeFactor;

    const units = ['KBPS', 'MBPS', 'GBPS', 'TBPS'];
    let unitIndex = units.indexOf(unit);

    while (newSize >= 1000 && unitIndex < units.length - 1) {
      newSize /= 1000;
      unitIndex++;
    }
    while (newSize < 1 && unitIndex > 0) {
      newSize *= 1000;
      unitIndex--;
    }

    const newUnit = units[unitIndex];
    newSize = parseFloat(newSize.toFixed(2));
    
    setBitrate(newSize.toString());
    setBitrateUnit(newUnit);
    recalculateValues('bitrate', newSize);
  };

  const handleFileSizeWheel = (e) => {
    e.preventDefault();
    if (pinnedField === 'fileSize') return;

    const delta = Math.sign(-e.deltaY);
    const changeFactor = 1.1;
    
    let parsed = parseFileSize(`${fileSize} ${fileSizeUnit}`);
    if (!parsed) return;
    
    let { size, unit } = parsed;
    let newSize = delta > 0 ? size * changeFactor : size / changeFactor;

    // Define the order of units
    const units = ['KB', 'MB', 'GB', 'TB', 'PB'];
    let unitIndex = units.indexOf(unit);

    // Adjust unit if size exceeds boundaries
    while (newSize >= 1024 && unitIndex < units.length - 1) {
      newSize /= 1024;
      unitIndex++;
    }
    while (newSize < 1 && unitIndex > 0) {
      newSize *= 1024;
      unitIndex--;
    }

    const newUnit = units[unitIndex];
    newSize = parseFloat(newSize.toFixed(3));
    
    setFileSize(newSize.toString());
    setFileSizeUnit(newUnit);
    recalculateValues('fileSize', newSize);
  };

  // Add touch handlers for mobile
  const handleTouchStart = (e, field) => {
    if (pinnedField === field) return;
    
    const touch = e.touches[0];
    const rect = e.currentTarget.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    
    e.currentTarget.dataset.touchStartY = touch.clientY;
    e.currentTarget.dataset.lastTouchY = touch.clientY;
    e.currentTarget.dataset.touchX = touchX;
  };

  const handleTouchMove = (e, field) => {
    e.preventDefault();
    if (pinnedField === field) return;

    const touch = e.touches[0];
    const lastY = parseFloat(e.currentTarget.dataset.lastTouchY);
    const touchX = parseFloat(e.currentTarget.dataset.touchX);
    const deltaY = lastY - touch.clientY;
    
    switch (field) {
      case 'duration':
        // Implement duration touch scrolling similar to handleDurationWheel
        break;
      case 'bitrate':
        const bitrateChange = deltaY * 0.1;
        const newBitrate = Math.max(0.1, parseFloat(bitrate) + bitrateChange).toFixed(1);
        setBitrate(newBitrate);
        recalculateValues('bitrate', newBitrate);
        break;
      case 'fileSize':
        // Implement file size touch scrolling similar to handleFileSizeWheel
        break;
    }

    e.currentTarget.dataset.lastTouchY = touch.clientY;
  };

  // Add bitrate parsing function similar to file size parsing
  const parseBitrate = (input) => {
    const cleanInput = input.replace(/\s+/g, '').toLowerCase();
    
    const unitPatterns = {
      'tbps': ['t', 'tb', 'tbs', 'tbps', 'terabit', 'terabits'],
      'gbps': ['g', 'gb', 'gbs', 'gbps', 'gigabit', 'gigabits'],
      'mbps': ['m', 'mb', 'mbs', 'mbps', 'megabit', 'megabits'],
      'kbps': ['k', 'kb', 'kbs', 'kbps', 'kilobit', 'kilobits'],
      'bps': ['b', 'bs', 'bps', 'bit', 'bits']
    };

    const unitRegexPattern = Object.values(unitPatterns).flat().join('|');
    const match = cleanInput.match(new RegExp(`^(\\d+(?:\\.\\d+)?)(${unitRegexPattern})?$`));

    if (match) {
      const size = parseFloat(match[1]);
      const unitLower = match[2] || 'mbps'; // Default to Mbps if no unit specified

      for (const [standardUnit, patterns] of Object.entries(unitPatterns)) {
        if (patterns.includes(unitLower)) {
          return { size, unit: standardUnit.toUpperCase() };
        }
      }
    }
    return null;
  };

  // Update the formatFileSize function to use 3 decimals
  const formatFileSize = (size, unit) => {
    return `${parseFloat(size).toFixed(3)} ${unit}`;
  };

  // Add this function near the top with other helper functions
  const parseFileSize = (input) => {
    // Remove any whitespace and convert to lowercase
    const cleanInput = input.replace(/\s+/g, '').toLowerCase();

    // Define unit patterns
    const unitPatterns = {
      'pb': ['p', 'ps', 'pb', 'pbs', 'pe', 'pet', 'pets', 'peta', 'petas', 'petabyte', 'petabytes'],
      'tb': ['t', 'ts', 'tb', 'tbs', 'te', 'ter', 'ters', 'tera', 'teras', 'terabyte', 'terabytes'],
      'gb': ['g', 'gs', 'gb', 'gbs', 'gi', 'gig', 'gigs', 'giga', 'gigas', 'gigabyte', 'gigabytes'],
      'mb': ['m', 'ms', 'mb', 'mbs', 'me', 'meg', 'megs', 'mega', 'megas', 'megabyte', 'megabytes'],
      'kb': ['k', 'ks', 'kb', 'kbs', 'ki', 'kil', 'kils', 'kilo', 'kilos', 'kilobyte', 'kilobytes'],
      'b': ['b', 'bs', 'bi', 'by', 'byte', 'bytes']
    };

    // Create a regex pattern that matches any of the unit patterns
    const unitRegexPattern = Object.values(unitPatterns).flat().join('|');
    
    // Match the input against the pattern
    const match = cleanInput.match(new RegExp(`^(\\d+(?:\\.\\d+)?)(${unitRegexPattern})$`));

    if (match) {
      const size = parseFloat(match[1]);
      const unitLower = match[2];

      // Determine the standardized unit
      for (const [standardUnit, patterns] of Object.entries(unitPatterns)) {
        if (patterns.includes(unitLower)) {
          return { size, unit: standardUnit.toUpperCase() };
        }
      }
    }

    return null;
  };

  // Add helper function to format bitrate with appropriate unit
  const formatBitrateWithUnit = (bitrateKbps) => {
    if (bitrateKbps >= 1000000000) {
      return { size: (bitrateKbps / 1000000000).toFixed(1), unit: 'Tbps' };
    }
    if (bitrateKbps >= 1000000) {
      return { size: (bitrateKbps / 1000000).toFixed(1), unit: 'Gbps' };
    }
    if (bitrateKbps >= 1000) {
      return { size: (bitrateKbps / 1000).toFixed(1), unit: 'Mbps' };
    }
    return { size: bitrateKbps.toFixed(1), unit: 'Kbps' };
  };

  // Add helper function to format file size with appropriate unit
  const formatFileSizeWithUnit = (sizeMB) => {
    if (sizeMB >= 1024 * 1024 * 1024) {
      return { size: (sizeMB / (1024 * 1024 * 1024)).toFixed(3), unit: 'PB' };
    }
    if (sizeMB >= 1024 * 1024) {
      return { size: (sizeMB / (1024 * 1024)).toFixed(3), unit: 'TB' };
    }
    if (sizeMB >= 1024) {
      return { size: (sizeMB / 1024).toFixed(3), unit: 'GB' };
    }
    if (sizeMB >= 1) {
      return { size: sizeMB.toFixed(3), unit: 'MB' };
    }
    return { size: (sizeMB * 1024).toFixed(3), unit: 'KB' };
  };

  return (
    <div className={`bitrate-calculator p-2 ${bgColor} ${textColor}`}>
      <div className="flex flex-col space-y-2">
        <div>
          <label className={`block ${labelColor}`}>
            {isMobile ? "Duration" : "Duration (HH:MM:SS:FF)"}
          </label>
          <input
            ref={durationRef}
            type="text"
            value={durationInput}
            onChange={handleDurationChange}
            onWheel={handleDurationWheel}
            onTouchStart={(e) => handleTouchStart(e, 'duration')}
            onTouchMove={(e) => handleTouchMove(e, 'duration')}
            className={getInputStyle('duration')}
            placeholder="00:00:00:00"
            disabled={pinnedField === 'duration'}
          />
        </div>

        <div className="grid grid-cols-[1fr,auto,1fr] gap-2 items-end">
          <div>
            <label className={`block ${labelColor}`}>Bitrate</label>
            <input
              ref={bitrateRef}
              type="text"
              value={`${bitrate} ${bitrateUnit}`}
              onChange={handleBitrateChange}
              onWheel={handleBitrateWheel}
              onTouchStart={(e) => handleTouchStart(e, 'bitrate')}
              onTouchMove={(e) => handleTouchMove(e, 'bitrate')}
              className={getInputStyle('bitrate')}
              disabled={pinnedField === 'bitrate'}
              placeholder="ex: 100 Mbps"
            />
          </div>

          <div className="flex flex-col justify-end">
            <label className="block invisible">Pin</label>
            <button
              onClick={handlePinClick}
              className={`${pinButtonStyle} ${pinnedField ? 'bg-gray-300' : ''} relative`}
            >
              {pinnedField ? (
                <FaLock 
                  className={`text-blue-500 scale-[0.85] transition-transform`}
                />
              ) : (
                <FaUnlockAlt 
                  className={`text-gray-500 scale-100 transition-transform`}
                />
              )}
              {pinnedField === 'duration' && (
                <FaAngleUp className="absolute -top-1 left-1/2 -translate-x-1/2 text-blue-500" />
              )}
              {pinnedField === 'bitrate' && (
                <FaAngleLeft className="absolute -left-1 top-1/2 -translate-y-1/2 text-blue-500" />
              )}
              {pinnedField === 'fileSize' && (
                <FaAngleRight className="absolute -right-1 top-1/2 -translate-y-1/2 text-blue-500" />
              )}
            </button>
          </div>

          <div>
            <label className={`block ${labelColor}`}>Estimated File Size</label>
            <input
              ref={fileSizeRef}
              type="text"
              value={formatFileSize(fileSize, fileSizeUnit)}
              onChange={handleFileSizeChange}
              onWheel={handleFileSizeWheel}
              onTouchStart={(e) => handleTouchStart(e, 'fileSize')}
              onTouchMove={(e) => handleTouchMove(e, 'fileSize')}
              className={getInputStyle('fileSize')}
              disabled={pinnedField === 'fileSize'}
              placeholder="ex: 45.000 GB"
            />
          </div>
        </div>
      </div>

      <div className="mt-4 flex justify-between w-full">
        <button
          onClick={handleLog}
          className={`px-4 py-4 rounded flex-grow mr-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {logButtonText}
        </button>
        <CopyToClipboard 
          text={`${durationInput} @ ${bitrate}Mbps = ${fileSize}${fileSizeUnit}`} 
          onCopy={handleCopy}
        >
          <button 
            className={`px-4 py-4 rounded flex-grow mx-2 ${buttonColor} text-white flex items-center justify-center`}
          >
            {copyButtonText}
          </button>
        </CopyToClipboard>
        <button
          onClick={handleShare}
          className={`px-4 py-4 rounded flex-grow mx-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {shareButtonText}
        </button>
        <button
          onClick={handleReset}
          className={`px-4 py-4 rounded flex-grow ml-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {resetButtonText}
        </button>
      </div>
    </div>
  );
};

export default BitrateCalculator; 