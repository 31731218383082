export const GoProCameras = {
    "HERO 13": {
        brand: "GoPro",
        resolutions: {
            "5.3K 8:7 (5312x4648)": {
                dimensions: [5312, 4648],
                codecs: {
                    "HEVC/H.265 120 Mbps": { framerates: ["24", "25", "30"] }
                }
            },
            "5.3K 16:9 (5312x2988)": {
                dimensions: [5312, 2988],
                codecs: {
                    "HEVC/H.265 120 Mbps": { framerates: ["24", "25", "30", "50", "60"] }
                }
            },
            "4K 8:7(3840x3360)": {
                dimensions: [3840, 3360],
                codecs: {
                    "HEVC/H.265 120 Mbps": { framerates: ["24", "25", "30", "50", "60"] }
                }
            },
            "UHD 16:9 (3840x2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "HEVC/H.265 120 Mbps": { framerates: ["24", "25", "30", "50", "60", "100", "120"] }
                }
            },
            "UHD 9:16 (2160x3840)": {
                dimensions: [2160, 3840],
                codecs: {
                    "HEVC/H.265 120 Mbps": { framerates: ["25", "30", "50", "60"] }
                }
            },
            "2.7K 16:9 (2704x1520)": {
                dimensions: [2704, 1520],
                codecs: {
                    "HEVC/H.265 120 Mbps": { framerates: ["200", "240"] }
                }
            },
            "2.7K 4:3 (2704x2028)": {
                dimensions: [2704, 2028],
                codecs: {
                    "HEVC/H.265 120 Mbps": { framerates: ["100", "120"] }
                }
            },
            "1080p 16:9 (1920x1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "HEVC/H.265 120 Mbps": { framerates: ["25", "30", "50", "60", "100", "120", "200", "240"] }
                }
            },
            "1080p 9:16 (1080x1920)": {
                dimensions: [1080, 1920],
                codecs: {
                    "HEVC/H.265 120 Mbps": { framerates: ["25", "30", "50", "60"] }
                }
            },
        }
    },
    "HERO 2024": {
        brand: "GoPro",
        resolutions: {
            "UHD (3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "HEVC/H.265 50 Mbps": { framerates: ["25", "30"] }
                }
            },          
            "2.7K (2704 x 1520)": {
                dimensions: [2704, 1520],
                codecs: {
                    "HEVC/H.265 50 Mbps": { framerates: ["50", "60"] }
                }
            },
            "HD (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "HEVC/H.265 50 Mbps": { framerates: ["25", "30"] }
                }
            },
        }
    },

    // ... other cameras ..
};