import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SlArrowUp, SlArrowDown } from "react-icons/sl";
import { FaInstagram } from "react-icons/fa";

const APP_VERSION = 'v0.8.5b';

const Footer = ({ setShowPrivacyPolicy, isMobile }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      const timer = setTimeout(() => {
        setIsExpanded(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <footer className={`${isMobile ? 'mt-0' : 'mt-4'} bg-gray-200`}>
      <div className="px-4 text-center text-xs text-gray-700">
        <div className="md:space-y-1">
          {/* Mobile Footer */}
          <div className="md:hidden">
            <div 
              className="py-2 flex items-center justify-center gap-2 cursor-pointer"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <span>
                Made by your friends at {isExpanded ? (
                  <a href="https://tbdpost.com" target="_blank" rel="noopener noreferrer" className="underline hover:text-gray-900 transition-colors">TBD Post</a>
                ) : (
                  'TBD Post'
                )}
              </span>
              {isExpanded ? <SlArrowDown /> : <SlArrowUp />}
            </div>
            <div className={`space-y-1 overflow-hidden transition-all duration-500 ease-in-out ${
              isExpanded ? 'max-h-40 pb-4' : 'max-h-0 pb-1'
            }`}>
              <div>
                <br />
                  <a 
                    href="https://forms.gle/UqCj9QU1gTePgoiD8" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="underline hover:text-gray-900 transition-colors"
                >
                  Submit a Feature Request or Bug Report
                </a>
              </div>
              
              <div>
                ©{new Date().getFullYear()} | {APP_VERSION} |{' '}
                <a 
                  href="https://www.instagram.com/data_calc" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-flex items-center gap-0.5 underline hover:text-gray-900 transition-colors"
                >
                  <FaInstagram className="h-3 w-auto" />data_calc
                </a>
              </div>
              
              <div className="flex justify-center gap-4">
                <button 
                  onClick={() => setShowPrivacyPolicy(true)} 
                  className="underline hover:text-gray-900 transition-colors"
                >
                  Privacy Policy
                </button>
              </div>
            </div>
          </div>

          {/* Desktop Footer */}
          <div className="hidden md:block space-y-1 py-2">
            <div>
              Made by your friends at{' '}
              <a 
                href="https://tbdpost.com" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="underline hover:text-gray-900 transition-colors"
              >
                TBD Post
              </a>
            </div>
            <div>
              <a 
                href="https://forms.gle/UqCj9QU1gTePgoiD8" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="underline hover:text-gray-900 transition-colors"
              >
                Submit a Feature Request or Bug Report
              </a>
            </div>
            
            <div>
              ©{new Date().getFullYear()} | {APP_VERSION} |{' '}
              <a 
                  href="https://www.instagram.com/data_calc" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-flex items-center gap-0.5 underline hover:text-gray-900 transition-colors"
                >
                  <FaInstagram className="h-3 w-auto" />data_calc
                </a>
            </div>
            
            <div className="flex justify-center gap-4">
              <button 
                onClick={() => setShowPrivacyPolicy(true)} 
                className="underline hover:text-gray-900 transition-colors"
              >
                Privacy Policy
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;