import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
    FaClock, 
    FaClipboardList,
    FaHistory,
    FaPlayCircle 
  } from 'react-icons/fa';
  import { 
    FaVideo,
    FaCalculator
  } from 'react-icons/fa6';
import { BsFillAspectRatioFill, BsFillStopwatchFill } from 'react-icons/bs';
import { BiTransfer } from 'react-icons/bi';
import { HiSparkles } from 'react-icons/hi2';
import { MdInsertChart } from 'react-icons/md';
import { PiFilmReelFill } from 'react-icons/pi';
import { RiFileEditFill } from 'react-icons/ri';
import { calculatorConfig } from '../config/features';

const SideMenu = ({ isMobile }) => {
  const location = useLocation();

  const renderMenuItem = (calc) => {
    const isActive = location.pathname === calc.path;
    const baseClasses = `flex items-center justify-between px-3 py-2 rounded-lg transition-colors
      ${isActive ? 'bg-blue-500 text-white shadow-md' : 'text-gray-700 hover:bg-gray-100'}`;

    // For hidden items
    if (calc.hidden) {
      return null;
    }

    const iconGroup = (
      <div className="flex items-center gap-1">
        {calc.icon}
        {calc.secondaryIcon || <FaCalculator />}
      </div>
    );

    // For coming soon or disabled items
    if (calc.comingSoon || (!calc.enabled && !calc.production)) {
      return (
        <div className={`${baseClasses} cursor-not-allowed opacity-70`}>
          <div className="flex items-center space-x-3">
            {iconGroup}
            <span>{calc.title}</span>
          </div>
          <span className="text-xs bg-gray-200 text-gray-700 px-2 py-0.5 rounded-full">
            SOON
          </span>
        </div>
      );
    }

    return (
      <Link
        to={calc.path}
        className={baseClasses}
      >
        <div className="flex items-center space-x-3">
          {iconGroup}
          <span>{calc.title}</span>
        </div>
        {calc.beta && (
          <span className="text-xs bg-blue-200 text-blue-800 px-2 py-0.5 rounded-full">
            BETA
          </span>
        )}
      </Link>
    );
  };

  return (
    <div className="w-68 bg-white shadow-md rounded-lg p-3 h-fit">
      <nav>
        <ul className="space-y-2">
          {Object.entries(calculatorConfig)
            .filter(([_, calc]) => !calc.hidden)
            .map(([key, calc]) => (
              <li key={calc.path}>
                {renderMenuItem(calc)}
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default SideMenu;