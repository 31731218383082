import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SlDocs, SlNote, SlRefresh, SlLike, SlLink } from "react-icons/sl";
import { FaThumbtack, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { getCamerasByBrand, getResolutionsForCamera, getFrameratesForCamera, getCodecsForCameraAndResolution } from '../data/cameraData';
import { codecs, dailiesCodecs } from '../data/codecData';
import { brands } from '../data/brandData';
import { resolutions, cameraResolutions } from '../data/resolutionData';
import { handleShare as handleShareUtil, disableScroll, enableScroll } from '../utils';
import { useDropdownTouch } from '../hooks/useDropdownTouch';

// Timecode class for handling timecode operations
class Timecode {
  constructor(hours = 0, minutes = 0, seconds = 0, frames = 0, frameRate = 24) {
    this.hours = Math.floor(hours);
    this.minutes = Math.floor(minutes);
    this.seconds = Math.floor(seconds);
    this.frames = Math.floor(frames);
    this.frameRate = frameRate;
  }

  // Parse a timecode string into a Timecode object
  static parse(timecodeString, frameRate = 24) {
    const parts = timecodeString.split(/[:.]/).map(Number);
    while (parts.length < 4) parts.unshift(0);
    const [hours, minutes, seconds, frames] = parts;
    return new Timecode(hours, minutes, seconds, frames, frameRate);
  }
  
  // Convert Timecode object to string
  toString() {
    return `${this.hours.toString().padStart(2, '0')}:${this.minutes.toString().padStart(2, '0')}:${this.seconds.toString().padStart(2, '0')}:${this.frames.toString().padStart(2, '0')}`;
  }

  // Calculate total frames
  totalFrames() {
    return this.hours * 3600 * this.frameRate + 
           this.minutes * 60 * this.frameRate + 
           this.seconds * this.frameRate + 
           this.frames;
  }

  // Create a Timecode object from total frames
  static fromFrames(totalFrames, frameRate = 24) {
    const hours = Math.floor(totalFrames / (3600 * frameRate));
    totalFrames %= 3600 * frameRate;
    const minutes = Math.floor(totalFrames / (60 * frameRate));
    totalFrames %= 60 * frameRate;
    const seconds = Math.floor(totalFrames / frameRate);
    const frames = Math.floor(totalFrames % frameRate);

    return new Timecode(hours, minutes, seconds, frames, frameRate);
  }
}


const defaultAspectRatios = ["Match", "2.39:1", "2:1", "1.85:1", "1.66:1", "16:9", "4:3", 
  "21:9", "16:10", "6:5", "1:1", "4:5", "9:16", "Custom"];


// Add these constants at the top of the file, outside of the component
const DailiesCalculator = ({ addLog }) => {  // Add addLog as a prop
  const [isMobile, setIsMobile] = useState(false);
  const [selectedRatio, setSelectedRatio] = useState("Match");
  const [customRatio, setCustomRatio] = useState("");
  const [width, setWidth] = useState(1920);
  const [height, setHeight] = useState(1080);
  const [pinnedField, setPinnedField] = useState(null);
  const [logButtonText, setLogButtonText] = useState(<SlNote />);
  const [copyButtonText, setCopyButtonText] = useState(<SlDocs />);
  const [shareButtonText, setShareButtonText] = useState(<SlLink />);
  const [resetButtonText, setResetButtonText] = useState(<SlRefresh />);
  const [customRatios, setCustomRatios] = useState([]);
  const [calculationMode, setCalculationMode] = useState(null);
  const [resolution, setResolution] = useState("1920 x 1080");
  const [isWidthLocked, setIsWidthLocked] = useState(false);
  const [isHeightLocked, setIsHeightLocked] = useState(false);
  const [sensitivity] = useState(0.025);

  const ratioRef = useRef(null);
  const widthRef = useRef(null);
  const heightRef = useRef(null);
  const resolutionRef = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ratio = params.get('ratio');
    const width = params.get('width');
    const height = params.get('height');

    if (ratio && width && height) {
      setSelectedRatio(ratio);
      setWidth(parseInt(width, 10));
      setHeight(parseInt(height, 10));
    }
  }, []);

  useEffect(() => {
    calculateDimensions();
  }, [selectedRatio, width, height, pinnedField]);

  const roundToEven = (num) => Math.round(num / 2) * 2;

  const calculateDimensions = () => {
    const aspectRatio = parseRatio(selectedRatio);
    if (isNaN(aspectRatio)) return;

    if (pinnedField === 'width') {
      setHeight(roundToEven(width / aspectRatio));
    } else if (pinnedField === 'height') {
      setWidth(roundToEven(height * aspectRatio));
    } else if (pinnedField === 'ratio') {
      // Do nothing, as ratio is pinned
    } else {
      setHeight(roundToEven(width / aspectRatio));
    }
  };

  const parseRatio = (input) => {
    console.log('Parsing ratio input:', input);

    // Remove all whitespace and convert to lowercase
    input = input.toLowerCase().replace(/\s+/g, '');
    console.log('Cleaned input:', input);

    // Check for decimal format first (e.g., 2.35 or 1.77)
    const decimalMatch = input.match(/^(\d+(?:\.\d+)?)$/);
    if (decimalMatch) {
      const result = parseFloat(input);
      console.log(`Decimal format detected: ${input}, treating as ${input}:1`);
      return result;
    }

    // Check for ratio format (e.g., 16:9, 16x9, 2.35:1)
    const ratioMatch = input.match(/^(\d+(?:\.\d+)?)[:x](\d+(?:\.\d+)?)$/);
    if (ratioMatch) {
      const [w, h] = ratioMatch.slice(1).map(Number);
      const result = w / h;
      console.log(`Ratio format detected: ${w}:${h}, Result:`, result);
      return result;
    }

    // Check for two numbers separated by space (e.g., 16 9, 2.35 1)
    const spaceMatch = input.match(/^(\d+(?:\.\d+)?)\s*(\d+(?:\.\d+)?)$/);
    if (spaceMatch) {
      const [w, h] = spaceMatch.slice(1).map(Number);
      const result = w / h;
      console.log(`Space-separated format detected: ${w} ${h}, Result:`, result);
      return result;
    }

    // If none of the above formats match, return NaN
    console.log('No valid format detected, returning NaN');
    return NaN;
  };

  const compareRatios = (a, b) => {
    const ratioA = parseRatio(a);
    const ratioB = parseRatio(b);
    return ratioA - ratioB;
  };

  const formatRatioDisplay = (ratio) => {
    return ratio;
  };

  const handleRatioChange = (e) => {
    const value = e.target.value;
    setSelectedRatio(value);
    
    if (value === "Custom") {
      setCustomRatio("");
    } else {
      updateDimensionsForRatio(value);
    }
  };

  const handleCustomRatioChange = (e) => {
    const value = e.target.value;
    console.log('Custom ratio input:', value);
    // Allow digits, decimal point, colon, 'x', and space
    // Replace any sequence of spaces with a single space
    if (/^[\d.:x\s]*$/.test(value)) {
      const cleanedValue = value.replace(/\s+/g, ' ');
      // If there's a space, automatically convert it to a colon
      const formattedValue = cleanedValue.replace(/\s/g, ':');
      setCustomRatio(formattedValue);
      console.log('Valid input, setting custom ratio:', formattedValue);
    } else {
      console.log('Invalid input, ignoring');
    }
  };

  const handleCustomRatioKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      processCustomRatio();
    }
  };

  const handleCustomRatioBlur = () => {
    processCustomRatio();
  };

  // New helper function to handle all custom ratio processing
  const processCustomRatio = () => {
    console.log('Processing custom ratio, current value:', customRatio);
    if (customRatio) {
      const parsedRatio = parseRatio(customRatio);
      console.log('Parsed ratio:', parsedRatio);
      if (!isNaN(parsedRatio)) {
        const formattedRatio = formatRatio(parsedRatio);
        console.log('Formatted ratio:', formattedRatio);
        if (!defaultAspectRatios.includes(formattedRatio) && !customRatios.includes(formattedRatio)) {
          setCustomRatios(prevRatios => {
            const newRatios = [...prevRatios, formattedRatio];
            const sortedRatios = newRatios.sort(compareRatios);
            console.log('Updated custom ratios:', sortedRatios);
            return sortedRatios;
          });
        }
        setSelectedRatio(formattedRatio);
        recalculateBasedOnRatio(formattedRatio);
      } else {
        console.log('Invalid ratio, not updating');
      }
    }
    setCustomRatio("");
  };

  const parseCustomRatio = (input) => {
    const parts = input.split(/[:/x\s]/);
    if (parts.length === 2) {
      const [w, h] = parts.map(Number);
      if (!isNaN(w) && !isNaN(h) && w > 0 && h > 0) {
        return [w, h];
      }
    }
    return null;
  };

  const handleWidthChange = (e) => {
    const newWidth = parseInt(e.target.value) || 0;
    let newHeight = dailiesData.height; // Initialize newHeight with current height
    
    // If using Match, calculate based on camera aspect ratio
    if (selectedRatio === 'Match' && calcData.resolution) {
      const resolutionMatch = calcData.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
      if (resolutionMatch) {
        const [, cameraWidth, cameraHeight] = resolutionMatch;
        const cameraAspect = parseInt(cameraWidth) / parseInt(cameraHeight);
        newHeight = Math.round(newWidth / cameraAspect);
      }
    } else if (selectedRatio !== 'Custom') {
      // Calculate based on selected aspect ratio
      const aspectRatio = parseRatio(selectedRatio);
      if (!isNaN(aspectRatio)) {
        newHeight = Math.round(newWidth / aspectRatio);
      }
    }

    setDailiesData(prev => ({
      ...prev,
      width: newWidth,
      height: newHeight
    }));

    // Update UI states
    setWidth(newWidth);
    setHeight(newHeight);

    // Recalculate dailies file size if not locked
    if (!isDailiesFileSizeLocked) {
      calculateDailiesFileSize();
    }
  };

  const handleHeightChange = (e) => {
    const newHeight = parseInt(e.target.value) || 0;
    let newWidth = dailiesData.width; // Initialize newWidth with current width
    
    // If using Match, calculate based on camera aspect ratio
    if (selectedRatio === 'Match' && calcData.resolution) {
      const resolutionMatch = calcData.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
      if (resolutionMatch) {
        const [, cameraWidth, cameraHeight] = resolutionMatch;
        const cameraAspect = parseInt(cameraWidth) / parseInt(cameraHeight);
        newWidth = Math.round(newHeight * cameraAspect);
      }
    } else if (selectedRatio !== 'Custom') {
      // Calculate based on selected aspect ratio
      const aspectRatio = parseRatio(selectedRatio);
      if (!isNaN(aspectRatio)) {
        newWidth = Math.round(newHeight * aspectRatio);
      }
    }

    setDailiesData(prev => ({
      ...prev,
      width: newWidth,
      height: newHeight
    }));

    // Update UI states
    setWidth(newWidth);
    setHeight(newHeight);

    // Recalculate dailies file size if not locked
    if (!isDailiesFileSizeLocked) {
      calculateDailiesFileSize();
    }
  };

  const handleWidthBlur = () => {
    const newWidth = parseInt(width);
    if (!isNaN(newWidth)) {
      setWidth(newWidth);
      if (pinnedField === 'height') {
        const newRatio = newWidth / height;
        updateRatio(newRatio);
      } else {
        const newHeight = roundToEven(newWidth / parseRatio(selectedRatio));
        setHeight(newHeight);
        setResolution(`${newWidth} x ${newHeight}`);
      }
    } else {
      setWidth(1920);
    }
  };

  const handleHeightBlur = () => {
    const newHeight = parseInt(height);
    if (!isNaN(newHeight)) {
      setHeight(newHeight);
      if (pinnedField === 'width') {
        const newRatio = width / newHeight;
        updateRatio(newRatio);
      } else {
        const newWidth = roundToEven(newHeight * parseRatio(selectedRatio));
        setWidth(newWidth);
        setResolution(`${newWidth} x ${newHeight}`);
      }
    } else {
      setHeight(1080);
    }
  };

  const handleWidthKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const handleHeightKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const handleWidthWheel = (e) => {
    e.preventDefault();
    if (pinnedField) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const isLeftHalf = x < rect.width / 2;
    const scrollAmount = -e.deltaY;
    const baseChange = isLeftHalf ? 20 : 2;
    const changeAmount = Math.abs(scrollAmount) > 50 
      ? baseChange * Math.abs(scrollAmount / 50) 
      : baseChange;
    const delta = Math.sign(scrollAmount);

    const newWidth = roundToEven(Math.max(2, dailiesData.width + delta * changeAmount));
    
    // Update width and recalculate height based on aspect ratio
    handleWidthChange({ target: { value: newWidth } });
  };

  const handleHeightWheel = (e) => {
    e.preventDefault();
    if (pinnedField) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const isLeftHalf = x < rect.width / 2;
    const scrollAmount = -e.deltaY;
    const baseChange = isLeftHalf ? 20 : 2;
    const changeAmount = Math.abs(scrollAmount) > 50 
      ? baseChange * Math.abs(scrollAmount / 50) 
      : baseChange;
    const delta = Math.sign(scrollAmount);

    const newHeight = roundToEven(Math.max(2, dailiesData.height + delta * changeAmount));
    
    // Update height and recalculate width based on aspect ratio
    handleHeightChange({ target: { value: newHeight } });
  };

  const updateRatio = (newRatio) => {
    const formattedRatio = formatRatio(newRatio);
    if (!defaultAspectRatios.includes(formattedRatio) && !customRatios.includes(formattedRatio)) {
      setCustomRatios(prevRatios => {
        const newRatios = [...prevRatios, formattedRatio];
        return newRatios.sort(compareRatios);
      });
    }
    setSelectedRatio(formattedRatio);
    setCustomRatio(formattedRatio);
  };

  const handlePinClick = (field) => {
    if (pinnedField === field) {
      // Unpin width/height only
      setPinnedField(null);
      setIsWidthLocked(false);
      setIsHeightLocked(false);
    } else {
      // Pin width/height only
      setPinnedField(field);
      setIsWidthLocked(field === 'width');
      setIsHeightLocked(field === 'height');
      
      // Don't affect other locks
      // Remove these lines that were affecting other locks
      // setIsDurationLocked(false);
      // setIsFileSizeLocked(false);
      // setIsDailiesFileSizeLocked(false);
      // setCalculationMode(null);
    }
  };

  const handleBlur = () => {
    calculateDimensions();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      calculateDimensions();
    }
  };



  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (isMobile) {
      disableScroll();
      return () => enableScroll();
    }
  }, [isMobile]);


  const calculateDuration = (data = calcData, fileSizeInput) => {
    if (!data) {
      console.error('Invalid data for duration calculation');
      return "00:00:00:01"; // Ensure at least 1 frame
    }

    // if (!data || !data.fileSize || !data.fileSizeUnit) {
    //   console.error('Invalid data for duration calculation');
    //   return "00:00:00:01"; // Ensure at least 1 frame
    // }

    const selectedCodec = codecs.find(codec => codec.name === data.codec);
    if (!selectedCodec) {
      console.error('Invalid codec');
      return "00:00:00:01";
    }

    const datarate = selectedCodec.datarate;
    const resolutionMatch = data.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
    let resolutionValue;
    if (resolutionMatch) {
      const [, width, height] = resolutionMatch;
      resolutionValue = parseInt(width) * parseInt(height);
    } else {
      console.error('Invalid resolution format');
      return "00:00:00:01";
    }

    const fps = parseFloat(data.framerate);
    const parsedSize = parseFileSize(fileSizeInput || `${data.fileSize} ${fileSizeUnit}`);
    if (!parsedSize) {
      console.error('Invalid file size');
      return "00:00:00:01"; // Ensure at least 1 frame
    }

    // Unit multipliers for file size calculations
    const unitMultiplier = {
      'B': 1,
      'KB': 1024,
      'MB': 1024 * 1024,
      'GB': 1024 * 1024 * 1024,
      'TB': 1024 * 1024 * 1024 * 1024,
      'PB': 1024 * 1024 * 1024 * 1024 * 1024
    };

    const size_in_bytes = parseFloat(parsedSize.size) * unitMultiplier[parsedSize.unit];

    const bytes_per_second = datarate * resolutionValue * fps;

    let total_seconds = size_in_bytes / (bytes_per_second * 1024 * 1024 / 8);

    console.log("Calculated total seconds:", total_seconds); // Debug log

    // Ensure the total_seconds is at least enough for one frame
    if (total_seconds < 1 / fps) {
      total_seconds = 1 / fps;
    }

    const hours = Math.floor(total_seconds / 3600);
    total_seconds %= 3600;
    const minutes = Math.floor(total_seconds / 60);
    total_seconds %= 60;
    const seconds = Math.floor(total_seconds);
    const frames = Math.round((total_seconds - seconds) * fps);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
  };

  // State variables
  const [calcData, setCalcData] = useState({
    duration: '01:00:00:00',
    brand: 'ARRI',
    camera: 'ALEXA 35',
    resolution: '4.6K 3:2 Open Gate (4608 x 3164)',
    framerate: '23.976',
    codec: 'ARRIRAW',
    fileSize: '1.91 TB',
  });

  const [durationInput, setDurationInput] = useState("01:00:00:00");
  const [fileSizeUnit, setFileSizeUnit] = useState("GB");
  const [copyStatus, setCopyStatus] = useState("");
  const [copiedLogIndex, setCopiedLogIndex] = useState(null);
  const [copyDurationText, setCopyDurationText] = useState(<SlDocs />);
  const [logs, setLogs] = useState([]);
  const [copyFileSizeText, setCopyFileSizeText] = useState(<SlDocs />);
  // const [calculationMode, setCalculationMode] = useState(null); // 'duration' or 'fileSize'
  const [fileSizeInput, setFileSizeInput] = useState("1.91 TB");
  // const [logButtonText, setLogButtonText] = useState(<SlNote />);
  // const [pinnedField, setPinnedField] = useState(null); // 'duration', 'fileSize', or null
  const [isDurationLocked, setIsDurationLocked] = useState(false);
  const [isFileSizeLocked, setIsFileSizeLocked] = useState(false);
  // const [shareButtonText, setShareButtonText] = useState(<SlLink />);
  // const [resetButtonText, setResetButtonText] = useState(<SlRefresh />);
  const [previousResolution, setPreviousResolution] = useState("UHD (3840 x 2160)");
  const [availableCameras, setAvailableCameras] = useState([]);
  const [availableResolutions, setAvailableResolutions] = useState([]);
  const [availableFramerates, setAvailableFramerates] = useState([]);
  const [availableCodecs, setAvailableCodecs] = useState([]);

  // Refs for DOM elements
  // const resolutionRef = useRef(null);
  const codecRef = useRef(null);
  const durationRef = useRef(null);
  const framerateRef = useRef(null);
  const fileSizeRef = useRef(null);

  const logRef = useRef(null);

  // Convert resolutions object to array for select options
  const resolutionOptions = Object.keys(cameraResolutions);

  // Get resolution string for display
  let resolutionString;
  if (calcData.resolution === 'Custom') {
    resolutionString = calcData.customResolution;
  } else {
    const match = calcData.resolution.match(/\((\d+\s*x\s*\d+)\)/);
    resolutionString = match ? match[1].replace(/\s+/g, '') : calcData.resolution;
  }

  // Ref for previous calcData
  const prevCalcData = useRef(calcData);

  // Effect to check if the device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);


  // Update the handleDurationPinClick function
  const handleDurationPinClick = () => {
    if (calculationMode === 'duration') {
      // Unpin all if duration is already pinned
      setIsDurationLocked(false);
      setIsFileSizeLocked(false);
      setIsDailiesFileSizeLocked(false);
      setCalculationMode(null);
    } else {
      // Pin duration and unpin others
      setIsDurationLocked(true);
      setIsFileSizeLocked(false);
      setIsDailiesFileSizeLocked(false);
      setCalculationMode('duration');
    }
  };

  // Update the handleFileSizePinClick function
  const handleFileSizePinClick = () => {
    if (calculationMode === 'fileSize') {
      // Unpin all if file size is already pinned
      setIsDurationLocked(false);
      setIsFileSizeLocked(false);
      setIsDailiesFileSizeLocked(false);
      setCalculationMode(null);
    } else {
      // Pin file size and unpin others
      setIsDurationLocked(false);
      setIsFileSizeLocked(true);
      setIsDailiesFileSizeLocked(false);
      setCalculationMode('fileSize');
    }
  };

  useEffect(() => {
    const cameras = getCamerasByBrand(calcData.brand);
    setAvailableCameras(cameras);
    
    if (!cameras.includes(calcData.camera)) {
      const newCamera = cameras[0] || '';
      setCalcData(prev => ({ 
        ...prev, 
        camera: newCamera,
        resolution: '', // Reset resolution
        codec: '',      // Reset codec
        framerate: ''   // Reset framerate
      }));
    }
  }, [calcData.brand]);

  useEffect(() => {
    if (calcData.camera) {
      const resolutions = getResolutionsForCamera(calcData.camera);
      setAvailableResolutions(resolutions); // getResolutionsForCamera already returns an array
      
      if (!resolutions.includes(calcData.resolution)) {
        const newResolution = resolutions[0] || '';
        setCalcData(prev => ({ 
          ...prev, 
          resolution: newResolution,
          codec: '',      // Reset codec
          framerate: ''   // Reset framerate
        }));
      }
    }
  }, [calcData.camera]);

  useEffect(() => {
    if (calcData.camera && calcData.cameraResolution) {
      const codecs = getCodecsForCameraAndResolution(calcData.camera, calcData.cameraResolution);
      setAvailableCodecs(codecs);
      
      if (!codecs.includes(calcData.codec)) {
        const newCodec = codecs[0] || '';
        setCalcData(prev => ({ 
          ...prev, 
          codec: newCodec,
          framerate: '' // Reset framerate
        }));
      } else {
        updateFramerateForCodec(calcData.camera, calcData.cameraResolution, calcData.codec);
      }
    }
  }, [calcData.camera, calcData.cameraResolution]);

  useEffect(() => {
    if (calcData.camera && calcData.cameraResolution && calcData.codec) {
      updateFramerateForCodec(calcData.camera, calcData.cameraResolution, calcData.codec);
    }
  }, [calcData.camera, calcData.cameraResolution, calcData.codec]);

  const updateFramerateForCodec = (camera, resolution, codec) => {
    const framerates = getFrameratesForCamera(camera, resolution, codec);
    setAvailableFramerates(framerates);
    
    if (!framerates.includes(calcData.framerate)) {
      const defaultFramerate = '23.976';
      const newFramerate = framerates.includes(defaultFramerate) ? defaultFramerate : framerates[0] || '';
      setCalcData(prev => ({ ...prev, framerate: newFramerate }));
    }
  };


  // Modify the handleInputChange function to handle brand and camera changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'duration') {
      setDurationInput(value);
    } else if (name === 'fileSize') {
      setFileSizeInput(value);
    } else if (['resolution', 'codec', 'framerate', 'brand', 'camera'].includes(name)) {
      setCalcData(prevData => ({
        ...prevData,
        [name]: value
      }));
      
      // If brand or camera changes, reset dependent fields
      if (name === 'brand') {
        setCalcData(prevData => ({
          ...prevData,
          camera: '',
          resolution: '',
          codec: '',
          framerate: ''
        }));
      } else if (name === 'camera') {
        setCalcData(prevData => ({
          ...prevData,
          resolution: '',
          codec: '',
          framerate: ''
        }));
      }
      
      // Recalculate based on the current pin state or duration by default
      if (calculationMode === 'fileSize') {
        // If file size is pinned, recalculate duration
        const duration = calculateDuration({ ...calcData, [name]: value, fileSize: parseFloat(fileSizeInput.split(' ')[0]) }, fileSizeInput);
        setDurationInput(duration);
        setCalcData(prevData => ({
          ...prevData,
          duration: duration,
          [name]: value
        }));
      } else if (!calculationMode) { // When nothing is pinned
        // Recalculate file size based on current duration
        const { size, unit } = calculateFileSize();
        setFileSizeInput(`${size} ${unit}`);
        setCalcData(prevData => ({
          ...prevData,
          fileSize: size,
          [name]: value
        }));
        setFileSizeUnit(unit);
      }
    }
  };

  // Function to format duration
  const formatDuration = (value) => {
    const parts = value.split(':').filter(part => part !== '');
    while (parts.length < 4) {
      parts.unshift('00');
    }
    return parts.join(':');
  };

  // Handler for duration focus
  const handleDurationFocus = () => {
    if (!isDurationLocked) {
      setDurationInput('');
    }
  };

  // Function to parse duration input
  const parseDuration = (input, framerate) => {
    // Replace all periods with ':00' to ensure they become two zeros
    input = input.replace(/\./g, ':00');

    if (!input || input.trim() === '') {
      return new Timecode(0, 0, 0, 0, framerate).toString();
    }

    input = input.trim().toLowerCase();

    // Handle single number input
    if (/^\d+$/.test(input)) {
      let digits = input;
      let frames, seconds, minutes, hours;

      if (framerate >= 1000) {
        frames = parseInt(digits.slice(-4), 10);
        digits = digits.slice(0, -4).padStart(6, '0');
      } else if (framerate >= 100) {
        frames = parseInt(digits.slice(-3), 10);
        digits = digits.slice(0, -3).padStart(6, '0');
      } else {
        frames = parseInt(digits.slice(-2), 10);
        digits = digits.slice(0, -2).padStart(6, '0');
      }

      seconds = parseInt(digits.slice(-2), 10);
      minutes = parseInt(digits.slice(-4, -2), 10);
      hours = parseInt(digits.slice(0, -4), 10);

      // Adjust for overflow
      if (frames >= framerate) {
        seconds += Math.floor(frames / framerate);
        frames %= framerate;
      }
      if (seconds >= 60) {
        minutes += Math.floor(seconds / 60);
        seconds %= 60;
      }
      if (minutes >= 60) {
        hours += Math.floor(minutes / 60);
        minutes %= 60;
      }

      // Ensure at least 1 frame
      const totalFrames = Math.max(1, (hours * 3600 + minutes * 60 + seconds) * framerate + frames);
      
      return Timecode.fromFrames(totalFrames, framerate).toString();
    }

    // Handle input with colons after replacing periods
    if (/^\d+([:]\d+)*$/.test(input)) {
      const parts = input.split(':').map(Number);
      while (parts.length < 4) parts.unshift(0);
      let [hours, minutes, seconds, frames] = parts;

      // Adjust for overflow
      if (frames >= framerate) {
        seconds += Math.floor(frames / framerate);
        frames %= framerate;
      }
      if (seconds >= 60) {
        minutes += Math.floor(seconds / 60);
        seconds %= 60;
      }
      if (minutes >= 60) {
        hours += Math.floor(minutes / 60);
        minutes %= 60;
      }

      // Ensure at least 1 frame
      const totalFrames = Math.max(1, (hours * 3600 + minutes * 60 + seconds) * framerate + frames);
      
      return Timecode.fromFrames(totalFrames, framerate).toString();
    }

    // Define regex patterns for different time units
    const patterns = {
      week: /(\d+)\s*(?:w|wk|wks|week|weeks)/i,
      day: /(\d+)\s*(?:d|dy|dys|day|days)/i,
      hour: /(\d+)\s*(?:h|hr|hrs|hour|hours)/i,
      minute: /(\d+)\s*(?:m|min|mins|minute|minutes)/i,
      second: /(\d+)\s*(?:s|sec|secs|second|seconds)/i,
      frame: /(\d+)\s*(?:f|fr|frm|frms|frame|frames)/i
    };

    let totalFrames = 0;

    Object.entries(patterns).forEach(([unit, pattern]) => {
      const match = input.match(pattern);
      if (match) {
        const value = parseInt(match[1]);
        switch (unit) {
          case 'week': totalFrames += value * 7 * 24 * 3600 * framerate; break;
          case 'day': totalFrames += value * 24 * 3600 * framerate; break;
          case 'hour': totalFrames += value * 3600 * framerate; break;
          case 'minute': totalFrames += value * 60 * framerate; break;
          case 'second': totalFrames += value * framerate; break;
          case 'frame': totalFrames += value; break;
        }
      }
    });

    if (totalFrames === 0) {
      // If no valid time units were found, try parsing as a single number (assume seconds)
      const totalSeconds = parseFloat(input);
      if (!isNaN(totalSeconds)) {
        totalFrames = Math.round(totalSeconds * framerate);
      } else {
        throw new Error("Invalid duration format");
      }
    }

    return Timecode.fromFrames(totalFrames, framerate).toString();
  };

  const handleShare = () => {
    const shareUrl = generateShareUrl();

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(shareUrl)
        .then(handleShareSuccess)
        .catch(handleShareError);
    } else if (navigator.share) {
      navigator.share({
        title: 'Dailies Calculation',
        text: 'Check out this calculation',
        url: shareUrl,
      })
        .then(handleShareSuccess)
        .catch(handleShareError);
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = shareUrl;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        handleShareSuccess();
      } catch (err) {
        handleShareError(err);
      }
      document.body.removeChild(textArea);
    }
  };

  const handleShareSuccess = () => {
    setShareButtonText(<SlLike />);
    setTimeout(() => setShareButtonText(<SlLink />), 500);
  };

  const handleShareError = (error) => {
    console.error('Failed to copy or share: ', error);
  };

  const handleShareLog = () => {
    const shareUrl = generateShareUrl();

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(shareUrl)
        .catch(handleShareLogError);
    } else if (navigator.share) {
      navigator.share({
        title: 'Duration to File Size Calculation',
        text: 'Check out this calculation',
        url: shareUrl,
      })
        .then(handleShareLogSuccess)
        .catch(handleShareLogError);
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = shareUrl;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        handleShareLogError(err);
      }
      document.body.removeChild(textArea);
    }
  };

  const handleShareLogError = (error) => {
    console.error('Failed to copy or share: ', error);
  };

  const generateShareUrl = () => {
    const params = new URLSearchParams({
      duration: calcData.duration,
      brand: calcData.brand,
      camera: calcData.camera,
      resolution: calcData.resolution,
      codec: calcData.codec,
      framerate: calcData.framerate,
      fileSize: fileSizeInput
    });
    return `${window.location.origin}${window.location.pathname}?${params.toString()}\r\n`;
  };


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('duration')) {
      const newCalcData = {
        duration: params.get('duration') || '01:00:00:00',
        brand: params.get('brand') || 'ARRI',
        camera: params.get('camera') || 'ALEXA 35',
        resolution: params.get('resolution') || '4.6K 3:2 Open Gate (4608 x 3164)',
        codec: params.get('codec') || 'ARRIRAW',
        framerate: params.get('framerate') || '23.976',
      };
      setCalcData(newCalcData);
      setDurationInput(params.get('duration') || '01:00:00:00');
      setFileSizeInput(params.get('fileSize') || '1.91 TB');
    }
  }, []);

  // Handler for duration blur event
  const handleDurationBlur = () => {
    if (durationInput.trim() === '') {
      setDurationInput('');
      if (!isDurationLocked) {
        setCalcData(prevData => ({ ...prevData, duration: '' }));
      }
    } else {
      try {
        const parsedDuration = parseDuration(durationInput, parseFloat(calcData.framerate));
        setDurationInput(parsedDuration);
        if (!isDurationLocked) {
          setCalcData(prevData => ({
            ...prevData,
            duration: parsedDuration
          }));
          // Recalculate file size if needed
          if (!isFileSizeLocked) {
            const { size, unit } = calculateFileSize();
            setFileSizeInput(`${size} ${unit}`);
            setFileSizeUnit(unit);
          }
        }
      } catch (error) {
        console.error("Invalid duration format");
        setDurationInput('');
        if (!isDurationLocked) {
          setCalcData(prevData => ({ ...prevData, duration: '' }));
        }
      }
    }
  };

  // Handler for duration key press
  const handleDurationKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!isDurationLocked) {
        try {
          const parsedDuration = parseDuration(durationInput, parseFloat(calcData.framerate));
          setDurationInput(parsedDuration);
          setCalcData(prev => ({
            ...prev,
            duration: parsedDuration
          }));
          // Recalculate file size based on the parsed duration
          if (!isFileSizeLocked) {
            const { size, unit } = calculateFileSize({ ...calcData, duration: parsedDuration });
            setFileSizeInput(`${size} ${unit}`);
            setCalcData(prevData => ({
              ...prevData,
              fileSize: size
            }));
            setFileSizeUnit(unit);
          }
        } catch (error) {
          console.error("Invalid duration format");
          // Optionally, notify the user or revert to the previous valid duration
        }
      }
    }
  };

  const handleWheel = (e, options, currentValue, setterFunction) => {
    e.preventDefault();
    const index = options.indexOf(currentValue);
    const sensitivity = 0.025;
    const scrollAmount = -e.deltaY * sensitivity;
    
    let newIndex = index + Math.sign(scrollAmount);
    newIndex = Math.max(0, Math.min(newIndex, options.length - 1));
    
    if (newIndex !== index) {
      setterFunction(options[newIndex]);
    }
  };

  const handleDurationWheel = (e) => {
    e.preventDefault();
    if (isDurationLocked) return; // Immediately return if duration is locked

    const sensitivity = 0.025;
    const scrollAmount = -e.deltaY * sensitivity; // Note the negative sign here
    
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    
    let [hours, minutes, seconds, frames] = calcData.duration.split(':').map(Number);
    const framerate = parseFloat(calcData.framerate);
    
    if (x < width * 0.25) {
      // Scrolling hours
      hours += Math.sign(scrollAmount);
      hours = Math.max(0, hours);
    } else if (x < width * 0.5) {
      // Scrolling minutes
      minutes += Math.sign(scrollAmount);
      if (minutes < 0) {
        if (hours > 0) {
          hours--;
          minutes = 59;
        } else {
          minutes = 0;
        }
      } else if (minutes >= 60) {
        hours++;
        minutes = 0;
      }
    } else if (x < width * 0.75) {
      // Scrolling seconds
      seconds += Math.sign(scrollAmount);
      if (seconds < 0) {
        if (minutes > 0) {
          minutes--;
          seconds = 59;
        } else if (hours > 0) {
          hours--;
          minutes = 59;
          seconds = 59;
        } else {
          seconds = 0;
        }
      } else if (seconds >= 60) {
        minutes++;
        seconds = 0;
        if (minutes >= 60) {
          hours++;
          minutes = 0;
        }
      }
    } else {
      // Scrolling frames
      frames += Math.sign(scrollAmount);
      if (frames < 0) {
        if (seconds > 0) {
          seconds--;
          frames = Math.floor(framerate) - 1;
        } else if (minutes > 0) {
          minutes--;
          seconds = 59;
          frames = Math.floor(framerate) - 1;
        } else if (hours > 0) {
          hours--;
          minutes = 59;
          seconds = 59;
          frames = Math.floor(framerate) - 1;
        } else {
          frames = 0;
        }
      } else if (frames >= Math.floor(framerate)) {
        seconds++;
        frames = 0;
        if (seconds >= 60) {
          minutes++;
          seconds = 0;
          if (minutes >= 60) {
            hours++;
            minutes = 0;
          }
        }
      }
    }

    const newDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
    setDurationInput(newDuration);
    setCalcData(prevData => ({ ...prevData, duration: newDuration }));

    // Recalculate file size
    const { size, unit } = calculateFileSize();
    setFileSizeInput(`${size} ${unit}`);
    setCalcData(prevData => ({
      ...prevData,
      fileSize: size
    }));
    setFileSizeUnit(unit);
  };

  const calculateFileSize = (data = calcData) => {
    try {
      console.log("Starting file size calculation with data:", data);

      if (!data.duration || !data.resolution || !data.framerate || !data.codec) {
        console.log("Missing required data for calculation");
        return { size: '0', unit: 'B' };
      }

      const [hours, minutes, seconds, frames] = data.duration.split(':').map(Number);
      const totalFrames = (hours * 3600 + minutes * 60 + seconds) * parseFloat(data.framerate) + frames;
      console.log("Total frames:", totalFrames);

      let resolutionValue;
      const resolutionMatch = data.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
      if (resolutionMatch) {
        const [, width, height] = resolutionMatch;
        resolutionValue = parseInt(width) * parseInt(height);
        console.log("Resolution parsed from string:", width, "x", height);
      } else if (availableResolutions.length > 0) {
        // Get resolution from available resolutions
        const resolution = availableResolutions.find(r => r === data.resolution);
        if (!resolution) {
          throw new Error(`Invalid resolution: ${data.resolution}`);
        }
        const match = resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
        if (!match) {
          throw new Error(`Invalid resolution format: ${resolution}`);
        }
        const [, width, height] = match;
        resolutionValue = parseInt(width) * parseInt(height);
      } else {
        throw new Error('No valid resolution available');
      }
      console.log("Resolution value:", resolutionValue);

      const selectedCodec = codecs.find(codec => codec.name === data.codec);
      console.log("Selected codec:", selectedCodec);
      if (!selectedCodec) {
        throw new Error(`Invalid codec: ${data.codec}`);
      }

      const megapixels = resolutionValue;
      const datarate = selectedCodec.datarate;
      const fileSizeGB = (totalFrames * megapixels * datarate) / 8 / 1024;
      console.log("Calculated file size in GB:", fileSizeGB);

      const { size, unit } = formatFileSize(fileSizeGB);
      console.log("Formatted file size:", size, unit);

      return { size, unit };
    } catch (error) {
      console.error("Error calculating file size:", error.message);
      return { size: '0', unit: 'B' };
    }
  };

  // Helper function to format file size
  const formatFileSize = (sizeInGB) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let size = sizeInGB * 1024 * 1024 * 1024; // Convert GB to bytes
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return {
      size: size.toFixed(3), // Changed from 2 to 3 decimal places
      unit: units[unitIndex]
    };
  };

  // 8. Modify useEffect to handle recalculations based on calculationMode
  useEffect(() => {
    // Skip calculations if the resolution is set to 'Custom' and the custom resolution is not yet confirmed
    if (calcData.resolution === 'Custom' && !calcData.customResolution) {
      return;
    }

    recalculateBasedOnPinState();
  }, [
    calcData.resolution,
    calcData.codec,
    calcData.framerate,
    calcData.duration,
    calcData.fileSize,
    isDurationLocked,
    isFileSizeLocked,
    calculationMode
  ]);

  useEffect(() => {
    const resolutionElement = resolutionRef.current;
    const framerateElement = framerateRef.current;
    const codecElement = codecRef.current;

    const elements = [
      { ref: resolutionElement, handler: handleResolutionWheel },
      { ref: framerateElement, handler: handleFramerateWheel },
      { ref: codecElement, handler: handleCodecWheel },
    ];

    elements.forEach(({ ref, handler }) => {
      if (ref) {
        ref.addEventListener('wheel', handler, { passive: false });
      }
    });

    return () => {
      elements.forEach(({ ref, handler }) => {
        if (ref) {
          ref.removeEventListener('wheel', handler);
        }
      });
    };
  }, [calcData.resolution, calcData.framerate, calcData.codec]);

  useEffect(() => {
    const durationElement = durationRef.current;

    const wheelHandler = (e) => {
      if (!isDurationLocked) {
        handleDurationWheel(e);
      }
    };

    if (durationElement) {
      durationElement.addEventListener('wheel', wheelHandler, { passive: false });
    }

    return () => {
      if (durationElement) {
        durationElement.removeEventListener('wheel', wheelHandler);
      }
    };
  }, [calcData.duration, calcData.framerate, isDurationLocked]);

  const handleFileSizeClick = () => {
    if (isMobile) return;

    const fileSizeText = `${calcData.fileSize} ${fileSizeUnit}`;
    navigator.clipboard.writeText(fileSizeText).then(() => {
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  // Find and replace the handleLogClick function with this updated version
  const handleLogClick = () => {
    // Calculate total combined size
    const cameraSize = parseFileSize(fileSizeInput);
    const dailiesSize = parseFileSize(dailiesFileSize);
    
    let totalSizeInBytes = 0;
    
    // Convert both sizes to bytes for accurate addition
    if (cameraSize) {
      const unitMultiplier = {
        'B': 1,
        'KB': 1024,
        'MB': 1024 * 1024,
        'GB': 1024 * 1024 * 1024,
        'TB': 1024 * 1024 * 1024 * 1024,
        'PB': 1024 * 1024 * 1024 * 1024 * 1024
      };
      totalSizeInBytes += parseFloat(cameraSize.size) * unitMultiplier[cameraSize.unit];
    }
    
    if (dailiesSize) {
      const unitMultiplier = {
        'B': 1,
        'KB': 1024,
        'MB': 1024 * 1024,
        'GB': 1024 * 1024 * 1024,
        'TB': 1024 * 1024 * 1024 * 1024,
        'PB': 1024 * 1024 * 1024 * 1024 * 1024
      };
      totalSizeInBytes += parseFloat(dailiesSize.size) * unitMultiplier[dailiesSize.unit];
    }

    // Convert total bytes back to appropriate unit
    const { size: totalSize, unit: totalUnit } = formatFileSize(totalSizeInBytes / (1024 * 1024 * 1024)); // Convert to GB first

    const logEntry = 
      `${calcData.brand} ${calcData.camera} - ${calcData.resolution} ${calcData.codec} @ ${calcData.framerate}fps ${calcData.duration} = ${fileSizeInput}\n` +
      `Dailies: ${width}x${height} ${dailiesData.codec} = ${dailiesFileSize}\n` +
      `Total Data: ${totalSize} ${totalUnit}\r\n`;

    // Add the log entry
    addLog(logEntry);

    // Visual feedback
    setLogButtonText(<SlLike />);
    setTimeout(() => setLogButtonText(<SlNote />), 500);

    // Try to copy to clipboard
    const copyToClipboard = async (text) => {
      try {
        // First try the modern clipboard API
        if (navigator.clipboard && window.isSecureContext) {
          await navigator.clipboard.writeText(text);
          return;
        }

        // Fallback to the older execCommand method
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed';
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        
        try {
          document.execCommand('copy');
          textArea.remove();
        } catch (err) {
          console.error('Failed to copy using execCommand:', err);
          textArea.remove();
        }
      } catch (err) {
        console.error('Failed to copy text:', err);
      }
    };

    // Attempt to copy
    copyToClipboard(logEntry);
  };

  const copyLogToClipboard = (log, index) => {
    if (isMobile) return; 

    const logWithLineBreak = `${log}\r\n`; // Add line break

    navigator.clipboard.writeText(logWithLineBreak).then(() => {
      setCopiedLogIndex(index);
      setTimeout(() => setCopiedLogIndex(null), 3000);
    }).catch(err => {
      console.error('Failed to copy log: ', err);
      setCopiedLogIndex(null);
    });
  };

  const handleBrandWheel = (e) => {
    handleWheel(
      e,
      brands,
      calcData.brand,
      (newBrand) => {
        setCalcData(prev => ({ ...prev, brand: newBrand }));
        recalculateBasedOnPinState();
      }
    );
  };

  const handleCameraWheel = (e) => {
    handleWheel(
      e,
      availableCameras,
      calcData.camera,
      (newCamera) => {
        setCalcData(prev => ({ ...prev, camera: newCamera }));
        recalculateBasedOnPinState();
      }
    );
  };

  const handleResolutionWheel = (e) => {
    handleWheel(
      e, 
      availableResolutions, 
      calcData.resolution || '', // Add default empty string
      (newResolution) => {
        if (newResolution) { // Add null check
          setCalcData(prev => ({ ...prev, resolution: newResolution }));
          recalculateBasedOnPinState(newResolution);
        }
      }
    );
  };

  const handleFramerateWheel = (e) => {
    handleWheel(
      e, 
      availableFramerates, 
      calcData.framerate, 
      (newFramerate) => {
        setCalcData(prev => ({ ...prev, framerate: newFramerate }));
        recalculateBasedOnPinState();
      }
    );
  };

  // Modify the handleCodecWheel function
  const handleCodecWheel = (e) => {
    e.preventDefault();
    if (availableCodecs.length === 0) return;

    const delta = Math.sign(e.deltaY);
    const currentIndex = availableCodecs.indexOf(calcData.codec);
    let newIndex = currentIndex + delta;

    // Ensure the new index is within bounds without looping
    newIndex = Math.max(0, Math.min(newIndex, availableCodecs.length - 1));

    // Only update if the index has changed
    if (newIndex !== currentIndex) {
      const newCodec = availableCodecs[newIndex];
      setCalcData(prev => ({ ...prev, codec: newCodec }));
      updateFramerateForCodec(calcData.camera, calcData.resolution, newCodec);
    }
  };

  // Modify the useEffect for codec ref
  useEffect(() => {
    const codecElement = codecRef.current;

    const wheelHandler = (e) => {
      handleCodecWheel(e);
    };

    if (codecElement) {
      codecElement.addEventListener('wheel', wheelHandler, { passive: false });
    }

    return () => {
      if (codecElement) {
        codecElement.removeEventListener('wheel', wheelHandler);
      }
    };
  }, [calcData.camera, calcData.resolution, calcData.codec, availableCodecs]);

  // Add a new useEffect to ensure codec is always valid
  useEffect(() => {
    if (availableCodecs.length > 0 && !availableCodecs.includes(calcData.codec)) {
      const newCodec = availableCodecs[0];
      setCalcData(prev => ({ ...prev, codec: newCodec }));
      updateFramerateForCodec(calcData.camera, calcData.resolution, newCodec);
    }
  }, [calcData.camera, calcData.resolution, calcData.codec, availableCodecs]);

  const handleDeleteLog = (index) => {
    setLogs(prevLogs => prevLogs.filter((_, i) => i !== index));
  };

  const defaultValues = {
    duration: "01:00:00:00",
    brand: 'ARRI',
    camera: 'ALEXA 35',
    resolution: '4.6K 3:2 Open Gate (4608 x 3164)',
    codec: 'ARRIRAW',
    framerate: "23.976",
    fileSize: '1.91 TB'
  };

  const handleReset = () => {
    // Unpin all fields
    setIsDurationLocked(false);
    setIsFileSizeLocked(false);
    setIsDailiesFileSizeLocked(false);
    setPinnedField(null);
    setIsWidthLocked(false);
    setIsHeightLocked(false);
    setCalculationMode(null);
    
    // Reset duration and file size related states
    setDurationInput("01:00:00:00");
    setFileSizeInput("1.91 TB");
    setFileSizeUnit("TB");
    
    // Reset dailies ratio
    setSelectedRatio("Match");
    setCustomRatio("");
    
    // Calculate initial height based on camera resolution
    const resolutionMatch = calcData.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
    if (resolutionMatch) {
      const [, cameraWidth, cameraHeight] = resolutionMatch;
      const cameraAspect = parseInt(cameraWidth) / parseInt(cameraHeight);
      const newHeight = Math.round(1920 / cameraAspect); // Using 1920 as default width
      
      // Set width and calculated height
      setWidth(1920);
      setHeight(newHeight);
      
      // Update dailiesData
      setDailiesData(prev => ({
        ...prev,
        width: 1920,
        height: newHeight,
        aspectRatio: 'Match',
        codec: 'Apple ProRes LT'
      }));
    } else {
      // Fallback to default values if no resolution match
      setWidth(1920);
      setHeight(1080);
      setDailiesData(prev => ({
        ...prev,
        width: 1920,
        height: 1080,
        aspectRatio: 'Match',
        codec: 'Apple ProRes LT'
      }));
    }
    
    // Update calcData with default values
    setCalcData(prevData => ({
      ...prevData,
      duration: "01:00:00:00"
    }));

    // Show reset confirmation
    setResetButtonText(<SlLike />);
    setTimeout(() => setResetButtonText(<SlRefresh />), 500);
  };

  useEffect(() => {
    if (isMobile) {
      disableScroll();
      return () => enableScroll();
    }
  }, [isMobile]);

  // 1. Update handleCopyDuration to remove navigator.clipboard.writeText
  const handleCopyDuration = (text, result) => {
    if (result) {
      setCopyDurationText(<SlLike />);
    } else {
      setCopyDurationText('Error');
    }
    setTimeout(() => setCopyDurationText(<SlDocs />), 500);
  };

  // 1. Update handleCopyFileSize to remove navigator.clipboard.writeText
  const handleCopyFileSize = (text, result) => {
    if (result) {
      setCopyFileSizeText(<SlLike />);
    } else {
      setCopyFileSizeText('Error');
    }
    setTimeout(() => setCopyFileSizeText(<SlDocs />), 500);
  };

  // 3. Update handleTouchStart to allow default behavior for select fields
  const handleTouchStart = useCallback((e, field) => {
    // Return early if the field is locked
    if ((field === 'fileSize' && isFileSizeLocked) || 
        (field === 'duration' && isDurationLocked) ||
        (field === 'width' && pinnedField) ||
        (field === 'height' && pinnedField)) return;
    
    e.preventDefault();
    const touch = e.touches[0];
    const startY = touch.clientY;
    const rect = e.target.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    const fieldWidth = rect.width;
    let lastY = startY;
    let startValue;

    if (field === 'fileSize') {
      const parsedSize = parseFileSize(fileSizeInput);
      startValue = parsedSize ? parseFloat(parsedSize.size) : 0;
    } else if (field === 'duration') {
      const timecode = Timecode.parse(durationInput, parseFloat(calcData.framerate));
      startValue = timecode.totalFrames();
    }

    const handleTouchMove = (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const diffY = lastY - currentY;
      const isLeftHalf = touchX < fieldWidth / 2;
      const baseChange = isLeftHalf ? 20 : 2;
      const changeAmount = Math.abs(diffY) > 5 ? baseChange * Math.abs(diffY / 5) : baseChange;
      const delta = Math.sign(diffY);

      if (field === 'fileSize') {
        let change = diffY * 0.1; // Using original sensitivity
        if (isLeftHalf) change *= 10;
        let newSize = Math.max(0, startValue - change);
        newSize = Math.round(newSize * 100) / 100;

        const newFileSizeInput = `${newSize} ${fileSizeUnit}`;
        setFileSizeInput(newFileSizeInput);
        setCalcData(prevData => ({
          ...prevData,
          fileSize: newSize.toString()
        }));

        // Recalculate duration
        const duration = calculateDuration({ ...calcData, fileSize: newSize.toString() }, newFileSizeInput);
        setDurationInput(duration);
        setCalcData(prevData => ({
          ...prevData,
          duration: duration
        }));
      } else if (field === 'duration') {
        const framerate = parseFloat(calcData.framerate);
        let change = Math.round(diffY * 0.1);
        if (isLeftHalf) change *= 10;

        let newTotalFrames = Math.max(1, startValue - change);
        const newTimecode = Timecode.fromFrames(newTotalFrames, framerate);
        const newDuration = newTimecode.toString();

        setDurationInput(newDuration);
        setCalcData(prevData => ({
          ...prevData,
          duration: newDuration
        }));

        // Recalculate file size
        const { size, unit } = calculateFileSize({ ...calcData, duration: newDuration });
        setFileSizeInput(`${size} ${unit}`);
        setCalcData(prevData => ({
          ...prevData,
          fileSize: size
        }));
        setFileSizeUnit(unit);
      }
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  }, [calcData, fileSizeInput, durationInput, isFileSizeLocked, isDurationLocked, calculateDuration, calculateFileSize, fileSizeUnit]);

  // Updated parseFileSize function to handle more input permutations
  const parseFileSize = (input) => {
    // Remove any whitespace and convert to lowercase
    const cleanInput = input.replace(/\s+/g, '').toLowerCase();
  
    // Define unit patterns
    const unitPatterns = {
      'pb': ['p', 'ps', 'pb', 'pbs', 'pe', 'pet', 'pets', 'peta', 'petas', 'petabyte', 'petabytes'],
      'tb': ['t', 'ts', 'tb', 'tbs', 'te', 'ter', 'ters', 'tera', 'teras', 'terabyte', 'terabytes'],
      'gb': ['g', 'gs', 'gb', 'gbs', 'ge', 'gig', 'gigs', 'giga', 'gigas', 'gigabyte', 'gigabytes'],
      'mb': ['m', 'ms', 'mb', 'mbs', 'me', 'meg', 'megs', 'mega', 'megas', 'megabyte', 'megabytes'],
      'kb': ['k', 'ks', 'kb', 'kbs', 'ki', 'kil', 'kils', 'kilo', 'kilos', 'kilobyte', 'kilobytes'],
      'b': ['b', 'bs', 'bi', 'by', 'byte', 'bytes']
    };
  
    // Create a regex pattern that matches any of the unit patterns
    const unitRegexPattern = Object.values(unitPatterns).flat().join('|');
    
    // Match the input against the pattern
    const match = cleanInput.match(new RegExp(`^(\\d+(?:\\.\\d+)?)(${unitRegexPattern})$`));
  
    if (match) {
      const size = parseFloat(match[1]);
      const unitLower = match[2];
  
      // Determine the standardized unit
      for (const [standardUnit, patterns] of Object.entries(unitPatterns)) {
        if (patterns.includes(unitLower)) {
          return { size, unit: standardUnit.toUpperCase() };
        }
      }
    }
  
    return null;
  };

  // Update handleFileSizeFocus to clear the input and show placeholder text
  const handleFileSizeFocus = () => {
    if (!isFileSizeLocked) {
      setFileSizeInput('');
    }
  };

  // Updated handleFileSizeBlur function to parse input and calculate duration
  const handleFileSizeBlur = () => {
    if (fileSizeInput.trim() === '') {
      setFileSizeInput('');
      if (!isFileSizeLocked) {
        setCalcData(prevData => ({ ...prevData, fileSize: '' }));
      }
    } else {
      const parsedSize = parseFileSize(fileSizeInput);
      if (parsedSize !== null) {
        const formattedSize = `${parsedSize.size} ${parsedSize.unit}`;
        setFileSizeInput(formattedSize);
        if (!isFileSizeLocked) {
          setCalcData(prevData => ({
            ...prevData,
            fileSize: parsedSize.size,
            fileSizeUnit: parsedSize.unit
          }));
          
          // Calculate duration based on the parsed file size
          if (!isDurationLocked) {
            const duration = calculateDuration({ ...calcData, 
              fileSize: parsedSize.size, 
              fileSizeUnit: parsedSize.unit 
            });
            setDurationInput(duration);
            setCalcData(prevData => ({
              ...prevData,
              duration: duration
            }));
          }
        }
      } else {
        console.error("Invalid file size format");
        // Optionally, revert to previous valid input or notify the user
      }
    }
  };

  // Updated handleFileSizeKeyPress to trigger blur on Enter key
  const handleFileSizeKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!isFileSizeLocked) {
        const parsedSize = parseFileSize(fileSizeInput);
        if (parsedSize !== null) {
          const formattedSize = `${parsedSize.size} ${parsedSize.unit}`;
          setFileSizeInput(formattedSize);
          setCalcData(prevData => ({
            ...prevData,
            fileSize: parsedSize.size,
            fileSizeUnit: parsedSize.unit
          }));
          
          // Calculate duration based on the parsed file size
          if (!isDurationLocked) {
            const duration = calculateDuration({ 
              ...calcData, 
              fileSize: parsedSize.size, 
              fileSizeUnit: parsedSize.unit 
            });
            setDurationInput(duration);
            setCalcData(prevData => ({
              ...prevData,
              duration: duration
            }));
          }
        }
      }
    } else {
      console.error("Invalid file size format");
      // Optionally, revert to previous valid input or notify the user
    }
  };

// Helper function to recalculate based on pin state
  const recalculateBasedOnPinState = (updatedResolution = calcData.resolution) => {
    const updatedCalcData = { ...calcData, resolution: updatedResolution };
    
    if (calculationMode === 'duration' && isDurationLocked) {
      // If duration is pinned, recalculate file size based on duration
      const { size, unit } = calculateFileSize(updatedCalcData);
      setFileSizeInput(`${size} ${unit}`);
      setCalcData(prevData => ({
        ...prevData,
        fileSize: size
      }));
      setFileSizeUnit(unit);
    } else if (calculationMode === 'fileSize' && isFileSizeLocked) {
      // If file size is pinned, recalculate duration based on file size
      const duration = calculateDuration(updatedCalcData, fileSizeInput);
      setDurationInput(duration);
      setCalcData(prevData => ({
        ...prevData,
        duration: duration
      }));
    } else {
      // If nothing is pinned, default to recalculating file size based on duration
      const { size, unit } = calculateFileSize(updatedCalcData);
      setFileSizeInput(`${size} ${unit}`);
      setCalcData(prevData => ({
        ...prevData,
        fileSize: size
      }));
      setFileSizeUnit(unit);
    }
  };

   // Updated handleFileSizeWheel to implement proportional scrolling across all units
  const handleFileSizeWheel = (e) => {
    e.preventDefault();
    if (isFileSizeLocked || isDurationLocked) return;

    const delta = Math.sign(-e.deltaY); // Positive for scroll up, negative for scroll down
    const changeFactor = 1.1; // 10% change per scroll

    let parsed = parseFileSize(fileSizeInput);
    if (!parsed) return;
    let { size, unit } = parsed;
    let newSize = size;

    // Apply proportional change based on scroll direction
    if (delta > 0) {
      newSize *= changeFactor;
    } else {
      newSize /= changeFactor;
    }

    // Define the order of units
    const unitsOrder = ['KB', 'MB', 'GB', 'TB', 'PB'];
    let currentIndex = unitsOrder.indexOf(unit);

    // Adjust unit if size exceeds current unit's boundaries
    while (newSize >= 1000 && currentIndex < unitsOrder.length - 1) {
      newSize /= 1000;
      currentIndex++;
      unit = unitsOrder[currentIndex];
    }

    while (newSize < 1 && currentIndex > 0) {
      newSize *= 1000;
      currentIndex--;
      unit = unitsOrder[currentIndex];
    }

    // Format the new size based on unit for consistency
    newSize = unit === 'KB' ? Math.round(newSize) : parseFloat(newSize.toFixed(2));

    const newFileSizeInput = `${newSize} ${unit}`;
    setFileSizeInput(newFileSizeInput);

    // Update calcData immediately
    const updatedCalcData = {
      ...calcData,
      fileSize: newSize.toString(),
      fileSizeUnit: unit
    };
    setCalcData(updatedCalcData);

    // Recalculate duration based on the new file size
    const duration = calculateDuration(updatedCalcData, newFileSizeInput);
    setDurationInput(duration);
    setCalcData(prevData => ({
      ...prevData,
      duration: duration
    }));
  };

  // Ensure handleFileSizeWheel is correctly referenced in useEffect
  useEffect(() => {
    const fileSizeElement = fileSizeRef.current;

    const wheelHandler = (e) => {
      if (!isFileSizeLocked) {
        handleFileSizeWheel(e);
      }
    };

    if (fileSizeElement) {
      fileSizeElement.addEventListener('wheel', wheelHandler, { passive: false });
      fileSizeElement.addEventListener('touchmove', wheelHandler, { passive: false });
    }

    return () => {
      if (fileSizeElement) {
        fileSizeElement.removeEventListener('wheel', wheelHandler);
        fileSizeElement.removeEventListener('touchmove', wheelHandler);
      }
    };
  }, [isFileSizeLocked, handleFileSizeWheel]);

  // 3. Update the input fields in the JSX to apply styles based on lock state
  // Modify the useEffect for mobile anchoring
  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        if (window.scrollY > 0) {
          window.scrollTo(0, 0);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobile]);

  

  // Update theme colors based on isDarkMode
  const bgColor = 'bg-white';
  const textColor = 'text-gray-900';
  const inputBgColor = 'bg-gray-50';
  const inputBorderColor = 'border-gray-300';
  const labelColor = 'text-gray-700';
  const buttonColor = 'bg-blue-500 hover:bg-blue-600';
  
  const commonInputClass = `w-full px-2 py-1 border rounded h-[36px] ${inputBgColor} ${inputBorderColor} ${textColor}`;
  const pinButtonStyle = `p-2 rounded h-[36px] w-[42px] flex items-center justify-center self-end mb-[2px] bg-gray-200 hover:bg-gray-300 border-2 ${
    calculationMode ? 'border-blue-500' : 'border-transparent'
  }`;

   // Function to get input style based on lock state
   const getInputStyle = (field) => {
    const baseStyle = "w-full px-2 py-1 border rounded h-[36px]";
    
    if (isDurationLocked || isFileSizeLocked || isDailiesFileSizeLocked) {
      // Locked state styles
      const lockedStyle = "bg-gray-200 text-gray-700 cursor-not-allowed pointer-events-none";
      
      // Add blue border if this field is the one that's controlling
      if ((field === 'duration' && calculationMode === 'duration') ||
          (field === 'fileSize' && calculationMode === 'fileSize') ||
          (field === 'dailiesFileSize' && calculationMode === 'dailiesFileSize')) {
        return `${baseStyle} ${lockedStyle} border-2 border-blue-500`;
      }
      
      return `${baseStyle} ${lockedStyle} border border-gray-400`;
    }
    
    // Default unlocked state
    return `${baseStyle} bg-gray-50 border border-gray-300 text-gray-900`;
  };



  useEffect(() => {
    setAvailableCameras(getCamerasByBrand(calcData.brand));
  }, [calcData.brand]);

  useEffect(() => {
    if (calcData.camera) {
      setAvailableResolutions(getResolutionsForCamera(calcData.camera));
      setAvailableFramerates(getFrameratesForCamera(calcData.camera));
    }
  }, [calcData.camera]);

  useEffect(() => {
    if (calcData.camera && calcData.resolution) {
      setAvailableCodecs(getCodecsForCameraAndResolution(calcData.camera, calcData.resolution));
    }
  }, [calcData.camera, calcData.resolution]);

  

  useEffect(() => {
    const elements = [
      { ref: brandRef, handler: handleBrandWheel },
      { ref: cameraRef, handler: handleCameraWheel },
      { ref: resolutionRef, handler: handleResolutionWheel },
      { ref: framerateRef, handler: handleFramerateWheel },
      { ref: codecRef, handler: handleCodecWheel },
      { ref: durationRef, handler: handleDurationWheel },
      { ref: ratioRef, handler: handleRatioWheel },
      { ref: fileSizeRef, handler: handleFileSizeWheel }
    ];

    elements.forEach(({ ref, handler }) => {
      if (ref?.current) {
        const wheelHandler = (e) => {
          if (document.activeElement !== ref.current) {
            e.stopPropagation();
            handler(e);
          }
        };
        ref.current.addEventListener('wheel', wheelHandler, { passive: true });
        return () => ref.current?.removeEventListener('wheel', wheelHandler);
      }
    });
  }, [calcData, availableCameras, availableResolutions, availableFramerates, availableCodecs]);

  // Add these new refs
  const brandRef = useRef(null);
  const cameraRef = useRef(null);

  



  const logResult = () => {
    const logEntry = `AR: ${selectedRatio} - ${width}x${height}`;
    addLog(logEntry);
    setLogButtonText(<SlLike />);
    setTimeout(() => setLogButtonText(<SlNote />), 2000);
  };

  const handleCopy = () => {
    setCopyButtonText(<SlLike />);
    setTimeout(() => setCopyButtonText(<SlDocs />), 2000);
  };


  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (isMobile) {
      disableScroll();
      return () => enableScroll();
    }
  }, [isMobile]);
  
  // Add this helper function to calculate GCD
  const getGCD = (a, b) => {
    a = Math.abs(a);
    b = Math.abs(b);
    while (b) {
      const temp = b;
      b = a % b;
      a = temp;
    }
    return a;
  };

  const formatRatio = (ratio) => {
    // Check if the input ratio matches any of the original custom ratios
    const matchingCustomRatio = customRatios.find(r => {
      const parsed = parseRatio(r);
      return Math.abs(parsed - ratio) < 0.0001;
    });
    
    if (matchingCustomRatio) {
      return matchingCustomRatio;
    }

    // For decimal inputs like 2.35, preserve as "2.35:1"
    if (Number.isFinite(ratio) && !Number.isInteger(ratio)) {
      return `${ratio.toFixed(2)}:1`;
    }

    // For integer ratios, use the GCD reduction
    const tolerance = 0.0001;
    for (let denominator = 1; denominator <= 16; denominator++) {
      const numerator = ratio * denominator;
      if (Math.abs(numerator - Math.round(numerator)) < tolerance) {
        let n = Math.round(numerator);
        let d = denominator;
        const gcd = getGCD(n, d);
        n = n / gcd;
        d = d / gcd;
        return `${n}:${d}`;
      }
    }

    return `${ratio.toFixed(2)}:1`;
  };



  // Add this function with the other calculation functions
  const recalculateBasedOnRatio = (ratio) => {
    const aspectRatio = parseRatio(ratio);
    if (!isNaN(aspectRatio)) {
      if (pinnedField === 'width') {
        const newHeight = roundToEven(width / aspectRatio);
        setHeight(newHeight);
        setResolution(`${width} x ${newHeight}`);
      } else if (pinnedField === 'height') {
        const newWidth = roundToEven(height * aspectRatio);
        setWidth(newWidth);
        setResolution(`${newWidth} x ${height}`);
      } else {
        // Default behavior: maintain width, adjust height
        const newHeight = roundToEven(width / aspectRatio);
        setHeight(newHeight);
        setResolution(`${width} x ${newHeight}`);
      }
    }
  };
  
  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        if (window.scrollY > 0) {
          window.scrollTo(0, 0);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobile]);

  // Replace the existing handleRatioWheel function with this optimized version
  const handleRatioWheel = (e) => {
    e.preventDefault();
    if (pinnedField) return;

    // Increase sensitivity for faster response
    const sensitivity = 0.05; // Increased from 0.025
    const scrollAmount = -e.deltaY * sensitivity;
    
    const ratios = [
      "Match",
      ...defaultAspectRatios
        .filter(r => r !== "Match" && r !== "Custom"),
      ...customRatios
    ];

    const currentIndex = ratios.indexOf(selectedRatio);
    const delta = Math.sign(scrollAmount);
    let newIndex = currentIndex + delta;
    newIndex = Math.max(0, Math.min(newIndex, ratios.length - 1));
    
    if (newIndex !== currentIndex) {
      const newRatio = ratios[newIndex];
      setSelectedRatio(newRatio);
      updateDimensionsForRatio(newRatio);
    }
  };

  // Add new helper function to handle dimension updates
  const updateDimensionsForRatio = (ratio) => {
    if (ratio === 'Match') {
      const resolutionMatch = calcData.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
      if (resolutionMatch) {
        const [, cameraWidth, cameraHeight] = resolutionMatch;
        const cameraAspect = parseInt(cameraWidth) / parseInt(cameraHeight);
        const newHeight = Math.round(dailiesData.width / cameraAspect);
        
        setHeight(newHeight);
        setDailiesData(prev => ({
          ...prev,
          height: newHeight,
          aspectRatio: 'Match'
        }));

        if (!isDailiesFileSizeLocked) {
          calculateDailiesFileSize();
        }
      }
    } else {
      recalculateBasedOnRatio(ratio);
    }
  };

  // Update the useEffect for the ratio wheel event listener
  useEffect(() => {
    const ratioElement = ratioRef.current;
    
    if (ratioElement) {
      const wheelHandler = (e) => {
        if (document.activeElement !== ratioElement) {
          handleRatioWheel(e);
        }
      };
      
      ratioElement.addEventListener('wheel', wheelHandler, { passive: false });
      return () => ratioElement.removeEventListener('wheel', wheelHandler);
    }
  }, [selectedRatio, calcData.resolution, width]);

  // Add handleCustomRatioKeyDown if it's not already defined
  const handleCustomRatioKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      processCustomRatio();
      // Move focus to the next element
      widthRef.current?.focus();
    }
  };

  // First, add new state variables near the top with other state declarations
  const [dailiesCodec, setDailiesCodec] = useState('ProRes 422 HQ');
  const [dailiesFileSize, setDailiesFileSize] = useState('');
  const [isDailiesFileSizeLocked, setIsDailiesFileSizeLocked] = useState(false);

  // Update the handleDailiesFileSizePinClick function
  const handleDailiesFileSizePinClick = () => {
    if (calculationMode === 'dailiesFileSize') {
      // Unpin all if dailies file size is already pinned
      setIsDurationLocked(false);
      setIsFileSizeLocked(false);
      setIsDailiesFileSizeLocked(false);
      setCalculationMode(null);
    } else {
      // Pin dailies file size and unpin others
      setIsDurationLocked(false);
      setIsFileSizeLocked(false);
      setIsDailiesFileSizeLocked(true);
      setCalculationMode('dailiesFileSize');
    }
  };

  // Dailies-related state
  const [dailiesData, setDailiesData] = useState({
    width: 1920,
    height: 1080,
    codec: 'Apple ProRes LT',  // Changed from 'ProRes 422 HQ' to 'Apple ProRes LT'
    fileSize: '',
    aspectRatio: 'Match'
  });

  // Add state for dailies aspect ratio handling
  const [customDailiesRatio, setCustomDailiesRatio] = useState("");
  const [customDailiesRatios, setCustomDailiesRatios] = useState([]);

  // Add dailies-specific state variables
  const [availableDailiesCodecs] = useState(dailiesCodecs.map(codec => codec.name));

  // Add function to handle aspect ratio matching
  const handleMatchAspectRatio = useCallback(() => {
    if (dailiesData.aspectRatio === 'Match' && calcData.resolution) {
      // Add null check and default to empty string
      const resolutionString = calcData.resolution || '';
      const resolutionMatch = resolutionString.match(/\((\d+)\s*x\s*(\d+)\)/);
      
      if (resolutionMatch) {
        const [, cameraWidth, cameraHeight] = resolutionMatch;
        const cameraAspect = parseInt(cameraWidth) / parseInt(cameraHeight);
        
        const newHeight = Math.round(dailiesData.width / cameraAspect);
        setDailiesData(prev => ({
          ...prev,
          height: newHeight
        }));
        setHeight(newHeight);
      }
    }
  }, [calcData.resolution, dailiesData.width, dailiesData.aspectRatio]);

  // Add effect to handle aspect ratio matching when camera resolution changes
  useEffect(() => {
    handleMatchAspectRatio();
  }, [calcData.resolution, handleMatchAspectRatio]);

  // Add function to calculate dailies file size
  const calculateDailiesFileSize = useCallback(() => {
    try {
      if (!calcData.duration || !calcData.framerate || !dailiesData.width || !dailiesData.height || !dailiesData.codec) {
        console.log("Missing required data for dailies calculation");
        return;
      }

      // Parse duration into frames
      const [hours, minutes, seconds, frames] = calcData.duration.split(':').map(Number);
      const framerate = parseFloat(calcData.framerate);
      const totalFrames = (hours * 3600 + minutes * 60 + seconds) * framerate + frames;
      
      // Calculate resolution value (total pixels)
      const resolutionValue = dailiesData.width * dailiesData.height;
      
      // Find the selected codec and its datarate
      const selectedCodec = dailiesCodecs.find(codec => codec.name === dailiesData.codec);
      if (!selectedCodec) {
        throw new Error(`Invalid dailies codec: ${dailiesData.codec}`);
      }

      // Calculate file size
      const datarate = selectedCodec.datarate;
      const fileSizeGB = (totalFrames * resolutionValue * datarate) / 8 / 1024;
      
      // Format the file size
      const { size, unit } = formatFileSize(fileSizeGB);
      
      // Update the dailies file size
      setDailiesData(prev => ({
        ...prev,
        fileSize: `${size} ${unit}`
      }));

      // Also update the dailiesFileSize state for the input field
      setDailiesFileSize(`${size} ${unit}`);

    } catch (error) {
      console.error("Error calculating dailies file size:", error.message);
      setDailiesData(prev => ({
        ...prev,
        fileSize: '0 B'
      }));
      setDailiesFileSize('0 B');
    }
  }, [calcData.duration, calcData.framerate, dailiesData.width, dailiesData.height, dailiesData.codec]);

  // Add an effect to trigger dailies file size calculation when relevant values change
  useEffect(() => {
    if (!isDailiesFileSizeLocked) {
      calculateDailiesFileSize();
    }
  }, [
    calcData.duration,
    calcData.framerate,
    dailiesData.width,
    dailiesData.height,
    dailiesData.codec,
    isDailiesFileSizeLocked,
    calculateDailiesFileSize
  ]);

  // Update the useEffect for camera resolution changes
  useEffect(() => {
    if (calcData.camera) {
      const resolutions = getResolutionsForCamera(calcData.camera);
      setAvailableResolutions(resolutions);
      
      if (!resolutions.includes(calcData.resolution)) {
        const newResolution = resolutions[0] || '';
        setCalcData(prev => ({ 
          ...prev, 
          resolution: newResolution,
          codec: '',      // Reset codec
          framerate: ''   // Reset framerate
        }));
      }

      // Update dailies dimensions based on camera resolution
      if (dailiesData.aspectRatio === 'Match') {
        const resolutionMatch = calcData.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
        if (resolutionMatch) {
          const [, cameraWidth, cameraHeight] = resolutionMatch;
          const cameraAspect = parseInt(cameraWidth) / parseInt(cameraHeight);
          
          // Keep the dailies width and adjust height to match camera aspect ratio
          const newHeight = Math.round(dailiesData.width / cameraAspect);
          setDailiesData(prev => ({
            ...prev,
            height: newHeight
          }));
          setHeight(newHeight); // Also update the height state

          // Recalculate dailies file size with new dimensions
          if (!isDailiesFileSizeLocked) {
            calculateDailiesFileSize();
          }
        }
      }
    }
  }, [calcData.camera, calcData.resolution, dailiesData.width, dailiesData.aspectRatio, isDailiesFileSizeLocked]);

  // Add these new refs near the top with other refs
  const dailiesCodecRef = useRef(null);
  const dailiesFileSizeRef = useRef(null);

  // Add these new handlers
  const handleDailiesCodecWheel = (e) => {
    handleWheel(
      e,
      availableDailiesCodecs,
      dailiesData.codec,
      (newCodec) => {
        setDailiesData(prev => ({ ...prev, codec: newCodec }));
        if (!isDailiesFileSizeLocked) {
          calculateDailiesFileSize();
        }
      }
    );
  };

  // Add handler for dailies ratio wheel
  const handleDailiesRatioWheel = (e) => {
    if (pinnedField) return;

    // Create ratios array without Custom, and ensure Match is at the top
    const ratios = [
      "Match",
      ...defaultAspectRatios
        .filter(r => r !== "Match" && r !== "Custom")
        .sort(compareRatios),
      ...customRatios.sort(compareRatios)
    ];

    const currentIndex = ratios.indexOf(selectedRatio);
    const delta = Math.sign(-e.deltaY);
    let newIndex = currentIndex + delta;
    newIndex = Math.max(0, Math.min(newIndex, ratios.length - 1));
    
    if (newIndex !== currentIndex) {
      const newRatio = ratios[newIndex];
      setSelectedRatio(newRatio);
      recalculateBasedOnRatio(newRatio);
    }
  };

  // Update the handleDailiesFileSizeWheel function
  const handleDailiesFileSizeWheel = (e) => {
    e.preventDefault();
    if (isDailiesFileSizeLocked) return;

    const delta = Math.sign(-e.deltaY);
    const changeFactor = 1.1;

    let parsed = parseFileSize(dailiesFileSize);
    if (!parsed) return;
    let { size, unit } = parsed;
    let newSize = size;

    if (delta > 0) {
      newSize *= changeFactor;
    } else {
      newSize /= changeFactor;
    }

    const unitsOrder = ['KB', 'MB', 'GB', 'TB', 'PB'];
    let currentIndex = unitsOrder.indexOf(unit);

    while (newSize >= 1000 && currentIndex < unitsOrder.length - 1) {
      newSize /= 1000;
      currentIndex++;
      unit = unitsOrder[currentIndex];
    }

    while (newSize < 1 && currentIndex > 0) {
      newSize *= 1000;
      currentIndex--;
      unit = unitsOrder[currentIndex];
    }

    newSize = unit === 'KB' ? Math.round(newSize) : parseFloat(newSize.toFixed(2));
    const newDailiesFileSize = `${newSize} ${unit}`;
    setDailiesFileSize(newDailiesFileSize);
    setDailiesData(prev => ({
      ...prev,
      fileSize: newDailiesFileSize
    }));

    // Recalculate camera file size and duration based on dailies size
    recalculateFromDailiesSize(newSize, unit);
  };

  // Add new function to handle recalculation from dailies size
  const recalculateFromDailiesSize = (dailiesSize, dailiesUnit) => {
    if (isDurationLocked || isFileSizeLocked) return;

    // Convert dailies size to bytes
    const unitMultiplier = {
      'KB': 1024,
      'MB': 1024 * 1024,
      'GB': 1024 * 1024 * 1024,
      'TB': 1024 * 1024 * 1024 * 1024,
      'PB': 1024 * 1024 * 1024 * 1024 * 1024
    };
    
    const dailiesSizeInBytes = dailiesSize * unitMultiplier[dailiesUnit];

    // Get dailies codec datarate
    const dailiesCodecData = dailiesCodecs.find(codec => codec.name === dailiesData.codec);
    if (!dailiesCodecData) return;

    // Calculate frames based on dailies size
    const framerate = parseFloat(calcData.framerate);
    const dailiesPixels = width * height;
    
    // Convert codec datarate from Mbps to bytes per frame
    const mbitsPerSecond = dailiesCodecData.datarate;
    const bytesPerSecond = (mbitsPerSecond * 1024 * 1024) / 8;
    const bytesPerFrame = bytesPerSecond / framerate;
    
    // Calculate total frames
    const totalFrames = Math.round(dailiesSizeInBytes / bytesPerFrame);
    
    // Convert frames to timecode
    const newDuration = Timecode.fromFrames(totalFrames, framerate).toString();

    // Update duration
    setDurationInput(newDuration);
    setCalcData(prev => ({
      ...prev,
      duration: newDuration
    }));

    // Calculate camera file size based on new duration
    const { size: cameraSize, unit: cameraUnit } = calculateFileSize({
      ...calcData,
      duration: newDuration
    });

    // Update camera file size
    setFileSizeInput(`${cameraSize} ${cameraUnit}`);
    setFileSizeUnit(cameraUnit);
  };

  // Update the dailies file size input handler
  const handleDailiesFileSizeChange = (e) => {
    const newValue = e.target.value;
    setDailiesFileSize(newValue);
    
    // Parse and validate the new value
    const parsed = parseFileSize(newValue);
    if (parsed) {
      setDailiesData(prev => ({
        ...prev,
        fileSize: `${parsed.size} ${parsed.unit}`
      }));
      recalculateFromDailiesSize(parsed.size, parsed.unit);
    }
  };

  // Add blur handler for dailies file size input
  const handleDailiesFileSizeBlur = () => {
    const parsed = parseFileSize(dailiesFileSize);
    if (parsed) {
      const formattedSize = `${parsed.size} ${parsed.unit}`;
      setDailiesFileSize(formattedSize);
      setDailiesData(prev => ({
        ...prev,
        fileSize: formattedSize
      }));
      recalculateFromDailiesSize(parsed.size, parsed.unit);
    }
  };

  // Add touch handlers for mobile scrolling
  const handleDailiesWidthTouch = (e) => {
    if (pinnedField) return;
    
    e.preventDefault();
    const touch = e.touches[0];
    const startY = touch.clientY;
    const rect = e.target.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    const fieldWidth = rect.width;
    let lastY = startY;
    
    const handleTouchMove = (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const diffY = lastY - currentY;
      const isLeftHalf = touchX < fieldWidth / 2;
      const baseChange = isLeftHalf ? 20 : 2;
      const changeAmount = Math.abs(diffY) > 5 
        ? baseChange * Math.abs(diffY / 5) 
        : baseChange;
      
      const delta = Math.sign(diffY);
      const newWidth = roundToEven(Math.max(2, dailiesData.width + delta * changeAmount));
      handleWidthChange({ target: { value: newWidth } });
      lastY = currentY;
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  };

  const handleDailiesHeightTouch = (e) => {
    if (pinnedField) return;
    
    e.preventDefault();
    const touch = e.touches[0];
    const startY = touch.clientY;
    const rect = e.target.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    const fieldWidth = rect.width;
    let lastY = startY;
    
    const handleTouchMove = (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const diffY = lastY - currentY;
      const isLeftHalf = touchX < fieldWidth / 2;
      const baseChange = isLeftHalf ? 20 : 2;
      const changeAmount = Math.abs(diffY) > 5 
        ? baseChange * Math.abs(diffY / 5) 
        : baseChange;
      
      const delta = Math.sign(diffY);
      const newHeight = roundToEven(Math.max(2, dailiesData.height + delta * changeAmount));
      handleHeightChange({ target: { value: newHeight } });
      lastY = currentY;
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  };

  // Add useEffect for wheel event listeners
  useEffect(() => {
    const elements = [
      { ref: ratioRef, handler: handleDailiesRatioWheel },
      { ref: dailiesCodecRef, handler: handleDailiesCodecWheel },
      { ref: dailiesFileSizeRef, handler: handleDailiesFileSizeWheel }
    ];

    elements.forEach(({ ref, handler }) => {
      if (ref?.current) {
        ref.current.addEventListener('wheel', handler, { passive: false });
        return () => ref.current?.removeEventListener('wheel', handler);
      }
    });
  }, [selectedRatio, dailiesData.codec, dailiesFileSize]); // Add missing dependency array and closing bracket

  // Add this effect to ensure calculator stays at top after keyboard appears/disappears on mobile
  useEffect(() => {
    if (isMobile) {
      const handleResize = () => {
        window.scrollTo(0, 0);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [isMobile]);

  const handleBrandTouch = useDropdownTouch(
    brands,
    calcData.brand,
    handleInputChange,
    'brand'
  );

  const handleCameraTouch = useDropdownTouch(
    availableCameras,
    calcData.camera,
    handleInputChange,
    'camera'
  );

  const handleResolutionTouch = useDropdownTouch(
    availableResolutions,
    calcData.resolution,
    handleInputChange,
    'resolution'
  );

  const handleCodecTouch = useDropdownTouch(
    availableCodecs,
    calcData.codec,
    handleInputChange,
    'codec'
  );

  const handleFramerateTouch = useDropdownTouch(
    availableFramerates,
    calcData.framerate,
    handleInputChange,
    'framerate'
  );

  const handleDailiesCodecTouch = useDropdownTouch(
    dailiesCodecs.map(codec => codec.name),
    dailiesData.codec,
    (e) => setDailiesData(prev => ({ ...prev, codec: e.target.value })),
    'dailiesCodec'
  );

  const handleDailiesRatioTouch = useDropdownTouch(
    [...defaultAspectRatios.filter(r => r !== "Custom"), ...customRatios],
    selectedRatio,
    handleRatioChange,
    'ratio'
  );

  return (
    <div className={`dailies-calculator p-2 ${isMobile ? '' : ''} ${bgColor} ${textColor}`}>
            <label className={`block ${labelColor}`}>Duration (HH:MM:SS:FF)</label>
      <div className="flex items-center space-x-2">
        <button
          type="button"
          onClick={() => {
            if (calculationMode === null) {
              setIsDurationLocked(true);
              setIsFileSizeLocked(false);
              setIsDailiesFileSizeLocked(false);
              setCalculationMode('duration');
            } else if (calculationMode === 'duration') {
              setIsDurationLocked(false);
              setIsFileSizeLocked(true);
              setIsDailiesFileSizeLocked(false);
              setCalculationMode('fileSize');
            } else if (calculationMode === 'fileSize') {
              setIsDurationLocked(false);
              setIsFileSizeLocked(false);
              setIsDailiesFileSizeLocked(true);
              setCalculationMode('dailiesFileSize');
            } else {
              setIsDurationLocked(false);
              setIsFileSizeLocked(false);
              setIsDailiesFileSizeLocked(false);
              setCalculationMode(null);
            }
          }}
          className={`${pinButtonStyle} ${calculationMode ? 'bg-gray-300' : ''} relative`}
        >
          <FaThumbtack 
            className={`${calculationMode ? "text-blue-500" : "text-gray-500"} ${
              calculationMode ? "scale-[0.85]" : "scale-100"
            } transition-transform`}
          />
          {calculationMode === 'duration' && (
            <FaAngleLeft className="absolute -left-1 top-1/2 -translate-y-1/2 text-blue-500" />
          )}
          {calculationMode === 'fileSize' && (
            <FaAngleRight className="absolute -right-1 top-1/2 -translate-y-1/2 text-blue-500" />
          )}
        </button>
        <div className="flex-1">
          <input
            ref={durationRef}
            type="text"
            name="duration"
            value={durationInput}
            onChange={handleInputChange}
            onFocus={handleDurationFocus}
            onBlur={handleDurationBlur}
            onKeyPress={handleDurationKeyPress}
            onWheel={handleDurationWheel}
            onTouchStart={(e) => handleTouchStart(e, 'duration')}
            placeholder={isDurationLocked ? '' : "ex: 01:00:00:00 or 16 hrs"}
            className={getInputStyle('duration')}
            readOnly={isDurationLocked}
            aria-readonly={isDurationLocked}
          />
        </div>
      </div>
      <div className={`mb-2`}></div>
      <div className="flex space-x-2">
        <div className="w-1/2">
          <label className={`block ${labelColor}`}>Brand</label>
          <select
            ref={brandRef}
            name="brand"
            value={calcData.brand}
            onChange={handleInputChange}
            onTouchStart={handleBrandTouch}
            className={commonInputClass}
          >
            {brands.map(brand => (
              <option key={brand} value={brand}>{brand}</option>
            ))}
          </select>
        </div>
        <div className="w-1/2">
          <label className={`block ${labelColor}`}>Camera</label>
          <select
            ref={cameraRef}
            name="camera"
            value={calcData.camera}
            onChange={handleInputChange}
            onTouchStart={handleCameraTouch}
            className={commonInputClass}
          >
            {availableCameras.map(camera => (
              <option key={camera} value={camera}>{camera}</option>
            ))}
          </select>
        </div>
      </div>
      <div className={`mb-2`}></div>
      <div className="flex space-x-2">
        <div className="w-3/4">
          <label className={`block ${labelColor}`}>Resolution</label>
          <select
            ref={resolutionRef}
            name="resolution"
            value={calcData.resolution}
            onChange={handleInputChange}
            onTouchStart={handleResolutionTouch}
            className={commonInputClass}
          >
            {availableResolutions.map(resolution => (
              <option key={resolution} value={resolution}>{resolution}</option>
            ))}
          </select>
        </div>
        <div className="w-1/4">
          <label className={`block ${labelColor}`}>Framerate</label>
          <select
            ref={framerateRef}
            name="framerate"
            value={calcData.framerate}
            onChange={handleInputChange}
            onTouchStart={handleFramerateTouch}
            className={commonInputClass}
          >
            {availableFramerates.map(framerate => (
              <option key={framerate} value={framerate}>{framerate}</option>
            ))}
          </select>
        </div>
      </div>
      <div className={`mb-2`}></div>
      <div className="flex space-x-2">
        <div className="w-1/2">
          <label className={`block ${labelColor}`}>Camera Codec</label>
          <select
            ref={codecRef}
            name="codec"
            value={calcData.codec}
            onChange={handleInputChange}
            onTouchStart={handleCodecTouch}
            className={commonInputClass}
          >
            {availableCodecs.map((codec, index) => (
              <option key={`${codec}-${index}`} value={codec}>{codec}</option>
            ))}
          </select>
        </div>
        <div className="w-1/2">
          <label className={`block ${labelColor}`}>Camera File Size</label>
          <div className="flex items-center space-x-2">
            <div className="flex-1">
              <input
                ref={fileSizeRef}
                type="text"
                name="fileSize"
                value={fileSizeInput}
                onChange={handleInputChange}
                onFocus={handleFileSizeFocus}
                onBlur={handleFileSizeBlur}
                onKeyPress={handleFileSizeKeyPress}
                onWheel={handleFileSizeWheel}
                onTouchStart={(e) => handleTouchStart(e, 'fileSize')}
                placeholder={isFileSizeLocked ? '' : "ex: 52 GB"}
                className={getInputStyle('fileSize')}
                readOnly={isFileSizeLocked}
                aria-readonly={isFileSizeLocked}
              />
            </div>
            <button
              type="button"
              onClick={() => {
                if (calculationMode === null) {
                  setIsDurationLocked(true);
                  setIsFileSizeLocked(false);
                  setIsDailiesFileSizeLocked(false);
                  setCalculationMode('duration');
                } else if (calculationMode === 'duration') {
                  setIsDurationLocked(false);
                  setIsFileSizeLocked(true);
                  setIsDailiesFileSizeLocked(false);
                  setCalculationMode('fileSize');
                } else if (calculationMode === 'fileSize') {
                  setIsDurationLocked(false);
                  setIsFileSizeLocked(false);
                  setIsDailiesFileSizeLocked(true);
                  setCalculationMode('dailiesFileSize');
                } else {
                  setIsDurationLocked(false);
                  setIsFileSizeLocked(false);
                  setIsDailiesFileSizeLocked(false);
                  setCalculationMode(null);
                }
              }}
              className={`${pinButtonStyle} ${calculationMode ? 'bg-gray-300' : ''} relative`}
            >
              <FaThumbtack 
                className={`${calculationMode ? "text-blue-500" : "text-gray-500"} ${
                  calculationMode ? "scale-[0.85]" : "scale-100"
                } transition-transform`}
              />
              {calculationMode === 'duration' && (
                <FaAngleLeft className="absolute -left-1 top-1/2 -translate-y-1/2 text-blue-500" />
              )}
              {calculationMode === 'fileSize' && (
                <FaAngleRight className="absolute -right-1 top-1/2 -translate-y-1/2 text-blue-500" />
              )}
            </button>
          </div>
        </div>
      </div>
      <div className={`mb-2`}></div>
      <div className="flex items-end gap-2">
        <div className="w-1/3">
          <label className={`block ${labelColor}`}>Dailies Aspect</label>
          <div className="relative">
            <select
              ref={ratioRef}
              name="ratio"
              value={selectedRatio}
              onChange={handleRatioChange}
              onBlur={handleBlur}
              onKeyPress={handleKeyPress}
              onTouchStart={handleDailiesRatioTouch}
              className={`${getInputStyle('ratio')} ${selectedRatio === 'Custom' ? 'hidden':''} text-left`}
              onWheel={handleRatioWheel}
            >
              {[...defaultAspectRatios.filter(r => r !== "Custom"), ...customRatios]
                .sort(compareRatios)
                .map((ratio) => (
                  <option key={ratio} value={ratio}>{formatRatioDisplay(ratio)}</option>
                ))}
              <option value="Custom">Custom</option>
            </select>
            <input
              type="text"
              value={customRatio}
              onChange={handleCustomRatioChange}
              onBlur={handleCustomRatioBlur}
              onKeyDown={handleCustomRatioKeyDown}
              onKeyPress={handleCustomRatioKeyPress}
              placeholder="Enter ratio (e.g., 16:9)"
              className={`${commonInputClass} ${selectedRatio !== 'Custom' ? 'hidden':''} text-left`}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-end gap-2">
          <button
            onClick={() => handlePinClick('width')}
            className={`${pinButtonStyle} ${pinnedField === 'width' ? 'bg-blue-100':''} shrink-0`}
          >
            <FaThumbtack className={pinnedField === 'width' ? "text-blue-500":"text-gray-500"} />
          </button>
          <div className="flex-1">
            <label className={`block ${labelColor}`}>Width</label>
            <input
              ref={widthRef}
              type="text"
              value={width}
              onChange={handleWidthChange}
              onBlur={handleWidthBlur}
              onKeyPress={handleWidthKeyPress}
              onWheel={handleWidthWheel}
              onClick={(e) => e.target.select()}
              onTouchStart={(e) => handleTouchStart(e, 'width')}
              className={`${getInputStyle('width')} w-full`}
              disabled={pinnedField === 'width' || pinnedField === 'height'}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-end gap-2">
          <div className="flex-1">
            <label className={`block ${labelColor}`}>Height</label>
            <input
              ref={heightRef}
              type="text"
              value={height}
              onChange={handleHeightChange}
              onBlur={handleHeightBlur}
              onKeyPress={handleHeightKeyPress}
              onWheel={handleHeightWheel}
              onClick={(e) => e.target.select()}
              onTouchStart={(e) => handleTouchStart(e, 'height')}
              className={`${getInputStyle('height')} w-full`}
              disabled={pinnedField === 'width' || pinnedField === 'height'}
            />
          </div>
          <button
            onClick={() => handlePinClick('height')}
            className={`${pinButtonStyle} ${pinnedField === 'height' ? 'bg-blue-100':''} shrink-0`}
          >
            <FaThumbtack className={pinnedField === 'height' ? "text-blue-500":"text-gray-500"} />
          </button>
        </div>
      </div>
      <div className={`mb-2`}></div>
      <div className="flex space-x-2">
        <div className="w-1/2">
          <label className={`block ${labelColor}`}>Dailies Codec</label>
          <select
            ref={dailiesCodecRef}
            value={dailiesData.codec}
            onChange={(e) => setDailiesData(prev => ({ ...prev, codec: e.target.value }))}
            onTouchStart={handleDailiesCodecTouch}
            className={commonInputClass}
          >
            {dailiesCodecs.map((codec) => (
              <option key={codec.name} value={codec.name}>
                {codec.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-1/2">
          <label className={`block ${labelColor}`}>Dailies File Size</label>
          <div className="flex items-center space-x-2">
            <div className="flex-1">
              <input
                ref={dailiesFileSizeRef}
                type="text"
                value={dailiesFileSize}
                onChange={handleDailiesFileSizeChange}
                onBlur={handleDailiesFileSizeBlur}
                placeholder="Calculated dailies size"
                className={getInputStyle('dailiesFileSize')}
                readOnly={isDailiesFileSizeLocked}
                onWheel={handleDailiesFileSizeWheel}
                onTouchStart={(e) => handleTouchStart(e, 'dailiesFileSize')}
              />
            </div>
            <button
              type="button"
              onClick={() => {
                if (calculationMode === null) {
                  setIsDurationLocked(true);
                  setIsFileSizeLocked(false);
                  setIsDailiesFileSizeLocked(false);
                  setCalculationMode('duration');
                } else if (calculationMode === 'duration') {
                  setIsDurationLocked(false);
                  setIsFileSizeLocked(true);
                  setIsDailiesFileSizeLocked(false);
                  setCalculationMode('fileSize');
                } else if (calculationMode === 'fileSize') {
                  setIsDurationLocked(false);
                  setIsFileSizeLocked(false);
                  setIsDailiesFileSizeLocked(true);
                  setCalculationMode('dailiesFileSize');
                } else {
                  setIsDurationLocked(false);
                  setIsFileSizeLocked(false);
                  setIsDailiesFileSizeLocked(false);
                  setCalculationMode(null);
                }
              }}
              className={`${pinButtonStyle} ${calculationMode ? 'bg-gray-300' : ''} relative`}
            >
              <FaThumbtack 
                className={`${calculationMode ? "text-blue-500" : "text-gray-500"} ${
                  calculationMode ? "scale-[0.85]" : "scale-100"
                } transition-transform`}
              />
              {calculationMode === 'duration' && (
                <FaAngleLeft className="absolute -left-1 top-1/2 -translate-y-1/2 text-blue-500" />
              )}
              {calculationMode === 'fileSize' && (
                <FaAngleRight className="absolute -right-1 top-1/2 -translate-y-1/2 text-blue-500" />
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-between w-full">
        <button
          onClick={handleLogClick}
          className={`px-4 py-4 rounded flex-grow mr-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {logButtonText}
        </button>
        <CopyToClipboard text={`${calcData.brand} ${calcData.camera} - ${calcData.resolution} ${calcData.codec} @ ${calcData.framerate}fps ${calcData.duration} = ${fileSizeInput}\r\n`} onCopy={handleCopyDuration}>
          <button 
            className={`px-4 py-4 rounded flex-grow mx-2 ${buttonColor} text-white flex items-center justify-center`}
          >
            {copyDurationText}
          </button>
        </CopyToClipboard>
        <button
          onClick={handleShare}
          className={`px-4 py-4 rounded flex-grow mx-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {shareButtonText}
        </button>
        <button
          onClick={handleReset}
          className={`px-4 py-4 rounded flex-grow ml-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {resetButtonText}
        </button>
      </div>
    </div>
  );
};

export default DailiesCalculator;