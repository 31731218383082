import React, { useState, useEffect, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SlDocs, SlNote, SlRefresh, SlLike, SlLink } from "react-icons/sl";
import { FaThumbtack, FaAngleLeft, FaAngleRight, FaAngleUp, FaLock, FaUnlockAlt } from 'react-icons/fa';
import { handleShare as handleShareUtil, disableScroll, enableScroll } from '../utils';
import { useCalculator } from '../context/CalculatorContext';
import { useDropdownTouch } from '../hooks/useDropdownTouch';

const defaultAspectRatios = ["2.39:1", "2:1", "1.85:1", "1.66:1", "16:9", "4:3", 
  "21:9", "16:10", "3:2","6:5", "1:1", "4:5", "9:16", "Custom"];

function AspectRatioCalculator({ addLog }) {
  const { aspectCalcState, setAspectCalcState } = useCalculator();

  const [isMobile, setIsMobile] = useState(false);
  const [selectedRatio, setSelectedRatio] = useState(aspectCalcState.selectedRatio);
  const [customRatio, setCustomRatio] = useState(aspectCalcState.customRatio);
  const [width, setWidth] = useState(aspectCalcState.width);
  const [height, setHeight] = useState(aspectCalcState.height);
  const [pinnedField, setPinnedField] = useState(aspectCalcState.pinnedField);
  const [logButtonText, setLogButtonText] = useState(<SlNote />);
  const [copyButtonText, setCopyButtonText] = useState(<SlDocs />);
  const [shareButtonText, setShareButtonText] = useState(<SlLink />);
  const [resetButtonText, setResetButtonText] = useState(<SlRefresh />);
  const [customRatios, setCustomRatios] = useState(aspectCalcState.customRatios);
  const [calculationMode, setCalculationMode] = useState(aspectCalcState.calculationMode);
  const [resolution, setResolution] = useState("1920 x 1080");
  const [isWidthLocked, setIsWidthLocked] = useState(aspectCalcState.isWidthLocked);
  const [isHeightLocked, setIsHeightLocked] = useState(aspectCalcState.isHeightLocked);
  const [sensitivity] = useState(0.025);
  const [scrollTimeout, setScrollTimeout] = useState(null);

  const ratioRef = useRef(null);
  const widthRef = useRef(null);
  const heightRef = useRef(null);
  const resolutionRef = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ratio = params.get('ratio');
    const widthParam = params.get('width');
    const heightParam = params.get('height');

    if (ratio && widthParam && heightParam) {
      setSelectedRatio(ratio);
      setWidth(parseInt(widthParam, 10));
      setHeight(parseInt(heightParam, 10));
    }
  }, []);

  useEffect(() => {
    setAspectCalcState({
      selectedRatio,
      width,
      height,
      pinnedField,
      customRatio,
      customRatios,
      calculationMode,
      isWidthLocked,
      isHeightLocked
    });
  }, [
    selectedRatio,
    width,
    height,
    pinnedField,
    customRatio,
    customRatios,
    calculationMode,
    isWidthLocked,
    isHeightLocked,
    setAspectCalcState
  ]);

  const roundToEven = (num) => {
    const rounded = Math.round(num);
    return rounded % 2 === 0 ? rounded : rounded + 1;
  };

  const parseRatio = (input) => {
    console.log('Parsing ratio input:', input);

    // Remove all whitespace and convert to lowercase
    input = input.toLowerCase().replace(/\s+/g, '');
    console.log('Cleaned input:', input);

    // Check for decimal format first (e.g., 2.35 or 1.77)
    const decimalMatch = input.match(/^(\d+(?:\.\d+)?)$/);
    if (decimalMatch) {
      const result = parseFloat(input);
      console.log(`Decimal format detected: ${input}, treating as ${input}:1`);
      return result;
    }

    // Check for ratio format (e.g., 16:9, 16x9, 2.35:1)
    const ratioMatch = input.match(/^(\d+(?:\.\d+)?)[:x](\d+(?:\.\d+)?)$/);
    if (ratioMatch) {
      const [w, h] = ratioMatch.slice(1).map(Number);
      const result = w / h;
      console.log(`Ratio format detected: ${w}:${h}, Result:`, result);
      return result;
    }

    // Check for two numbers separated by space (e.g., 16 9, 2.35 1)
    const spaceMatch = input.match(/^(\d+(?:\.\d+)?)\s*(\d+(?:\.\d+)?)$/);
    if (spaceMatch) {
      const [w, h] = spaceMatch.slice(1).map(Number);
      const result = w / h;
      console.log(`Space-separated format detected: ${w} ${h}, Result:`, result);
      return result;
    }

    // If none of the above formats match, return NaN
    console.log('No valid format detected, returning NaN');
    return NaN;
  };

  const compareRatios = (a, b) => {
    const ratioA = parseRatio(a);
    const ratioB = parseRatio(b);
    return ratioA - ratioB;
  };

  const formatRatioDisplay = (ratio) => {
    return ratio;
  };

  const formatRatio = (ratio) => {
    // Check if the input ratio matches any of the original custom ratios
    const matchingCustomRatio = customRatios.find(r => {
      const parsed = parseRatio(r);
      return Math.abs(parsed - ratio) < 0.0001;
    });

    if (matchingCustomRatio) {
      return matchingCustomRatio;
    }

    // For decimal inputs like 2.35, preserve as "2.35:1"
    if (Number.isFinite(ratio) && !Number.isInteger(ratio)) {
      return `${ratio.toFixed(3)}:1`;
    }

    // For integer ratios, use the GCD reduction
    const tolerance = 0.0001;
    for (let denominator = 1; denominator <= 16; denominator++) {
      const numerator = ratio * denominator;
      if (Math.abs(numerator - Math.round(numerator)) < tolerance) {
        let n = Math.round(numerator);
        let d = denominator;
        const gcd = getGCD(n, d);
        n = n / gcd;
        d = d / gcd;
        return `${n}:${d}`;
      }
    }

    return `${ratio.toFixed(3)}:1`;
  };

  const getGCD = (a, b) => {
    a = Math.abs(a);
    b = Math.abs(b);
    while (b) {
      const temp = b;
      b = a % b;
      a = temp;
    }
    return a;
  };

  const handlePinClick = (field) => {
    setPinnedField((prevPinned) => (prevPinned === field ? null : field));
  };

  const handleRatioChange = (e) => {
    const value = e.target.value;
    setSelectedRatio(value);

    if (value === "Custom") {
      setCustomRatio("");
      return;
    }

    setCustomRatio(value);
    if (pinnedField !== 'ratio') {
      calculateDimensions('ratio');
    }
  };

  const handleCustomRatioChange = (e) => {
    const value = e.target.value;
    console.log('Custom ratio input:', value);
    // Allow digits, decimal point, colon, 'x', and space
    // Replace any sequence of spaces with a single space
    if (/^[\d.:x\s]*$/.test(value)) {
      const cleanedValue = value.replace(/\s+/g, ' ');
      // If there's a space, automatically convert it to a colon
      const formattedValue = cleanedValue.replace(/\s/g, ':');
      setCustomRatio(formattedValue);
      console.log('Valid input, setting custom ratio:', formattedValue);
    } else {
      console.log('Invalid input, ignoring');
    }
  };

  const handleCustomRatioKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      processCustomRatio();
      e.target.blur();
    }
  };

  const handleCustomRatioBlur = () => {
    processCustomRatio();
  };

  // Helper function to handle all custom ratio processing
  const processCustomRatio = () => {
    console.log('Processing custom ratio, current value:', customRatio);
    if (customRatio) {
      const parsedRatio = parseRatio(customRatio);
      console.log('Parsed ratio:', parsedRatio);
      if (!isNaN(parsedRatio)) {
        const formattedRatio = formatRatio(parsedRatio);
        console.log('Formatted ratio:', formattedRatio);
        // Only add to custom ratios if it came from Custom input
        if (
          selectedRatio === 'Custom' &&
          !defaultAspectRatios.includes(formattedRatio) &&
          !customRatios.includes(formattedRatio)
        ) {
          setCustomRatios((prevRatios) => {
            const newRatios = [...prevRatios, formattedRatio];
            return newRatios.sort(compareRatios);
          });
        }
        setSelectedRatio(formattedRatio);
        recalculateDimensions('ratio');
      } else {
        console.log('Invalid ratio, not updating');
      }
    }
    setCustomRatio("");
  };

  const handleWidthChange = (e) => {
    const value = e.target.value === '' ? '' : parseInt(e.target.value, 10) || 0;
    setWidth(value);
    calculateDimensions('width');
  };

  const handleHeightChange = (e) => {
    const value = e.target.value === '' ? '' : parseInt(e.target.value, 10) || 0;
    setHeight(value);
    calculateDimensions('height');
  };

  const handleWidthBlur = () => {
    const newWidth = roundToEven(parseInt(width, 10) || 0);
    setWidth(newWidth);
    if (pinnedField !== 'width') {
      calculateDimensions('width');
    }
  };

  const handleHeightBlur = () => {
    const newHeight = roundToEven(parseInt(height, 10) || 0);
    setHeight(newHeight);
    if (pinnedField !== 'height') {
      calculateDimensions('height');
    }
  };

  const handleHeightKeyPress = (e) => {
    if (e.key === 'Enter') {
      const newHeight = roundToEven(parseInt(height, 10) || 0);
      setHeight(newHeight);

      if (pinnedField === 'width') {
        const newRatio = width / newHeight;
        const formattedRatio = formatRatio(newRatio);
        setSelectedRatio(formattedRatio);
      } else if (!pinnedField || pinnedField === 'ratio') {
        const aspectRatio = parseRatio(selectedRatio);
        const newWidth = roundToEven(newHeight * aspectRatio);
        setWidth(newWidth);
      }

      e.target.blur();
    }
  };

  const handleWidthKeyPress = (e) => {
    if (e.key === 'Enter') {
      const newWidth = roundToEven(parseInt(width, 10) || 0);
      setWidth(newWidth);
      e.target.blur();
    }
  };

  // Define handleRatioBlur function
  const handleRatioBlur = () => {
    const aspectRatio = parseRatio(selectedRatio);
    recalculateDimensions('ratio');
  };

  // Define handleKeyPress function
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      const fieldName = e.target.name;
      if (fieldName === 'width') {
        handleWidthBlur();
      } else if (fieldName === 'height') {
        handleHeightBlur();
      } else if (fieldName === 'ratio') {
        handleRatioBlur();
      }
    }
  };

  // Update handleWidthWheel function
  const handleWidthWheel = (e) => {
    e.preventDefault();
    if (pinnedField === 'width') return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const isLeftHalf = x < rect.width / 2;
    const scrollAmount = -e.deltaY;
    const baseChange = isLeftHalf ? 20 : 2;
    const changeAmount = Math.abs(scrollAmount) > 50 
      ? baseChange * Math.abs(scrollAmount / 50) 
      : baseChange;
    const delta = Math.sign(scrollAmount);

    const evenChangeAmount = Math.round(changeAmount / 2) * 2;
    const newWidth = Math.max(2, width + delta * evenChangeAmount);
    setWidth(newWidth);
    
    calculateDimensions('width');
  };

  // Update handleHeightWheel function
  const handleHeightWheel = (e) => {
    e.preventDefault();
    if (pinnedField === 'height') return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const isLeftHalf = x < rect.width / 2;
    const scrollAmount = -e.deltaY;
    const baseChange = isLeftHalf ? 20 : 2;
    const changeAmount = Math.abs(scrollAmount) > 50 
      ? baseChange * Math.abs(scrollAmount / 50) 
      : baseChange;
    const delta = Math.sign(scrollAmount);

    const evenChangeAmount = Math.round(changeAmount / 2) * 2;
    const newHeight = Math.max(2, height + delta * evenChangeAmount);
    setHeight(newHeight);
    
    calculateDimensions('height');
  };

  // Refine calculateDimensions function
  const calculateDimensions = (changedField) => {
    const aspectRatio = parseRatio(selectedRatio);
    if (isNaN(aspectRatio)) return;

    // Case 1: Nothing is pinned
    if (!pinnedField) {
      if (changedField === 'ratio') {
        // When ratio changes, update height based on current width
        setHeight(roundToEven(width / aspectRatio));
      } else if (changedField === 'width') {
        // When width changes, update height
        setHeight(roundToEven(width / aspectRatio));
      } else if (changedField === 'height') {
        // When height changes, update width
        setWidth(roundToEven(height * aspectRatio));
      }
      return;
    }

    // Case 2: Width is pinned
    if (pinnedField === 'width') {
      if (changedField === 'ratio') {
        // When ratio changes, update height
        setHeight(roundToEven(width / aspectRatio));
      } else if (changedField === 'height') {
        // When height changes, update ratio and store if custom
        const newRatio = width / height;
        const formattedRatio = formatRatio(newRatio);
        setSelectedRatio(formattedRatio);
        
        // Add to custom ratios if it's new
        if (!defaultAspectRatios.includes(formattedRatio) && !customRatios.includes(formattedRatio)) {
          setCustomRatios(prevRatios => {
            const newRatios = [...prevRatios, formattedRatio];
            return newRatios.sort(compareRatios);
          });
        }
      }
      return;
    }

    // Case 3: Height is pinned
    if (pinnedField === 'height') {
      if (changedField === 'ratio') {
        // When ratio changes, update width
        setWidth(roundToEven(height * aspectRatio));
      } else if (changedField === 'width') {
        // When width changes, update ratio and store if custom
        const newRatio = width / height;
        const formattedRatio = formatRatio(newRatio);
        setSelectedRatio(formattedRatio);
        
        // Add to custom ratios if it's new
        if (!defaultAspectRatios.includes(formattedRatio) && !customRatios.includes(formattedRatio)) {
          setCustomRatios(prevRatios => {
            const newRatios = [...prevRatios, formattedRatio];
            return newRatios.sort(compareRatios);
          });
        }
      }
      return;
    }

    // Case 4: Ratio is pinned
    if (pinnedField === 'ratio') {
      if (changedField === 'width') {
        // When width changes, update height
        setHeight(roundToEven(width / aspectRatio));
      } else if (changedField === 'height') {
        // When height changes, update width
        setWidth(roundToEven(height * aspectRatio));
      }
    }
  };

  // Helper function to calculate aspect ratio without causing loops
  const recalculateDimensions = (field, context = 'manual') => {
    const aspectRatio = parseRatio(selectedRatio);
    if (isNaN(aspectRatio)) return;

    if (pinnedField === 'ratio') {
      if (field === 'width') {
        setHeight(roundToEven(width / aspectRatio));
      } else if (field === 'height') {
        setWidth(roundToEven(height * aspectRatio));
      }
    } else if (pinnedField === 'width') {
      setHeight(roundToEven(width / aspectRatio));
    } else if (pinnedField === 'height') {
      setWidth(roundToEven(height * aspectRatio));
    } else {
      setHeight(roundToEven(width / aspectRatio));
    }
  };

  const handleShare = () => {
    const params = new URLSearchParams({
      ratio: selectedRatio,
      width: width.toString(),
      height: height.toString(),
      calculationMode: calculationMode || '',
      pinnedField: pinnedField || ''
    });

    const shareUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
    const title = 'Aspect Ratio Calculation';
    const text = `${selectedRatio} - ${width}x${height}`;

    handleShareUtil(isMobile, title, text, shareUrl, () => {
      setShareButtonText(<SlLike />);
      setTimeout(() => setShareButtonText(<SlLink />), 500);
    });
  };

  const handleReset = () => {
    setSelectedRatio("16:9");
    setCustomRatio("");
    setWidth(1920);
    setHeight(1080);
    setPinnedField(null);
    setIsWidthLocked(false);
    setIsHeightLocked(false);
    setResetButtonText(<SlLike />);
    setTimeout(() => setResetButtonText(<SlRefresh />), 2000);
  };

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (isMobile) {
      disableScroll();
      return () => enableScroll();
    }
  }, [isMobile]);

  const bgColor = 'bg-white';
  const textColor = 'text-gray-900';
  const inputBgColor = 'bg-gray-50';
  const inputBorderColor = 'border-gray-300';
  const labelColor = 'text-gray-700';
  const buttonColor = 'bg-blue-500 hover:bg-blue-600';

  const commonInputClass = `w-full px-2 py-1 border rounded h-[36px] ${inputBgColor} ${inputBorderColor} ${textColor} text-center`;
  const getInputStyle = (field) => {
    const baseStyle = "w-full px-2 py-1 border rounded h-[36px] appearance-none";
    
    // Only apply locked styles to the specifically pinned field
    if (field === pinnedField) {
      return `${baseStyle} bg-gray-200 text-gray-700 border-2 border-blue-500 cursor-not-allowed text-center 
        focus:outline-none focus:ring-0 focus:border-blue-500 
        disabled:bg-gray-200 disabled:text-gray-700 disabled:opacity-100`;
    }
    
    // All other fields remain editable
    return `${baseStyle} bg-gray-50 border border-gray-300 text-gray-900 text-center 
      focus:outline-none focus:ring-0 focus:border-gray-300`;
  };

  const pinButtonStyle = `p-2 rounded h-[38px] w-[38px] flex items-center justify-center self-end bg-gray-200 hover:bg-gray-300 border-2 ${
    pinnedField ? 'border-blue-500' : 'border-transparent'
  }`;

  const getLiveRatio = () => {
    if (width && height) {
      const currentRatio = width / height;
      return formatRatio(currentRatio);
    }
    return selectedRatio;
  };

  useEffect(() => {
    return () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [scrollTimeout]);

  const logResult = () => {
    const logEntry = `Aspect Ratio: ${selectedRatio} = ${width}x${height}`;
    addLog(logEntry);
    setLogButtonText(<SlLike />);
    setTimeout(() => setLogButtonText(<SlNote />), 2000);
  };

  const handleCopy = () => {
    setCopyButtonText(<SlLike />);
    setTimeout(() => setCopyButtonText(<SlDocs />), 2000);
  };

  // Update the handleRatioWheel function
  const handleRatioWheel = (e) => {
    e.preventDefault();
    if (pinnedField === 'ratio') return;
    
    const scrollAmount = e.deltaY;
    const scrollableRatios = [...defaultAspectRatios.filter(r => r !== "Custom"), ...customRatios];
    const currentIndex = scrollableRatios.indexOf(selectedRatio);
    
    if (scrollAmount > 0) {
      // Scroll down, increase index
      const nextIndex = currentIndex < scrollableRatios.length - 1 ? currentIndex + 1 : currentIndex;
      handleRatioChange({ target: { value: scrollableRatios[nextIndex] } });
    } else {
      // Scroll up, decrease index
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
      handleRatioChange({ target: { value: scrollableRatios[prevIndex] } });
    }
  };

  // Update the handleRatioTouch function to handle scrolling
  const handleRatioTouch = (e) => {
    const touch = e.touches[0];
    const rect = e.currentTarget.getBoundingClientRect();
    
    // Store the initial touch position
    e.currentTarget.dataset.touchStartY = touch.clientY;
    e.currentTarget.dataset.lastTouchY = touch.clientY;
  };

  const handleRatioTouchMove = (e) => {
    e.preventDefault();
    if (pinnedField === 'ratio') return;

    const touch = e.touches[0];
    const lastY = parseFloat(e.currentTarget.dataset.lastTouchY);
    const deltaY = lastY - touch.clientY;
    
    const scrollableRatios = [...defaultAspectRatios.filter(r => r !== "Custom"), ...customRatios];
    const currentIndex = scrollableRatios.indexOf(selectedRatio);
    
    // Only change ratio if we've moved more than 10 pixels
    if (Math.abs(deltaY) > 10) {
      if (deltaY > 0) {
        // Scroll down, increase index
        const nextIndex = currentIndex < scrollableRatios.length - 1 ? currentIndex + 1 : currentIndex;
        if (nextIndex !== currentIndex) {
          handleRatioChange({ target: { value: scrollableRatios[nextIndex] } });
        }
      } else {
        // Scroll up, decrease index
        const prevIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        if (prevIndex !== currentIndex) {
          handleRatioChange({ target: { value: scrollableRatios[prevIndex] } });
        }
      }
      // Update the last touch position after making a change
      e.currentTarget.dataset.lastTouchY = touch.clientY;
    }
  };
  const handleBlur = () => {
    if (selectedRatio === 'Custom') {
      processCustomRatio();
    } else {
      recalculateDimensions('ratio');
    }
  };
  // Update the select element to use these new handlers
  <select
    ref={ratioRef}
    name="ratio"
    value={selectedRatio}
    onChange={handleRatioChange}
    onBlur={handleBlur}
    onKeyPress={handleKeyPress}
    onTouchStart={handleRatioTouch}
    onTouchMove={handleRatioTouchMove}
    className={`${getInputStyle('ratio')} ${selectedRatio === 'Custom' ? 'hidden':''} text-left`}
    onWheel={handleRatioWheel}
  >
    {[...defaultAspectRatios.filter(r => r !== "Custom"), ...customRatios]
      .sort(compareRatios)
      .map((ratio) => (
        <option key={ratio} value={ratio}>{formatRatioDisplay(ratio)}</option>
      ))}
    <option value="Custom">Custom</option>
  </select>

  // Add this function near other handlers


  // Add touch handlers for mobile scrolling
  const handleTouchStart = (e, field) => {
    const touch = e.touches[0];
    const rect = e.currentTarget.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const isLeftHalf = x < rect.width / 2;
    
    // Store the initial touch position and whether it's on the left half
    e.currentTarget.dataset.touchStartY = touch.clientY;
    e.currentTarget.dataset.isLeftHalf = isLeftHalf;
    e.currentTarget.dataset.lastTouchY = touch.clientY;
  };

  const handleTouchMove = (e, field) => {
    e.preventDefault();
    if (pinnedField === field) return;

    const touch = e.touches[0];
    const startY = parseFloat(e.currentTarget.dataset.touchStartY);
    const lastY = parseFloat(e.currentTarget.dataset.lastTouchY);
    const isLeftHalf = e.currentTarget.dataset.isLeftHalf === 'true';
    
    const deltaY = lastY - touch.clientY;
    const baseChange = isLeftHalf ? 20 : 2;
    const changeAmount = Math.abs(deltaY) > 10 
      ? baseChange * Math.abs(deltaY / 10) 
      : baseChange;
    const delta = Math.sign(deltaY);

    const evenChangeAmount = Math.round(changeAmount / 2) * 2;

    if (field === 'width') {
      const newWidth = Math.max(2, width + delta * evenChangeAmount);
      setWidth(newWidth);
      calculateDimensions('width');
    } else if (field === 'height') {
      const newHeight = Math.max(2, height + delta * evenChangeAmount);
      setHeight(newHeight);
      calculateDimensions('height');
    }

    e.currentTarget.dataset.lastTouchY = touch.clientY;
  };

  

  return (
    <div className={`aspect-ratio-calculator p-2 ${isMobile ? '' : ''} ${bgColor} ${textColor}`}>
      <label className={`block ${labelColor}`}>Aspect Ratio</label>
      <div className="flex items-end space-x-2 mb-2">
        <div className="flex-1">
          <select
            ref={ratioRef}
            name="ratio"
            value={selectedRatio}
            onChange={handleRatioChange}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            onTouchStart={handleRatioTouch}
            onTouchMove={handleRatioTouchMove}
            className={`${getInputStyle('ratio')} ${selectedRatio === 'Custom' ? 'hidden':''} text-left`}
            onWheel={handleRatioWheel}
          >
            {[...defaultAspectRatios.filter(r => r !== "Custom"), ...customRatios]
              .sort(compareRatios)
              .map((ratio) => (
                <option key={ratio} value={ratio}>{formatRatioDisplay(ratio)}</option>
              ))}
            <option value="Custom">Custom</option>
          </select>
          <input
            type="text"
            name="ratio"
            value={customRatio}
            onChange={handleCustomRatioChange}
            onBlur={handleCustomRatioBlur}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Tab') {
                e.preventDefault();
                processCustomRatio();
                e.target.blur();
                if (e.key === 'Tab') {
                  widthRef.current?.focus();
                }
              }
            }}
            placeholder="Enter ratio (e.g., 16:9)"
            className={`${commonInputClass} ${selectedRatio !== 'Custom' ? 'hidden':''}`}
          />
        </div>
      </div>

      <div className="grid grid-cols-[1fr,auto,1fr] gap-2 items-end">
        <div>
          <label className={`block ${labelColor}`}>Width</label>
          <input
            ref={widthRef}
            type="text"
            name="width"
            value={width}
            onChange={handleWidthChange}
            onBlur={handleWidthBlur}
            onKeyPress={handleWidthKeyPress}
            onWheel={handleWidthWheel}
            onClick={(e) => e.target.select()}
            className={getInputStyle('width')}
            disabled={pinnedField === 'width'}
            onTouchStart={(e) => handleTouchStart(e, 'width')}
            onTouchMove={(e) => handleTouchMove(e, 'width')}
          />
        </div>

        <div className="flex flex-col justify-end">
          <label className="block invisible">Pin</label>
          <button
            type="button"
            onClick={() => {
              if (pinnedField === null) {
                setPinnedField('width');
              } else if (pinnedField === 'width') {
                setPinnedField('ratio');
              } else if (pinnedField === 'ratio') {
                setPinnedField('height');
              } else {
                setPinnedField(null);
              }
            }}
            className={`${pinButtonStyle} ${pinnedField ? 'bg-gray-300' : ''} relative`}
          >
            {pinnedField ? (
              <FaLock 
                className={`text-blue-500 scale-[0.85] transition-transform`}
              />
            ) : (
              <FaUnlockAlt 
                className={`text-gray-500 scale-100 transition-transform`}
              />
            )}
            {pinnedField === 'width' && (
              <FaAngleLeft className="absolute -left-1 top-1/2 -translate-y-1/2 text-blue-500" />
            )}
            {pinnedField === 'ratio' && (
              <FaAngleUp className="absolute -top-1 left-1/2 -translate-x-1/2 text-blue-500" />
            )}
            {pinnedField === 'height' && (
              <FaAngleRight className="absolute -right-1 top-1/2 -translate-y-1/2 text-blue-500" />
            )}
          </button>
        </div>

        <div>
          <label className={`block ${labelColor}`}>Height</label>
          <input
            ref={heightRef}
            type="text"
            name="height"
            value={height}
            onChange={handleHeightChange}
            onBlur={handleHeightBlur}
            onKeyPress={handleHeightKeyPress}
            onWheel={handleHeightWheel}
            onClick={(e) => e.target.select()}
            className={getInputStyle('height')}
            disabled={pinnedField === 'height'}
            onTouchStart={(e) => handleTouchStart(e, 'height')}
            onTouchMove={(e) => handleTouchMove(e, 'height')}
          />
        </div>
      </div>

      <div className="mt-4 flex justify-between w-full">
        <button
          onClick={logResult}
          className={`px-4 py-4 rounded flex-grow mr-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {logButtonText}
        </button>
        <CopyToClipboard text={`Aspect Ratio: ${selectedRatio} = ${width}x${height}`} onCopy={handleCopy}>
          <button className={`px-4 py-4 rounded flex-grow mx-2 ${buttonColor} text-white flex items-center justify-center`}>
            {copyButtonText}
          </button>
        </CopyToClipboard>
        <button
          onClick={handleShare}
          className={`px-4 py-4 rounded flex-grow mx-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {shareButtonText}
        </button>
        <button
          onClick={handleReset}
          className={`px-4 py-4 rounded flex-grow ml-2 ${buttonColor} text-white flex items-center justify-center`}
        >
          {resetButtonText}
        </button>
      </div>
    </div>
  );
}

export default AspectRatioCalculator;