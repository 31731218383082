import React, { useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { calculatorConfig } from '../config/features';

function Header({ isMobile }) {
  const navigate = useNavigate();
  const location = useLocation();
  const selectRef = useRef(null);
  
  const menuItems = Object.entries(calculatorConfig)
    .filter(([_, calc]) => !calc.hidden && !calc.comingSoon)
    .map(([key, calc]) => ({
      id: key,
      path: calc.path,
      label: `${calc.title}${calc.beta ? ' (BETA)' : ''}`,
      icon: calc.icon
    }));

  // Filter out only enabled calculators for mobile icons
  const mobileMenuItems = menuItems.filter(item => 
    calculatorConfig[item.id].enabled
  );

  // Get current calculator name based on location
  const getCurrentCalculatorName = () => {
    const currentItem = menuItems.find(item => item.path === location.pathname);
    return currentItem ? currentItem.label : 'Calculator';
  };

  const handleCalculatorChange = (e) => {
    const value = e.target.value;
    navigate(value);
  };

  const handleLogClick = () => {
    navigate('/logs', { 
      state: { 
        from: location.pathname,
        calculatorName: getCurrentCalculatorName()
      } 
    });
  };

  return (
    <header className={`bg-white rounded-sm ${isMobile ? 'shadow-md' : 'shadow-md'} p-3 md:p-4`}>
      <div className="flex h-full">
        {/* Logo Column */}
        <div className="flex-shrink-0">
          <div className="flex flex-col items-center">
            <a href="https://tbdpost.com" onClick={handleLogClick}>
              <img src="/tbd_logo_black.png" alt="TBD Post Logo" className="h-12 md:h-16" />
            </a>
            <h1 className="mt-0 text-sm items-center font-bold md:mt-0 md:hidden">DataCalc</h1>
          </div>
        </div>

        {/* Right Column - Dropdown and Icons for mobile, title and dropdown for desktop */}
        {isMobile ? (
          <div className="flex flex-col justify-between flex-grow pl-4">
            {/* Dropdown */}
            <div className="flex-1 flex items-start justify-end mb-1">
              <select
                ref={selectRef}
                value={location.pathname}
                onChange={handleCalculatorChange}
                className="text-lg font-bold bg-white border-none focus:outline-none text-right header-select w-full"
                size={1}
              >
                {menuItems.map(item => (
                  <option key={item.id} value={item.path}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            
            {/* Icons - adjusted spacing and positioning */}
            <div className="flex-1 flex items-center justify-end">
              <div className="flex space-x-6 px-2">
                {mobileMenuItems.map((item) => (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`transition-colors ${
                      location.pathname === item.path 
                        ? 'text-blue-500' 
                        : 'text-gray-400'
                    }`}
                  >
                    {React.cloneElement(item.icon, { size: 20 })}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-grow flex items-center justify-between pl-6">
            {/* Desktop Title */}
            <h1 className="text-2xl font-bold text-gray-800">TBD Post DataCalc</h1>
            
            {/* Desktop Dropdown */}
            <div className="header-select-container">
              <select
                ref={selectRef}
                value={location.pathname}
                onChange={handleCalculatorChange}
                className="text-xl font-bold bg-white border-none focus:outline-none text-right header-select"
                size={1}
              >
                {menuItems.map(item => (
                  <option key={item.id} value={item.path}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;