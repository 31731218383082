export const FreeflyCameras = {
    "Ember S5K": {
        brand: "Freefly",
        resolutions: {
            "5K 5:4 (5120 x 4096)": {
                dimensions: [5120, 4096],
                codecs: {
                    "Apple ProRes LT": { framerates: ["436"]}
                    }
                },
            "5K 4:3 (5120 x 3840)": {
                dimensions: [5120, 3840],
                codecs: {
                    "Apple ProRes LT": { framerates: ["464"]}
                    }
                },
            "5K 16:9 (5120 x 2880)": {
                dimensions: [5120, 2880],
                codecs: {
                    "Apple ProRes LT": { framerates: ["616"]}
                    }
                },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120, 2560],
                codecs: {
                    "Apple ProRes LT": { framerates: ["691"]}
                    }
                },
            "5K 2.37:1 (5120 x 2160)": {
                dimensions: [5120, 2160],
                codecs: {
                    "Apple ProRes LT": { framerates: ["809"]}
                    }
                },
            "5K (5120 x 4096)": {
                dimensions: [5120, 4096],
                codecs: {
                    "Apple ProRes LT": { framerates: ["436"]}
                    }
                },
            "5K (5120 x 3072)": {
                dimensions: [5120, 3072],
                codecs: {
                    "Apple ProRes LT": { framerates: ["578"]}
                    }
                },
            "5K (5120 x 2304)": {
                dimensions: [5120, 2304],
                codecs: {
                    "Apple ProRes LT": { framerates: ["765"]}
                    }
                },
            "5K (5120 x 2160)": {
                dimensions: [5120, 2160],
                codecs: {
                    "Apple ProRes LT": { framerates: ["809"]}
                    }
                },
            "5K (5120 x 2048)": {
                dimensions: [5120, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "5K (5120 x 1920)": {
                dimensions: [5120, 1920],
                codecs: {
                    "Apple ProRes LT": { framerates: ["913"]}
                    }
                },
            "5K (5120 x 1728)": {
                dimensions: [5120, 1728],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1011"]}
                    }
                },
            "5K (5120 x 3072)": {
                dimensions: [5120, 3072],
                codecs: {
                    "Apple ProRes LT": { framerates: ["578"]}
                    }
                },
            "5K (5120 x 2304)": {
                dimensions: [5120, 2304],
                codecs: {
                    "Apple ProRes LT": { framerates: ["765"]}
                    }
                },
            "5K (5120 x 2048)": {
                dimensions: [5120, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "5K (5120 x 1728)": {
                dimensions: [5120, 1728],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1011"]}
                    }
                },
            "5K (5120 x 1536)": {
                dimensions: [5120, 1536],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1131"]}
                    }
                },
            "5K (5120 x 1280)": {
                dimensions: [5120, 1280],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1346"]}
                    }
                },
            "5K (5120 x 2048)": {
                dimensions: [5120, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "5K (5120 x 1536)": {
                dimensions: [5120, 1536],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1131"]}
                    }
                },
            "5K (5120 x 1440)": {
                dimensions: [5120, 1440],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1203"]}
                    }
                },
            "5K (5120 x 1152)": {
                dimensions: [5120, 1152],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1487"]}
                    }
                },
            "5K (5120 x 1080)": {
                dimensions: [5120, 1080],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1569"]}
                    }
                },
            "5K (5120 x 1024)": {
                dimensions: [5120, 1024],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1661"]}
                    }
                },
            "5K (5120 x 864)": {
                dimensions: [5120, 864],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1946"]}
                    }
                },
            "4K (4096 x 4096)": {
                dimensions: [4096, 4096],
                codecs: {
                    "Apple ProRes LT": { framerates: ["436"]}
                    }
                },
            "4K (4096 x 3840)": {
                dimensions: [4096, 3840],
                codecs: {
                    "Apple ProRes LT": { framerates: ["464"]}
                    }
                },
            "4K (4096 x 2880)": {
                dimensions: [4096, 2880],
                codecs: {
                    "Apple ProRes LT": { framerates: ["616"]}
                    }
                },
            "4K (4096 x 2560)": {
                dimensions: [4096, 2560],
                codecs: {
                    "Apple ProRes LT": { framerates: ["691"]}
                    }
                },
            "4K (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Apple ProRes LT": { framerates: ["809"]}
                    }
                },
            "4K 1:1 (4096 x 4096)": {
                dimensions: [4096, 4096],
                codecs: {
                    "Apple ProRes LT": { framerates: ["436"]}
                    }
                },
            "4K 4:3 (4096 x 3072)": {
                dimensions: [4096, 3072],
                codecs: {
                    "Apple ProRes LT": { framerates: ["578"]}
                    }
                },
            "4K 16:9 (4096 x 2304)": {
                dimensions: [4096, 2304],
                codecs: {
                    "Apple ProRes LT": { framerates: ["765"]}
                    }
                },
            "4K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Apple ProRes LT": { framerates: ["809"]}
                    }
                },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "4K (4096 x 1920)": {
                dimensions: [4096, 1920],
                codecs: {
                    "Apple ProRes LT": { framerates: ["913"]}
                    }
                },
            "4K 2.37:1 (4096 x 1728)": {
                dimensions: [4096, 1728],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1011"]}
                    }
                },
            "4K (4096 x 3072)": {
                dimensions: [4096, 3072],
                codecs: {
                    "Apple ProRes LT": { framerates: ["578"]}
                    }
                },
            "4K (4096 x 2304)": {
                dimensions: [4096, 2304],
                codecs: {
                    "Apple ProRes LT": { framerates: ["765"]}
                    }
                },
            "4K (4096 x 2048)": {
                dimensions: [4096, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "4K (4096 x 1728)": {
                dimensions: [4096, 1728],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1011"]}
                    }
                },
            "4K (4096 x 1536)": {
                dimensions: [4096, 1536],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1131"]}
                    }
                },
            "4K (4096 x 1280)": {
                dimensions: [4096, 1280],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1346"]}
                    }
                },
            "4K (4096 x 2048)": {
                dimensions: [4096, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "4K (4096 x 1536)": {
                dimensions: [4096, 1536],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1131"]}
                    }
                },
            "4K (4096 x 1440)": {
                dimensions: [4096, 1440],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1203"]}
                    }
                },
            "4K (4096 x 1152)": {
                dimensions: [4096, 1152],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1487"]}
                    }
                },
            "4K (4096 x 1080)": {
                dimensions: [4096, 1080],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1569"]}
                    }
                },
            "4K (4096 x 1024)": {
                dimensions: [4096, 1024],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1661"]}
                    }
                },
            "4K (4096 x 864)": {
                dimensions: [4096, 864],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1946"]}
                    }
                },
            "3K (3072 x 4096)": {
                dimensions: [3072, 4096],
                codecs: {
                    "Apple ProRes LT": { framerates: ["436"]}
                    }
                },
            "3K (3072 x 3840)": {
                dimensions: [3072, 3840],
                codecs: {
                    "Apple ProRes LT": { framerates: ["464"]}
                    }
                },
            "3K (3072 x 2880)": {
                dimensions: [3072, 2880],
                codecs: {
                    "Apple ProRes LT": { framerates: ["616"]}
                    }
                },
            "3K (3072 x 2560)": {
                dimensions: [3072, 2560],
                codecs: {
                    "Apple ProRes LT": { framerates: ["691"]}
                    }
                },
            "3K (3072 x 2160)": {
                dimensions: [3072, 2160],
                codecs: {
                    "Apple ProRes LT": { framerates: ["809"]}
                    }
                },
            "3K (3072 x 4096)": {
                dimensions: [3072, 4096],
                codecs: {
                    "Apple ProRes LT": { framerates: ["436"]}
                    }
                },
            "3K (3072 x 3072)": {
                dimensions: [3072, 3072],
                codecs: {
                    "Apple ProRes LT": { framerates: ["578"]}
                    }
                },
            "3K (3072 x 2304)": {
                dimensions: [3072, 2304],
                codecs: {
                    "Apple ProRes LT": { framerates: ["765"]}
                    }
                },
            "3K (3072 x 2160)": {
                dimensions: [3072, 2160],
                codecs: {
                    "Apple ProRes LT": { framerates: ["809"]}
                    }
                },
            "3K (3072 x 2048)": {
                dimensions: [3072, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "3K (3072 x 1920)": {
                dimensions: [3072, 1920],
                codecs: {
                    "Apple ProRes LT": { framerates: ["913"]}
                    }
                },
            "3K (3072 x 1728)": {
                dimensions: [3072, 1728],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1011"]}
                    }
                },
            "3K 1:1 (3072 x 3072)": {
                dimensions: [3072, 3072],
                codecs: {
                    "Apple ProRes LT": { framerates: ["578"]}
                    }
                },
            "3K 4:3 (3072 x 2304)": {
                dimensions: [3072, 2304],
                codecs: {
                    "Apple ProRes LT": { framerates: ["765"]}
                    }
                },
            "3K 3:2 (3072 x 2048)": {
                dimensions: [3072, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "3K 16:9 (3072 x 1728)": {
                dimensions: [3072, 1728],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1011"]}
                    }
                },
            "3K 2:1 (3072 x 1536)": {
                dimensions: [3072, 1536],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1131"]}
                    }
                },
            "3K 2.4:1 (3072 x 1280)": {
                dimensions: [3072, 1280],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1346"]}
                    }
                },
            "3K (3072 x 2048)": {
                dimensions: [3072, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "3K (3072 x 1536)": {
                dimensions: [3072, 1536],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1131"]}
                    }
                },
            "3K (3072 x 1440)": {
                dimensions: [3072, 1440],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1203"]}
                    }
                },
            "3K (3072 x 1152)": {
                dimensions: [3072, 1152],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1487"]}
                    }
                },
            "3K (3072 x 1080)": {
                dimensions: [3072, 1080],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1569"]}
                    }
                },
            "3K (3072 x 1024)": {
                dimensions: [3072, 1024],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1661"]}
                    }
                },
            "3K (3072 x 864)": {
                dimensions: [3072, 864],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1946"]}
                    }
                },
            "2.5K (2560 x 4096)": {
                dimensions: [2560, 4096],
                codecs: {
                    "Apple ProRes LT": { framerates: ["436"]}
                    }
                },
            "2.5K (2560 x 3840)": {
                dimensions: [2560, 3840],
                codecs: {
                    "Apple ProRes LT": { framerates: ["464"]}
                    }
                },
            "2.5K (2560 x 2880)": {
                dimensions: [2560, 2880],
                codecs: {
                    "Apple ProRes LT": { framerates: ["616"]}
                    }
                },
            "2.5K (2560 x 2560)": {
                dimensions: [2560, 2560],
                codecs: {
                    "Apple ProRes LT": { framerates: ["691"]}
                    }
                },
            "2.5K (2560 x 2160)": {
                dimensions: [2560, 2160],
                codecs: {
                    "Apple ProRes LT": { framerates: ["809"]}
                    }
                },
            "2.5K (2560 x 4096)": {
                dimensions: [2560, 4096],
                codecs: {
                    "Apple ProRes LT": { framerates: ["436"]}
                    }
                },
            "2.5K (2560 x 3072)": {
                dimensions: [2560, 3072],
                codecs: {
                    "Apple ProRes LT": { framerates: ["578"]}
                    }
                },
            "2.5K (2560 x 2304)": {
                dimensions: [2560, 2304],
                codecs: {
                    "Apple ProRes LT": { framerates: ["765"]}
                    }
                },
            "2.5K (2560 x 2160)": {
                dimensions: [2560, 2160],
                codecs: {
                    "Apple ProRes LT": { framerates: ["809"]}
                    }
                },
            "2.5K (2560 x 2048)": {
                dimensions: [2560, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "2.5K (2560 x 1920)": {
                dimensions: [2560, 1920],
                codecs: {
                    "Apple ProRes LT": { framerates: ["913"]}
                    }
                },
            "2.5K (2560 x 1728)": {
                dimensions: [2560, 1728],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1011"]}
                    }
                },
            "2.5K (2560 x 3072)": {
                dimensions: [2560, 3072],
                codecs: {
                    "Apple ProRes LT": { framerates: ["578"]}
                    }
                },
            "2.5K (2560 x 2304)": {
                dimensions: [2560, 2304],
                codecs: {
                    "Apple ProRes LT": { framerates: ["765"]}
                    }
                },
            "2.5K (2560 x 2048)": {
                dimensions: [2560, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "2.5K (2560 x 1728)": {
                dimensions: [2560, 1728],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1011"]}
                    }
                },
            "2.5K (2560 x 1536)": {
                dimensions: [2560, 1536],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1131"]}
                    }
                },
            "2.5K (2560 x 1280)": {
                dimensions: [2560, 1280],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1346"]}
                    }
                },
            "2.5K 1:1 (2560 x 2048)": {
                dimensions: [2560, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "2.5K 4:3 (2560 x 1536)": {
                dimensions: [2560, 1536],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1131"]}
                    }
                },
            "2.5K (2560 x 1440)": {
                dimensions: [2560, 1440],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1203"]}
                    }
                },
            "2.5K 16:9 (2560 x 1152)": {
                dimensions: [2560, 1152],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1487"]}
                    }
                },
            "2.5K 17:9 (2560 x 1080)": {
                dimensions: [2560, 1080],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1569"]}
                    }
                },
            "2.5K 2:1 (2560 x 1024)": {
                dimensions: [2560, 1024],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1661"]}
                    }
                },
            "2.5K 2.37:1 (2560 x 864)": {
                dimensions: [2560, 864],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1946"]}
                    }
                },
            "2K (2048 x 4096)": {
                dimensions: [2048, 4096],
                codecs: {
                    "Apple ProRes LT": { framerates: ["436"]}
                    }
                },
            "2K (2048 x 3840)": {
                dimensions: [2048, 3840],
                codecs: {
                    "Apple ProRes LT": { framerates: ["464"]}
                    }
                },
            "2K (2048 x 2880)": {
                dimensions: [2048, 2880],
                codecs: {
                    "Apple ProRes LT": { framerates: ["616"]}
                    }
                },
            "2K (2048 x 2560)": {
                dimensions: [2048, 2560],
                codecs: {
                    "Apple ProRes LT": { framerates: ["691"]}
                    }
                },
            "2K (2048 x 2160)": {
                dimensions: [2048, 2160],
                codecs: {
                    "Apple ProRes LT": { framerates: ["809"]}
                    }
                },
            "2K (2048 x 4096)": {
                dimensions: [2048, 4096],
                codecs: {
                    "Apple ProRes LT": { framerates: ["436"]}
                    }
                },
            "2K (2048 x 3072)": {
                dimensions: [2048, 3072],
                codecs: {
                    "Apple ProRes LT": { framerates: ["578"]}
                    }
                },
            "2K (2048 x 2304)": {
                dimensions: [2048, 2304],
                codecs: {
                    "Apple ProRes LT": { framerates: ["765"]}
                    }
                },
            "2K (2048 x 2160)": {
                dimensions: [2048, 2160],
                codecs: {
                    "Apple ProRes LT": { framerates: ["809"]}
                    }
                },
            "2K (2048 x 2048)": {
                dimensions: [2048, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "2K (2048 x 1920)": {
                dimensions: [2048, 1920],
                codecs: {
                    "Apple ProRes LT": { framerates: ["913"]}
                    }
                },
            "2K (2048 x 1728)": {
                dimensions: [2048, 1728],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1011"]}
                    }
                },
            "2K (2048 x 3072)": {
                dimensions: [2048, 3072],
                codecs: {
                    "Apple ProRes LT": { framerates: ["578"]}
                    }
                },
            "2K (2048 x 2304)": {
                dimensions: [2048, 2304],
                codecs: {
                    "Apple ProRes LT": { framerates: ["765"]}
                    }
                },
            "2K (2048 x 2048)": {
                dimensions: [2048, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "2K (2048 x 1728)": {
                dimensions: [2048, 1728],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1011"]}
                    }
                },
            "2K (2048 x 1536)": {
                dimensions: [2048, 1536],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1131"]}
                    }
                },
            "2K (2048 x 1280)": {
                dimensions: [2048, 1280],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1346"]}
                    }
                },
            "2K (2048 x 2048)": {
                dimensions: [2048, 2048],
                codecs: {
                    "Apple ProRes LT": { framerates: ["858"]}
                    }
                },
            "2K (2048 x 1536)": {
                dimensions: [2048, 1536],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1131"]}
                    }
                },
            "2K (2048 x 1440)": {
                dimensions: [2048, 1440],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1203"]}
                    }
                },
            "2K (2048 x 1152)": {
                dimensions: [2048, 1152],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1487"]}
                    }
                },
            "2K (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1569"]}
                    }
                },
            "2K (2048 x 1024)": {
                dimensions: [2048, 1024],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1661"]}
                    }
                },
            "2K (2048 x 864)": {
                dimensions: [2048, 864],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1946"]}
                }
            },
        }
    },

    "Ember S2.5K": {
        brand: "Freefly",
        resolutions: {   
            "2.5K 4:3 (2560 x 1920)": {
                dimensions: [2560, 1920],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1751"]}
                    }
                },
            "2.5K 16:9 (2560 x 1440)": {
                dimensions: [2560, 1440],
                codecs: {
                    "Apple ProRes LT": { framerates: ["2277"]}
                    }
                },
            "2.5K 2:1 (2560 x 1280)": {
                dimensions: [2560, 1280],
                codecs: {
                    "Apple ProRes LT": { framerates: ["2531"]}
                    }
                },
            "2.5K 2.37:1 (2560 x 1080)": {
                dimensions: [2560, 1080],
                codecs: {
                    "Apple ProRes LT": { framerates: ["2922"]}
                    }
                },
            "2.5K (2560 x 1728)": {
                dimensions: [2560, 1728],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1929"]}
                    }
                },
            "2.5K (2560 x 1536)": {
                dimensions: [2560, 1536],
                codecs: {
                    "Apple ProRes LT": { framerates: ["2118"]}
                    }
                },
            "2.5K (2560 x 1152)": {
                dimensions: [2560, 1152],
                codecs: {
                    "Apple ProRes LT": { framerates: ["2779"]}
                    }
                },
            "2.5K (2560 x 1080)": {
                dimensions: [2560, 1080],
                codecs: {
                    "Apple ProRes LT": { framerates: ["2922"]}
                    }
                },
            "2.5K (2560 x 1024)": {
                dimensions: [2560, 1024],
                codecs: {
                    "Apple ProRes LT": { framerates: ["3080"]}
                    }
                },
            "2.5K (2560 x 864)": {
                dimensions: [2560, 864],
                codecs: {
                    "Apple ProRes LT": { framerates: ["3563"]}
                    }
                },
            "2K (2048 x 1920)": {
                dimensions: [2048, 1920],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1751"]}
                    }
                },
            "2K (2048 x 1440)": {
                dimensions: [2048, 1440],
                codecs: {
                    "Apple ProRes LT": { framerates: ["2277"]}
                    }
                },
            "2K (2048 x 1280)": {
                dimensions: [2048, 1280],
                codecs: {
                    "Apple ProRes LT": { framerates: ["2531"]}
                    }
                },
            "2K (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "Apple ProRes LT": { framerates: ["2922"]}
                    }
                },
            "2K (2048 x 1728)": {
                dimensions: [2048, 1728],
                codecs: {
                    "Apple ProRes LT": { framerates: ["1929"]}
                    }
                },
            "2K 4:3 (2048 x 1536)": {
                dimensions: [2048, 1536],
                codecs: {
                    "Apple ProRes LT": { framerates: ["2118"]}
                    }
                },
            "2K 16:9 (2048 x 1152)": {
                dimensions: [2048, 1152],
                codecs: {
                    "Apple ProRes LT": { framerates: ["2779"]}
                    }
                },
            "2K 17:9 (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "Apple ProRes LT": { framerates: ["2922"]}
                    }
                },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048, 1024],
                codecs: {
                    "Apple ProRes LT": { framerates: ["3080"]}
                    }
                },
            "2K 2.37:1 (2048 x 864)": {
                dimensions: [2048, 864],
                codecs: {
                    "Apple ProRes LT": { framerates: ["3563"]}
                }
            },
        }
    },

    // ... other cameras ...
};



