
export const AppleCameras = {
    "iPhone 15 Pro": {
        brand: "Apple",
        resolutions: {
            "UHD (3840 x 2160)": { 
                dimensions: [3840, 2160], 
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30"]},
                    "iPhone HEVC (H.265) Max": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"]},
                    "iPhone HEVC (H.265) High": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"]},
                    "iPhone HEVC (H.265) Medium": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"]},
                    "iPhone HEVC (H.265) Low": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"]},
                    "iPhone H.264 Max": { framerates: ["23.976", "24", "25", "29.97", "30"]},
                    "iPhone H.264 High": { framerates: ["23.976", "24", "25", "29.97", "30"]},
                    "iPhone H.264 Medium": { framerates: ["23.976", "24", "25", "29.97", "30"]},
                    "iPhone H.264 Low": { framerates: ["23.976", "24", "25", "29.97", "30"]}
                }
            },
            "1080p (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60"]},
                    "iPhone HEVC (H.265) Max": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "120", "240"]},
                    "iPhone HEVC (H.265) High": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "120", "240"]},
                    "iPhone HEVC (H.265) Medium": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "120", "240"]},
                    "iPhone HEVC (H.265) Low": { framerates: ["23.976", "24", "25", "29.97", "30", "48", "50", "59.94", "60", "120", "240"]},
                    "iPhone H.264 Max": { framerates: ["23.976", "24", "25", "29.97", "30"]},
                    "iPhone H.264 High": { framerates: ["23.976", "24", "25", "29.97", "30"]},
                    "iPhone H.264 Medium": { framerates: ["23.976", "24", "25", "29.97", "30"]},
                    "iPhone H.264 Low": { framerates: ["23.976", "24", "25", "29.97", "30"]}
                }
            },
        }
    },

};