
export const BlackmagicCameras = {

    "Pocket Cinema Camera 6K Pro": {
        brand: "Blackmagic Design",
        resolutions: {
            "6K (6144 x 3456)": {
                dimensions: [6144, 3456],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94"] }
                }
            },
            "6K 2.4:1 (6144 x 2560)": {
                dimensions: [6144, 2560],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] }
                }
            },
            "5.7K 17:9 (5744 x 3024)": {
                dimensions: [5744, 3024],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] }
                }
            },
            "4K DCI 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] }
                }
            },
            "UHD (3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] }
                }
            },
            "3.7K 6:5 Anamorphic (3728 x 3104)": {
                dimensions: [3728, 3104],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60"] }
                }
            },
            "2.8K 17:9 (2880 x 1512)": {
                dimensions: [2880, 1512],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60", "120"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60", "120"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60", "120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60", "120"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60", "120"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60", "120"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "60", "120"] }
                }
            },
            "HD (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },            
        }
    },
    "PYXIS 6K": {
        brand: "Blackmagic Design",
        resolutions: {
            "6K Open Gate 3:2 (6048 x 4032)": {
                dimensions: [6048, 4032],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] }
                }
            },
            "6K Anamorphic 6:5 (4832 x 4032)": {
                dimensions: [4832, 4032],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "36"] }
                }
            },
            "6K 16:9 (6048 x 3408)": {
                dimensions: [6048, 3408],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "46"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "46"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "46"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "46"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "46"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "46"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "46"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "46"] }
                }
            },
            "6K DCI 17:9 (6048 x 3200)": {
                dimensions: [6048, 3200],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "48"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "48"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "48"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "48"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "48"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "48"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "48"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "48"]}
                }
            },
            "6K 2.4:1 (6048 x 2520)": {
                dimensions: [6048, 2520],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },
            "S35 4:3 (4096 x 3072)": {
                dimensions: [4096, 3072],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50"]}
                }
            },
            "4K 16:9 (4096 x 2304)": {
                dimensions: [4096, 2304],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },
            "4K DCI 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },
            "S16 16:9 (2112 x 1184)": {
                dimensions: [2112, 1184],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"] }
                }
            },
            "HD (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },            
        }
    }, 
    "URSA Cine 12K": {
        brand: "Blackmagic Design",
        resolutions: {
            "12K 3:2 Open Gate (12288 x 8040)": {
                dimensions: [12288, 8040],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]}
                }
            },
            "12K 17:9 (12288 x 6912)": {
                dimensions: [12288, 6912],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]}
                }
            },
            "12K 16:9 (12288 x 6480)": {
                dimensions: [12288, 6480],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },
            "12K 2.4:1 (12288 x 5112)": {
                dimensions: [12288, 5112],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]}
                }
            },
            "12K 6:5 (9648 x 8040)": {
                dimensions: [9648, 8040],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },


            "9K 3:2 S35 (9408 x 6264)": {
                dimensions: [9408, 6264],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "100"]}
                }
            },
            "9K 16:9 (8688 x 4896)": {
                dimensions: [8688, 4896],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },
            "9K 17:9 (9312 x 4896)": {
                dimensions: [9312, 4896],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },
            "9K 2.4:1 (9312 x 3864)": {
                dimensions: [9312, 3864],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },
            "9K 6:5 (7680 x 6408)": {
                dimensions: [7680, 6408],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },




            "8K 3:2 Open Gate (8192 x 5360)": {
                dimensions: [8192, 5360],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]}
                }
            },
            "8K 16:9 (8192 x 4608)": {
                dimensions: [8192, 4608],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },
            "8K 17:9 (8192 x 4320)": {
                dimensions: [8192, 4320],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },
            "8K 2.4:1 (8192 x 3408)": {
                dimensions: [8192, 3408],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]}
                }
            },
            "8K 6:5 (6432 x 5360)": {
                dimensions: [6432, 5360],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },

            "4K 3:2 Open Gate (4096 x 2680)": {
                dimensions: [4096, 2680],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW 4:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW 6:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "144"]}
                }
            },
            "4K 16:9 (4096 x 2304)": {
                dimensions: [4096, 2304],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 4:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 6:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },
            "4K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 4:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 6:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },
            "4K 2.4:1 (4096 x 1704)": {
                dimensions: [4096, 1704],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW 4:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW 6:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]},
                        "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "224"]}
                }
            },
            "4K 6:5 (3216 x 2680)": {
                dimensions: [3216, 2680],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 4:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW 6:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },            
        }
    },
    "URSA Mini Pro 4.6K G2": {
        brand: "Blackmagic Design",
        resolutions: {
            "4.6K (4608 x 2592)": {
                dimensions: [4608, 2592],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60","120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444 XQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] },
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] },
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] },
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] }
                }
            },
            "4.6K 2.40:1 (4608 x 1920)": {
                dimensions: [4608, 1920],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60","120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444 XQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] },
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] },
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] },
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] }
                }
            },
            "4K 16:9 (4096 x 2304)": {
                dimensions: [4096, 2304],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60","120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444 XQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] },
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] },
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] },
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] }
                }
            },
            "4K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60","120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444 XQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] },
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] },
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] },
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"] }
                }
            },
            "UHD (3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120", "150"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60","120", "150"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444 XQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },
            "3K Anamorphic (3072 x 2560)": {
                dimensions: [3072, 2560],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120", "150"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60","120", "150"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444 XQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },
            "2K 16:9 (2048 x 1152)": {
                dimensions: [2048, 1152],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120", "150"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120", "150"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444 XQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]    },
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },
            "2K DI 17:9 (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120", "150"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120", "150"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444 XQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]    },
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },
            "HD (1920 x 1080)": {
                dimensions: [1920, 1080],
                codecs: {
                    "Blackmagic RAW 3:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120", "240", "300"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120", "240", "300"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444 XQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 4444": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120", "240"] },
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120", "240"] },
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120", "240"] },
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120", "240"] }
                }
            },            
        }
    },
    "URSA Mini Pro 12K": {
        brand: "Blackmagic Design",
        resolutions: {
            "12K DCI (12288 x 6480)": {
                dimensions: [12288, 6480],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] }
                }
            },
            "12K 16:9 (11520 x 6480)": {
                dimensions: [11520, 6480],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"] }
                }
            },
            "12K 2.4:1 (12288 x 5112)": {
                dimensions: [12288, 5112],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] }
                }
            },
            "12K Anamorphic (7680 x 6408)": {
                dimensions: [7680, 6408],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "75"] }
                }
            },
            "8K DCI (8192 x 4320)": {
                dimensions: [8192, 4320],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },
            "8K 16:9 (7680 x 4320)": {
                dimensions: [7680, 4320],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },
            "8K 2.4:1 (8192 x 3408)": {
                dimensions: [8192, 3408],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] }
                }
            },
            "8K Anamorphic (5120 x 4272)": {
                dimensions: [5120, 4272],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },
            "6K S16 (6144 x 3240)": {
                dimensions: [6144, 3240],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },
            "4K S16 (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"] }
                }
            },
            "4K DCI (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },

            "4K 2.4:1 (4096 x 1704)": {
                dimensions: [4096, 1704],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"] }
                }
            },
            "4K Anamorphic (2560 x 2136)": {
                dimensions: [2560, 2136],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },
            "UHD (3840 x 2160)": {
                dimensions: [3840, 2160],
                codecs: {
                    "Blackmagic RAW 5:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 8:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 12:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW 18:1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q0": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q1": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q3": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] },
                    "Blackmagic RAW Q5": { framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"] }
                }
            },            
        }
    },

    // ... other cameras ...
};