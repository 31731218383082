export const ZCamCameras = {
    "E2-F8": {
        brand: "Z CAM",
        resolutions: {
            "8K 2.4:1 (8192 x 3456)": {
                dimenisions: [8192, 3456],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 8K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 8K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 8K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            },
            "8K UHD (7680 x 4320)": {
                dimenisions: [7680, 4320],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 8K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 8K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 8K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            },
            "6K DCI (6144 x 3240)": {
                dimenisions: [6144, 3240],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            },
            "6K UHD (5760 x 3240)": {
                dimenisions: [5760, 3240],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            },
            "4K UHD (3840 x 2160)": {
                dimenisions: [3840, 2160],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Z CAM 4K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Z CAM 4K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Z CAM 4K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]}
                }
            },
        }
    },
    "E2-F6 Pro": {
        brand: "Z CAM",
        resolutions: {
            "6K Open Gate (6064 x 4040)": {
                dimenisions: [6064, 4040],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            },
            "C6K (6064 x 3196)": {
                dimenisions: [6064, 3196],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]}
                }
            },
            "6K 2.4:1 (6064 x 2560)": {
                dimenisions: [6064, 2560],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]}
                }
            },
            "6K UHD (5760 x 3240)": {
                dimenisions: [5760, 3240],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]}
                }
            },
            "5K 4:3 (5376 x 4032)": {
                dimenisions: [5376, 4032],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            },
            "C5K (5120 x 2700)": {
                dimenisions: [5120, 2700],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]}
                }
            },
            "5K 6:5 (4848 x 4040)": {
                dimenisions: [4848, 4040],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            },
            "5K UHD (4800 x 2700)": {
                dimenisions: [4800, 2700],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]}
                }
            },
            "4K 4:3 (4096 x 3072)": {
                dimenisions: [4096, 3072],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            },
            "C4K (4096 x 2160)": {
                dimenisions: [4096, 2160],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90"]}
                }
            },
            "C4K 2.4:1 (4096 x 1728)": {
                dimenisions: [4096, 1728],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]}
                }
            },
            "4K 6:5 (4080 x 3400)": {
                dimenisions: [4080, 3400],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            },
            "C4K 3:2 (4064 x 2712)": {
                dimenisions: [4064, 2712],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]}
                }
            },
            "UHD (3840 x 2160)": {
                dimenisions: [3840, 2160],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90"]}
                }
            },
            "S16 16:9 (2112 x 1188)": {
                dimenisions: [2112, 1188],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120", "170"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120", "170"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120", "170"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120", "170"]}
                }
            },
            "HD (1920 x 1080)": {
                dimenisions: [1920, 1080],
                codecs: {
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]}
                }
            },
        }
    },
    "E2-F6": {
        brand: "Z CAM",
        resolutions: {
            "Open Gate (6064 x 4040)": {
                dimenisions: [6064, 4040],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            }, 
        
        
            "6K DCI (6064 x 3196)": {
                dimenisions: [6064, 3196],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Z CAM 5-6K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Z CAM 5-6K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Z CAM 5-6K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]}
                }
            }, 
        
        
            "6K 2.4:1 (6064 x 2560)": {
                dimenisions: [6064, 2560],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Z CAM 5-6K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Z CAM 5-6K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]},
                    "Z CAM 5-6K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "50", "59.94"]}
                }
            }, 
        
        
            "6K UHD (5760 x 3240)": {
                dimenisions: [5760, 3240],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Z CAM 5-6K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Z CAM 5-6K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]},
                    "Z CAM 5-6K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94"]}
                }
            }, 
        
        
            "5K 4:3 (5376 x 4032)": {
                dimenisions: [5376, 4032],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            }, 
        
        
            "5K 6:5 (4848 x 4040)": {
                dimenisions: [4848, 4040],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            }, 
        
        
            "4K DCI (4096 x 2160)": {
                dimenisions: [4096, 2160],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Z CAM 4K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Z CAM 4K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Z CAM 4K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]}
                }
            }, 
        
        
            "4K 2.4:1 (4096 x 1728)": {
                dimenisions: [4096, 1728],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Z CAM 4K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Z CAM 4K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Z CAM 4K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]}
                }
            }, 
        
        
            "4K UHD (3840 x 2160)": {
                dimenisions: [3840, 2160],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Z CAM 4K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Z CAM 4K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]},
                    "Z CAM 4K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "72"]}
                }
            }, 
        
        
            "FHD (1920 x 1080)": {
                dimenisions: [1920, 1080],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Z CAM 4K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Z CAM 4K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]},
                    "Z CAM 4K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "90", "120"]}
                }
            }, 
        }
    },
    "E2-S6G": {
        brand: "Z CAM",
        resolutions: {
            "Open Gate (6144 x 4096)": {
                dimenisions: [6144, 4096],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            }, 
            "6K DCI (6144 x 3240)": {
                dimenisions: [6144, 3240],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            }, 
            "6K UHD (5760 x 3240)": {
                dimenisions: [5760, 3240],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97"]},
                    "Z CAM 5-6K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97"]}
                }
            }, 
            "6K 2.4:1 (6144 x 2592)": {
                dimenisions: [6144, 2592],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50"]},
                    "Z CAM 5-6K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "48", "50"]},
                    "Z CAM 5-6K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "48", "50"]},
                    "Z CAM 5-6K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "48", "50"]}
                }
            }, 
            "4K DCI (4096 x 2160)": {
                dimenisions: [4096, 2160],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Z CAM 4K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Z CAM 4K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Z CAM 4K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]}
                }
            }, 
            "4K DCI 2.4:1) (4096 x 1728)": {
                dimenisions: [4096, 1728],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Z CAM 4K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Z CAM 4K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Z CAM 4K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]}
                }
            }, 
            "4K UHD (3840 x 2160)": {
                dimenisions: [3840, 2160],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Z CAM 4K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Z CAM 4K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]},
                    "Z CAM 4K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "56"]}

                }
            }, 
            "FHD (1920 x 1080)": {
                dimenisions: [1920, 1080],
                codecs: {
                    "ZRAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Apple ProRes RAW": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Apple ProRes 422 HQ": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Apple ProRes 422": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Apple ProRes LT": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Apple ProRes Proxy": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Z CAM 4K H.264/H.265 High": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Z CAM 4K H.264/H.265 Medium": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]},
                    "Z CAM 4K H.264/H.265 Low": { framerates: ["23.976", "24", "25", "29.97", "48", "50", "59.94", "70"]}        
                }
            }, 
        }
    }




    
};
