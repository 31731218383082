import { 
    FaClock, 
    FaClipboardList,
    FaHistory,
    FaPlayCircle 
  } from 'react-icons/fa';
  import { 
    FaVideo,
    FaCalculator
  } from 'react-icons/fa6';
import { BsFillAspectRatioFill, BsFillStopwatchFill } from 'react-icons/bs';
import { PiFilmReelFill } from 'react-icons/pi';
import { RiFileEditFill } from 'react-icons/ri';
import { HiSparkles } from 'react-icons/hi2';
import { BiTransfer } from 'react-icons/bi';
import { MdInsertChart } from 'react-icons/md';
import { SlNote } from "react-icons/sl";

// Feature flags configuration
const features = {
  betaCalculators: {
    bitrateCalculator: true,
    dailiesCalculator: true,
  },
  comingSoon: {
    transferTime: true,
    fileNameCreator: true,
    logs: true,
  }
};

// Add near the top of the file
console.log('Current environment:', process.env.REACT_APP_ENVIRONMENT);

// Helper to check if feature is enabled based on environment
export const isFeatureEnabled = (featureName) => {
  console.log(`Checking feature ${featureName} in env:`, process.env.REACT_APP_ENVIRONMENT);
  if (process.env.REACT_APP_ENVIRONMENT === 'beta') {
    console.log(`${featureName} enabled in beta`);
    return true;
  }
  console.log(`${featureName} disabled in production`);
  return !features.betaCalculators[featureName];
};

// Export calculator configurations
export const calculatorConfig = {
  duration: {
    enabled: true,
    production: true,
    path: '/duration',
    component: 'DurationToFileSize',
    title: 'Duration ↔ File Size',
    description: 'Calculate file size based on duration, resolution, codec, and framerate.',
    icon: <FaClock />
  },
  camera: {
    enabled: true,
    production: true,
    path: '/camera',
    component: 'CameraToFileSize',
    title: 'Camera ↔ File Size',
    description: 'Calculate file size based on camera settings and recording duration.',
    icon: <FaVideo />
  },
  film: {
    enabled: true,
    production: true,
    path: '/film',
    component: 'FilmCalculator',
    title: 'Film Calculator',
    description: 'Calculate the amount of film you need to achieve your desired duration.',
    icon: <PiFilmReelFill />
  },
  aspectRatio: {
    enabled: true,
    production: true,
    path: '/aspect-ratio',
    component: 'AspectRatioCalculator',
    title: 'Aspect Ratio',
    description: 'Calculate dimensions while maintaining aspect ratio.',
    icon: <BsFillAspectRatioFill />
  },
  dailies: {
    enabled: process.env.REACT_APP_ENVIRONMENT === 'beta',
    production: false,
    path: '/dailies',
    component: 'DailiesCalculator',
    title: 'Dailies',
    description: 'Calculate resolutions and file sizes for OCN and dailies.',
    icon: <FaPlayCircle />,
    secondaryIcon: <FaCalculator />,
    beta: true
  },
  bitrate: {
    enabled: process.env.REACT_APP_ENVIRONMENT === 'beta',
    production: false,
    path: '/bitrate',
    component: 'BitrateCalculator',
    title: 'Bitrate ↔ File Size',
    description: 'Calculate bitrate, file size, and duration relationships.',
    icon: <MdInsertChart />,
    secondaryIcon: <FaCalculator />,
    beta: true
  },
  transferTime: {
    enabled: false,
    production: false,
    path: '/transfer-time',
    component: 'ComingSoon',
    title: 'Transfer Time',
    description: 'Calculate transfer times based on file size and connection speed.',
    icon: <BiTransfer />,
    secondaryIcon: <BsFillStopwatchFill />,
    comingSoon: true
  },
  fileNameCreator: {
    enabled: false,
    production: false,
    path: '/filename-creator',
    component: 'ComingSoon',
    title: 'File Name Creator',
    description: 'Generate standardized file names for your media.',
    icon: <RiFileEditFill />,
    secondaryIcon: <HiSparkles />,
    comingSoon: true
  },
  logs: {
    enabled: true,
    production: true,
    path: '/logs',
    component: 'Log',
    title: 'Logs',
    description: 'View and manage your calculation history.',
    icon: <FaHistory />,
    secondaryIcon: <FaClipboardList />,
    // beta: true
  }
};